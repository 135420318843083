  
     


<template>
  <div class="row mt-2">
    <!-- {{ cats_data }} -->


      <div v-for="result, index in cats_data"  class="card card-body" >


    

        <!-- COMPREHENSION OF SPOKEN/WRITTEN WORDS -->

        <div v-if="result['type'] && (result['type']==='spoken_words_comprehension' || result['type']==='written_words_comprehension')">

          <div v-if="result['type']==='spoken_words_comprehension'">
            Comprehension of Spoken Words 
          </div>
          <div v-if="result['type']==='written_words_comprehension'">
            Comprehension of Written Words 
          </div>

          <table class="table" style="table-layout: fixed">
            <thead>
                <tr>
                  <th scope="col">Target</th>
                  <th scope="col">Score</th>
                  <th scope="col">Phonological Distractor</th>
                  <th scope="col">Different features & position</th>
                  <th scope="col">Semantic Distractor</th>
                  <th scope="col">Unrelated Distractor</th>
                  <th scope="col">Delay (s)</th>

                </tr>
              </thead>

          
            <tbody>
                <tr v-for="row, index in result['results']" v-bind:key="index">


                  <td v-if="row['selection']==='target'"><b>{{ row['target']['name'].charAt(0).toUpperCase() + row['target']['name'].slice(1)}}</b></td>
                  <td v-else>{{ row['target']['name'].charAt(0).toUpperCase() + row['target']['name'].slice(1)}}</td>

                  <td v-if="row['score']=='1-D'">1 - delay > 5s</td>
                  <td v-else-if="row['score']=='1-Sc'">1 - self-correction</td>
                  <td v-else-if="row['score']=='1-R'">1 - repetition of stimulus</td>

                  <td v-else>{{ row['score']}}</td>


                  <td v-if="row['selection']==='phonological distractor'"><b>{{ row['phonological distractor']['name'].charAt(0).toUpperCase() + row['phonological distractor']['name'].slice(1) }}</b></td>
                  <td v-else>{{ row['phonological distractor']['name'].charAt(0).toUpperCase() + row['phonological distractor']['name'].slice(1) }}</td>

                  <td >{{ row['phonological distractor']['n_features_different']+'df - ' + row['phonological distractor']['position']}}</td>

                  <td v-if="row['selection']==='semantic distractor'"><b>{{ row['semantic distractor']['name'].charAt(0).toUpperCase() + row['semantic distractor']['name'].slice(1) }}</b></td>
                  <td v-else>{{ row['semantic distractor']['name'].charAt(0).toUpperCase() + row['semantic distractor']['name'].slice(1) }}</td>

                  <td v-if="row['selection']==='unrelated distractor'"><b>{{ row['unrelated distractor']['name'].charAt(0).toUpperCase() + row['unrelated distractor']['name'].slice(1) }}</b></td>
                  <td v-else>{{ row['unrelated distractor']['name'].charAt(0).toUpperCase() + row['unrelated distractor']['name'].slice(1) }}</td>
                  <td v-if="'delay' in row">{{ row['delay'].toFixed(0) }}</td>
                  <td v-else></td>


                </tr>
                <tr class="table-group-divider">
                  <th scope="col">{{ result['results'].filter(item => item['selection'] === 'target').length }} / {{ Object.keys(result['results']).length }}</th>

                  <th scope="col">{{ result['results'].filter(item => item['score'] === '2').length*2 + result['results'].filter(item => 'score' in item && item['score'].charAt(0) === '1').length }} / {{ Object.keys(result['results']).length*2 }}</th>

                  <th scope="col">{{ result['results'].filter(item => item['selection'] == 'phonological distractor').length }} / {{ Object.keys(result['results']).length }}</th>
                 
                  <th scope="col"></th>
                  <th scope="col">{{ result['results'].filter(item => item['selection'] == 'semantic distractor').length }} / {{ Object.keys(result['results']).length }}</th>
                  <th scope="col">{{ result['results'].filter(item => item['selection'] == 'unrelated distractor').length }} / {{ Object.keys(result['results']).length }}</th>

                </tr>

              </tbody>
            </table>
          </div>


        <!-- SEMANTIC MEMORY RESULTS -->

        <div v-if="result['type'] && result['type']==='semantic_memory'">
          Semantic Memory 
          <table class="table">
            <thead>
                <tr>
                  <th scope="col">Target</th>
                  <th scope="col">Close Semantic Distractor</th>
                  <th scope="col">Distant Semantic Distractor</th>
                  <th scope="col">Unrelated Distractor</th>
                  <th scope="col">Correct</th>
                  <th scope="col">Delay (s)</th>

                </tr>
              </thead>

          
            <tbody>
                <tr v-for="row, index in result['results']" v-bind:key="index">

                  <td v-if="row['selection']==='target'"><b>{{ row['target']['name'].charAt(0).toUpperCase() + row['target']['name'].slice(1)}}</b></td>
                  <td v-else>{{ row['target']['name'].charAt(0).toUpperCase() + row['target']['name'].slice(1)}}</td>

                  <td v-if="row['selection']==='close semantic distractor'"><b>{{ row['close semantic distractor']['name'].charAt(0).toUpperCase() + row['close semantic distractor']['name'].slice(1) }}</b></td>
                  <td v-else>{{ row['close semantic distractor']['name'].charAt(0).toUpperCase() + row['close semantic distractor']['name'].slice(1) }}</td>

                  
                  <td v-if="row['selection']==='distant semantic distractor'"><b>{{ row['distant semantic distractor']['name'].charAt(0).toUpperCase() + row['distant semantic distractor']['name'].slice(1) }}</b></td>
                  <td v-else>{{ row['distant semantic distractor']['name'].charAt(0).toUpperCase() + row['distant semantic distractor']['name'].slice(1) }}</td>

                  <td v-if="row['selection']==='unrelated distractor'"><b>{{ row['unrelated distractor']['name'].charAt(0).toUpperCase() + row['unrelated distractor']['name'].slice(1) }}</b></td>
                  <td v-else>{{ row['unrelated distractor']['name'].charAt(0).toUpperCase() + row['unrelated distractor']['name'].slice(1) }}</td>

                  <td>

                    <img v-if="row['correct']" :src="tickImg" style="width:15px;">
                    <img v-else :src="crossImg" style="width:15px;">
                  </td>
                  <td v-if="'delay' in row">{{ row['delay'].toFixed(0) }}</td>
                  <td v-else></td>

                </tr>
                <tr class="table-group-divider">
                  <th scope="col">{{ result['results'].filter(item => item['selection'] === 'target').length }} / {{ Object.keys(result['results']).length }}</th>
                  <th scope="col">{{ result['results'].filter(item => item['selection'] == 'close semantic distractor').length }} / {{ Object.keys(result['results']).length }}</th>
                  <th scope="col">{{ result['results'].filter(item => item['selection'] == 'distant semantic distractor').length }} / {{ Object.keys(result['results']).length }}</th>
                  <th scope="col">{{ result['results'].filter(item => item['selection'] == 'unrelated distractor').length }} / {{ Object.keys(result['results']).length }}</th>

                  <th scope="col">{{ result['results'].filter(item => item['correct'] == true).length }} / {{ Object.keys(result['results']).length }}</th>
                </tr>

              </tbody>
            </table>
          </div>




        <!-- RECOGNITION MEMORY RESULTS -->
        <div v-if="result['type'] && result['type']==='recognition_memory'">
          Recognition Memory 
          <table class="table">
            <thead>
                <tr>
                  <th scope="col">Target image</th>
                  <th scope="col">Selected image</th>
                  <th scope="col">Correct</th>
                  <th scope="col">Delay (s)</th>

                </tr>
              </thead>

          
            <tbody>
                <tr v-for="row, index in result['results']" v-bind:key="index">

                  <td>{{ row['target']['name'].charAt(0).toUpperCase() + row['target']['name'].slice(1)}}</td>
                  <td v-if="'selection' in row">{{ row[row['selection']]['name'].charAt(0).toUpperCase() + row[row['selection']]['name'].slice(1) }}</td>
                  <td v-else></td>
                  <td>

                    <img v-if="row['correct']" :src="tickImg" style="width:15px;">
                    <img v-else :src="crossImg" style="width:15px;">
                  </td>
                  <td v-if="'delay' in row">{{ row['delay'].toFixed(0) }}</td>
                  <td v-else></td>

                </tr>
                <tr class="table-group-divider">
                  <th></th>
                  <th scope="col">Total:</th>
                  
                  <th scope="col">{{ result['results'].filter(item => item['correct'] == true).length }} / {{ Object.keys(result['results']).length }}</th>
                </tr>

              </tbody>
            </table>
          </div>


      </div>




  

  </div>
</template>
  
<script>

  import {ref, onUpdated, onMounted} from 'vue'
  import crossImg from '@/assets/images/wrong_cross.png'
  import tickImg from '@/assets/images/Tick.png'

  export default {
    name: 'cats',
    components: {
    },
    props: {
      cats_data: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      

      onMounted( () => {

        console.debug('here')

        console.debug(cats_data)

  
      });

    
      return {

        tickImg,
        crossImg
        
      };

    }
  }
</script>