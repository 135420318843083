<template>
<div class="d-flex flex-column min-vh-100">
  <Navbar  :enableBack=true></Navbar>
  
  <div class="container mb-5">
    <h1 class="display-5">Conversation</h1>

    <div class="card text-center p-2">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div>

              <div class="special-badge">
              </div>
              <div class="mask-t">
                <strong>1</strong>
              </div>
              <p class="lead"> Real-life conversation training</p>
              <img :src="conversationImg" style="width: 100%;margin-top:2%;">
              <button class="btn btn-primary btn-lg" @click="selected_module='Barista';openModal('chatbot_modal')"> Start this exercise</button>
              <br/>
              <!-- <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('chatbot')"> Exercise overview</button> -->
            </div>
          </div>
        </div>

      </div>

      <div class="modal fade" id="chatbot_modal" tabindex="-1" aria-labelledby="chatbot_modal" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Real-life conversation training</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('chatbot_modal')"></button>
                </div>
                <div class="modal-body">
                  <div class="row mt-2">
                    <h5>Choose one of these situations to talk about:</h5>
                  </div>
                  <div class="row mt-2" style="height:50vh">
                    <div class="col-4 border-3 border-success border-end">
      
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Barista'?'btn-primary':'btn-light']" type="button" @click="selected_module='Barista'">
                        A barista at a coffee shop
                      </button>
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Postman'?'btn-primary':'btn-light']" type="button" @click="selected_module='Postman'">
                        Your local postman
                      </button>
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Waiter'?'btn-primary':'btn-light']" type="button" @click="selected_module='Waiter'">
                        A waiter at a restaurant
                      </button>
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Hairdresser'?'btn-primary':'btn-light']" type="button" @click="selected_module='Hairdresser'">
                        Your favourite hairdresser
                      </button>
                      
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Taxi'?'btn-primary':'btn-light']" type="button" @click="selected_module='Taxi'">
                        A taxi driver
                      </button>
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Supermarket'?'btn-primary':'btn-light']" type="button" @click="selected_module='Supermarket'">
                        The cashier at the supermarket
                      </button>
                      <button :class="['btn','mb-4', 'w-100', selected_module == 'Drive_thru'?'btn-primary':'btn-light']" type="button" @click="selected_module='Drive_thru'">
                        A drive-thru attendant at McDonald's
                      </button>
                    </div>

                    <div class="col-8">
                      <div v-show="selected_module == 'Barista'">
                        <p class="lead">Practise ordering a coffee or tea</p>
                      </div>
                      <div v-show="selected_module == 'Postman'">
                        <p class="lead">Practise chatting with your postman</p>
                      </div>
                      <div v-show="selected_module == 'Waiter'">
                        <p class="lead">Practise ordering at a restaurant</p>
                      </div>
                      <div v-show="selected_module == 'Hairdresser'">
                        <p class="lead">Practise requesting a haircut from your hairdresser</p>
                      </div>
                      <div v-show="selected_module == 'Taxi'">
                        <p class="lead">Practise asking your taxi driver to take somewhere</p>
                      </div>
                      <div v-show="selected_module == 'Supermaket'">
                        <p class="lead">Practise talking to the cashier at a supermarket</p>
                      </div>
                      <div v-show="selected_module == 'Drive_thru'">
                        <p class="lead">Practise ordering food at McDonald's</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="chatbot(selected_module)">Start training</button>
                </div>
              </div>
            </div>
            </div>

    </div>     
    
    <!-- <Posture_instructions/> -->

  </div>

  <!-- Modal -->
  <div v-show="checkSetting" class="modal fade" id="settingsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl" >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">My settings</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Patient_setting></Patient_setting>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="start_pop" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ module_title }}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div class="row justify-content-around">
            <div class="card card-body">
              <h5>{{ benfite_statement }}</h5>
            </div>
            
          </div>
          <div class="row justify-content-center">
            <Posture_instructions/>
          </div>
          <div class="row justify-content-center mt-4">
            <div class="col-3">
              <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
            </div>
            <div class="col-3">
              <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer class="mt-auto"></Footer>
</div>
  
</template>
<script>
import conversationImg from '@/assets/images/instructions/conversation_img.png'

import {getIssueFlag} from '@/utils/utils'
import Patient_setting from '../../setting.vue'
import Posture_instructions from '../components/posture_instructions.vue'
import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
  name: 'advanced_training',
  components: { Patient_setting, Footer, Posture_instructions, Navbar },
  props: 
  {
  },
  data(){
    return {
      selected_module:'',
      userCategory:'',
      region:'',
      explanation:'',
      conversationImg:conversationImg,
      checkSetting:false,
      ifVoice:false,
      module_title:'',
      benfite_statement:'',
      module_cate:''
    }
  },
  created() {
    this.userCategory = localStorage.getItem('userCategory');
    this.region = localStorage.getItem('region');

    this.explanation = ""

    let issue_flag = getIssueFlag()
    this.ifVoice = issue_flag[2];
  },
  mounted()
  {
    this.modal_references = {
        "chatbot_modal": new Modal(document.getElementById('chatbot_modal')),
      }

  },
  methods: {
  
    gotoBenefit(cate)
    {
      this.module_cate = cate;
      if(cate == 'long_news')
      {
        this.module_title = 'Daily news (long)'
        this.benfite_statement = 'News contains most phonemes (sounds) needed for practising articulation control. This exercise aims to help you improve your breathing and articulation control when speaking at length.'
      }
      else
      {
        console.log('unknown category = '+cate)
      }
      let start_pop = new Modal(document.getElementById('start_pop'));
      start_pop.show()
    },
    chatbot(selected_module)
    {
      localStorage.setItem('chatbot_topic', selected_module);

      this.closeModal('chatbot_modal')

      this.$router.push({ name: 'chat_scenario'})
      
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    openModal(name_id) 
    {
      if (name_id in this.modal_references) {
        // this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    gotoModule()
    {
      if(this.module_cate == 'long_news')
      {
        this.longNews()
      }
      else
      {
        console.log('unknown category = '+this.module_title)
      }
    }
  },
}
</script>
<style scoped>
.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}
</style>