<template>
  <div class="d-flex flex-column min-vh-100">
    <div class="container text-center">
      <div v-if="page_stage == 'permission' ">
        <Permission @confirm="confirm_permission" @go_to_guide="page_stage='go_to_guide'" />
      </div>
      <div v-else-if="page_stage == 'go_to_guide' ">
        <Guide @confirm="confirm_permission"></Guide>
      </div>
      <div v-else class="row justify-content-center">
        <Test @error="page_stage = 'permission';" @confirm="confirm_test"/>
      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>    
</template>

<script>
import { ref, onMounted, onUpdated, watch } from 'vue';
import Permission from './mic_test/permission.vue'
import Test from './mic_test/test.vue'
import Guide from './mic_test/guide.vue'
import Footer from '@/views/Footer.vue';

export default {
  name: 'MicPermission',
  components: { Permission, Test, Guide, Footer },
  emits: ['response'],

  setup(props, { emit }) {
    const test_mic = ref(false)
    const page_stage = ref('permission')
  
    //alert("In MicPermission")
    const confirm_permission = () => {
      //alert("permission granted (micPermission.vue)")
      if (test_mic.value == true) {
        page_stage.value = 'test'
      } else {
        //alert("response true")
        emit('response', true)
      }
    }

    const confirm_test = () => {
      emit('response', true);
    }

    onMounted(() => {

    });

    onUpdated(() => {
    })

    return {
      page_stage,
      confirm_permission,
      confirm_test,

    };
  },
};
</script>

<style>
</style>