<template>
  <div>
      <div v-if="song_select_mode" class="card card-body h-100">

        <p class="lead text-center">Pick a song you want to sing</p>


          <!-- Song Cards -->
          <div class="d-flex align-items-center justify-content-center">
            <!-- Left Arrow -->
            <button v-show="currentPage>1"
              class="btn btn_karaoke rounded-circle me-3"
              :disabled="currentPage === 1"
              @click="previousPage"
            >
            &#10094;
            </button>

            <!-- Songs Display -->
            <div class="d-flex flex-wrap justify-content-center" style="gap: 1rem; width: 100%;">
              <div
                v-for="(song, index) in filteredSongs"
                :key="index"
                class="card text-center"
                style="width: 200px; border: 1px solid #ddd; border-radius: 8px;"
              >
                <div class="card-body" style="display: flex;  flex-direction: column;">
                  <h5 class="card-title">{{ song.artist }}</h5>
                  <p class="card-text">{{ song.song_name }}</p>
                  <button 
                    class="btn btn-success"
                    style="margin-top: auto;"
                    @click="playSong(song)"
                  >
                    Select this <i class="bi bi-music-note"></i>
                  </button>
                </div>
              </div>
            </div>

            <!-- Right Arrow -->
            <button v-show="currentPage<totalPages"
              class="btn btn_karaoke rounded-circle ms-3"
              :disabled="currentPage === totalPages"
              @click="nextPage"
            >
            &#10095;
          
          </button>
          </div>


      <!-- <div class="col-md-6">
        <div class="card card-body h-100">
          <div class="row">
            <p class="lead">{{instruction}} </p>
            <p class="lead" style="color:blue"><b>{{song_name}}</b></p>
            <p class="lead" style="height:24px; fontSize:24px; color:#FF0000"><em>{{karaoke_text}}</em> </p>
            <div id="video_placeholder"></div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-body">
          <div class="container">
            <p class="lead">Songs</p>
            <div class="row shadow-sm p-1 mt-1 bg-white" v-for="song, index in available_songs" :key="index">
              <div class="col-8">
                <p class="text-start">{{song['file_name']}}</p>
              </div>
              <div class="col-4">
                <button class="btn btn-success" @click="playSong(song)" >Select</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div v-else class="card card-body h-100" style="min-height: 300px;">
      <div class="row">
        <!-- First Column -->
        <div class="col-md-3 d-flex flex-column">
          <div class="card h-100">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">{{ artist }}</h5>
              <p class="card-text">{{ song_name }}</p>
              
              <button 
                class="btn btn-primary mt-auto"
                @click="goToSongSelection()"
              >
                Selected  <i class="bi bi-music-note"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Second Column -->
        <div class="col-md-8">
          <p class="h5 fw-semibold" style="margin-bottom: 5%;">Sing the lyrics as the song plays</p>

          <p class="h4 fw-bold text-primary" style="height:24px;  margin-bottom: 5%; color:purple">{{karaoke_text}} </p>

          <div id="video_placeholder"></div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-4">
    <button 
      class="btn btn-primary"
      style="width: 30%;"
      @click="goToSongSelection()"
    >
      Select another song
    </button>
  </div>
    </div>

    
  </div>
</template>
<script>

import Lyric from 'lrc-file-parser'

import {logActivity,getTodayString, getWeekNumber} from '@/media_input/utils'

/*

{
   "Name":"Karaoke song",
   "exercise_type":"karaoke",
   "song":"any",
   "plan_name":"Singing",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Singing//Exercises/General/Karaoke/exercises/any",
   "ex_path":"Singing//Exercises/General/Karaoke/exercises/any",
   "today":"2023-07-11 16:59",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"karaoke",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "module_name":"Singing",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":1
}
*/
export default {
  name: 'karaoke',
  props: 
  {
    exForm: {
      type: Object,
    },

  },
  data(){
    return {
      karaoke_text: '',
      base_audio_folder: '/BV/training/karaoke_audios/',
      instruction: 'Pick a song from the list --> ',
      file_name:'',
      artist:'',
      song_name: '',
      audio_folder: '/BV/training/karaoke_audios/Johnny Cash - I Walk the Line/',
      lrc: null,
      available_songs:[],
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 10,
      song_select_mode:true,

    }
  },
  computed: {
    filteredSongs() {
      const searchTerm = this.searchQuery.toLowerCase();
      return this.paginatedSongs.filter(
        (song) =>
          song.file_name.toLowerCase().includes(searchTerm) ||
          song.artist.toLowerCase().includes(searchTerm)
      );
    },
    paginatedSongs() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.available_songs.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.available_songs.length / this.itemsPerPage);
    },
  },
    created() 
  {

    const _this = this
    logActivity( 'accessed', 'karaoke')

    _this.lrc = new Lyric({
      onPlay: function (line, text) { // Listening play event
          // console.debug(line, text) // line is line number of current play
                                  // text is lyric text of current play line
          _this.karaoke_text = text
      },
      onSetLyric: function (lines) { // listening lyrics seting event
          // console.debug(lines) // lines is array of all lyric text
      },
      offset: 150, // offset time(ms), default is 150 ms
      isRemoveBlankLine: false // is remove blank line, default is true
    })

    var storage = _this.$firebase.storage();

    // const listRef = storage.child( _this.base_audio_folder);

    const listRef = storage.ref(_this.base_audio_folder)

    console.debug('listRef = ' + listRef)

    // listAll(storage.ref(_this.base_audio_folder)).then((res) => {
    listRef.listAll().then((res) => {

      res.prefixes.forEach((folderRef) => {

        console.debug('folderRef = ' + folderRef)

        let temp = '1'+ folderRef
        temp = temp.split('/')
        temp = temp[temp.length - 1]


        let artist = ''
        let song_name = '' 
        let split_file_name = temp.split('-')

        if(split_file_name.length>1)
        {
          artist = split_file_name[0]
          song_name = split_file_name[1]
        }


        _this.available_songs.push({name:temp,file_name:temp,artist:artist,song_name:song_name})
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
      });
      res.items.forEach((itemRef) => {
        console.debug('itemRef = ' + itemRef)

        // All the items under listRef.
      });
    }).catch((error) => {
      console.debug('error = ' + error)
      // Uh-oh, an error occurred!
    });
      
  },
  methods: 
  {
    playSong(song)
    {
      const _this = this
      this.song_select_mode = false
      _this.karaoke_text = ''
      _this.file_name = song['file_name'] 
      _this.artist = song.artist
      _this.song_name = song.song_name
      _this.audio_folder = _this.base_audio_folder + '/' + song['file_name'] + '/'

      _this.instruction = 'Follow and sing the lyrics as the song plays.'

      _this.loadLyric()

      _this.loadAudio()
    },
    loadLyric()
    {
        const _this = this


        let lyric_address = _this.audio_folder+'lyrics.lrc'
        var storage = _this.$firebase.storage();


        storage.ref(lyric_address).getDownloadURL()
        .then((url) => {

            fetch(url)
            .then(function(response) {
                response.text().then(function(lyricStr) {
                    _this.lrc.setLyric(lyricStr) // set lyric, lyricStr is lyric file text, extendedLyricStrs is extended lyric file text array (optional)

                });
            });

        
        })
        .catch((error) => {
            // Handle any errors
        });


    },
    loadAudio()
    {
        const _this = this

        // let audio_address = 'audios/karaoke/running-up-that-hill/Kate_Bush_-_Running_Up_That_Hill.mp3'
        let audio_address = _this.audio_folder+'song.mp3'
        var storage = _this.$firebase.storage();


        storage.ref(audio_address).getDownloadURL()
        .then((url) => {
            _this.playAudio(url);

        })
        .catch((error) => {
            // Handle any errors
        });

    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    nextExercise()
    {
        const _this = this

        _this.$emit('nextExercise');
    },
    playAudio(url)
    {   
        const _this = this   

        console.debug('url = ' + url)
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="50px" width="100%" controls autoplay> </video>';
        var player =document.getElementById('movie');
        player.load();

        player.addEventListener('timeupdate', (event) => {
            console.debug('The currentTime attribute has been updated. Again.');

            console.debug('time = ' + event.currentTarget.currentTime)
            _this.lrc.play(event.currentTarget.currentTime*1000)

            console.debug('_this.karaoke_text = ' + _this.karaoke_text)

            // _this.karoke_text = event.timeStamp


        });


        player.addEventListener('pause', () => {
            _this.lrc.pause()
          })




    
    },
    goToSongSelection()
    {
      this.song_select_mode = true

    },
  },
}
</script>
<style scoped>

.btn_karaoke {
  width: 50px; /* Adjusted to match the size in the image */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem; /* Adjusted for a larger arrow size */
  border-radius: 50%;
  border: none; /* Removed the border */
  background-color: #e7d7fc; /* Light purple background color */
  color: black; /* Black arrow color */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds soft shadow */
  cursor: pointer; /* Ensures pointer cursor on hover */
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.btn_karaoke:hover {
  background-color: #d8c2f7; /* Darker shade of purple for hover effect */
  transform: scale(1.1); /* Slight scaling effect */
}

.btn_karaoke:active {
  transform: scale(0.95); /* Slight shrink effect on click */
}

.btn_karaoke[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>