<template>
  <div>
  <v-tour name="voice_speech_custom" :steps="steps"></v-tour>
  <div class="container">

    <FormKit type="form" :actions="false" classs="mt-2" id="global_exercise_meta_data" @submit="submit">
      <div class="row">
        <div class="card card-body">
          <div class="v-speech_custom-0">
            <div class="row mb-4">
              <p class="text-start" for="exercise_name">Module name<span style="color:red">*</span></p>
              <div class="row">
                <VTooltip>
                  <FormKit input-class="form-control" id="exercise_name" validation-name="Module name" name="name" type="text" v-model="module_name" 
                    :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                      options: { $el: 'div' },
                      option: { $el: 'div' },
                    }"
                    messages-class="text-danger"
                    validation="required"
                  />
                  <template #popper>
                    Enter the name of your training module
                  </template>
                </VTooltip>
              </div>
            </div>

            <div class="row mb-2">
              <p class="text-start" for="instruction">Instructions<span style="color:red">*</span></p>
                <VTooltip>
                  <FormKit input-class="form-control" id="instruction" validation-name="Exercise instruction" name="instruction" type="text" 
                  :sections-schema="{
                    messages: { $el: 'div' },
                    message: { $el: 'span' },
                    options: { $el: 'div' },
                    option: { $el: 'div' },
                  }"
                  messages-class="text-danger"
                  placeholder="E.g., Follow the video instruction. Then press Start to try it by yourself." v-model="global_exercise_meta_data.instruct" validation="required"/>
                  <template #popper>
                    Enter the instruction for patients to follow
                  </template>
                </VTooltip>
            </div>
          </div>
        </div>
        <div class="card card-body mt-2">
          <div class="v-speech_custom-1">
            <p class="text-start mb-4">Exercises<span style="color:red">*</span></p>
            <hr/>
            <div class="row mb-4" v-for="exercise_, index in exercises" :key="index">
              <div class="row">
                <div class="col-1 d-flex align-items-center justify-content-center">
                  <p class="text-center">{{ index + 1 }}.</p>
                </div>
                <div class="col-6">
                  <VTooltip>
                    <textarea type="textarea" class="form-control" name="content" v-model="exercise_['text']" required />
                    <template #popper>
                      Enter the training text (for display and analysis) 
                    </template>
                  </VTooltip>
                </div>
                <div class="col-3 v-speech_custom-3">
                  <div class="row">
                    <div class="col">
                      <p style="margin-bottom: 2px;">Repeat <strong>{{exercise_.n_repeats}}</strong> times</p>
                      <button type="button" class="btn btn-outline-primary ms-2 me-2" @click="exercise_.n_repeats-1 > 0 ? exercise_.n_repeats-- : false"><i class="bi bi-dash-circle"></i></button> 
                      <button type="button" class="btn btn-outline-primary" @click="exercise_.n_repeats++"><i class="bi bi-plus-circle"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col" >
                  <div class="float-end">
                    <button type="button" class="mt-4 btn btn-outline-danger" style='vertical-align: middle' @click="deleteExercise(index)"><i class="bi bi-x-lg"></i></button>
                  </div>
                </div>
                <div class="row">
                  <div class="d-flex justify-content-start align_items-start">
                    <button v-show="(exercise_.Audio_Address==null) || (exercise_.Video_Address == null)" class="btn btn-primary v-speech_custom-2 mt-4 me-2" type="button" @click="addVideo(index)">
                      <i class="bi bi-camera-video"></i> Record video
                    </button>
                    <button v-show="exercise_.Video_Address && (curr_recording_index === index || curr_recording_index === null)" class="btn btn-warning mt-4 me-2" type="button" data-bs-toggle="modal" data-bs-target="#watch_instructions_modal" @click="watchVideo(index)">
                      <i class="bi bi-camera-video"></i> Review video
                    </button>
                    <button v-show="exercise_.Video_Address && (curr_recording_index === index || curr_recording_index === null)" class="btn btn-danger mt-4 me-2" type="button" @click="deleteVideo(index)">
                      <i class="bi bi-camera-video"></i> Delete video
                    </button>
                  </div>
                </div>

              <hr class="mt-4"/>
              </div>
            </div>
            <div class="row">
              <div>
                <button type="button" class="btn btn-info w-25" @click="addExercise()"><i class="bi bi-plus-lg"></i> Add exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row v-speech_custom-4">
        <div class="card card-body mt-2">
          <div class="row">
            <p class="text-start mb-2">Assistance and analysis options</p>
            <hr/>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card card-body h-100">
                <p><strong>Real time assistance (choose up to one)</strong></p>

                <div class="row">
                  <div class="input-group">
                    <div class="form-check">
                      <VTooltip>
                        <label class="form-check-label" for="Loudness">Loudness meter</label>
                        <input class="form-check-input" type="radio" v-model="global_exercise_meta_data.assist" id="Loudness" value="spm_live">
                        <template #popper>
                          Indicates the patient's loudness in real time
                        </template>
                      </VTooltip>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="form-check">
                      <VTooltip>
                        <label class="form-check-label" for="Pitch">Pitch frequency meter</label>
                        <input class="form-check-input" type="radio" v-model="global_exercise_meta_data.assist" id="Pitch" value="spectrogram">
                        <template #popper>
                        Indicates the patient's pitch in real time
                        </template>
                      </VTooltip>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="form-check">
                      <VTooltip>
                        <label class="form-check-label" for="Pace">Pace indicator</label>
                        <input class="form-check-input" type="radio" v-model="global_exercise_meta_data.assist" id="Pace" value="pace_ind">
                        <template #popper>
                        Indicates target talking speed in real time. Note: demo video would not appear if this assistance is selected.
                        </template>
                      </VTooltip>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="form-check">
                      <label class="form-check-label" for="Pace">No assistance</label>
                      <input class="form-check-input" type="radio" v-model="global_exercise_meta_data.assist" id="Pace" value="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card card-body">
                <p><strong>Voice analysis</strong></p>              
                <div class="row">
                  <div class="input-group">
                    <div class="form-check form-check-inline">
                      <label class="form-check-label" for="max_phonation">Max phonation time (s)</label>
                      <input class="form-check-input" type="checkbox" v-model="global_exercise_meta_data.voicetype" id="max_phonation" value="max_phonation">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="form-check form-check-inline">
                      <label class="form-check-label" for="f0">Fundamental frequency f0 (Hz)</label>
                      <input class="form-check-input" type="checkbox" v-model="global_exercise_meta_data.voicetype" id="f0" value="f0">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="form-check form-check-inline">
                      <label class="form-check-label" for="jitter_shimmer">Jitter and shimmer</label>
                      <input class="form-check-input" type="checkbox" v-model="global_exercise_meta_data.voicetype" id="jitter_shimmer" value="jitter_shimmer">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group">
                    <div class="form-check form-check-inline">
                      <label class="form-check-label">Average loudness</label>
                      <input class="form-check-input" type="checkbox" disabled checked id="loudness">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12">
              <div class="card card-body">
                <p><strong>Speech analysis</strong></p>
                <div class="input-group">
                  <div class="form-check form-check-inline">
                    <label class="form-check-label" for="speech_rate">Speech rate (words/s)</label>
                    <input class="form-check-input" type="checkbox" v-model="global_exercise_meta_data.speechtype" id="speech_rate" value="speech_rate">
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label" for="sentencegibility">Sentence intelligibility</label>
                    <input class="form-check-input" type="checkbox" v-model="global_exercise_meta_data.speechtype" id="sentencegibility" value="sentencegibility">
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label" for="intelligibility">Phoneme intelligibility (only for single word recordings)</label>
                    <input class="form-check-input" type="checkbox" v-model="global_exercise_meta_data.speechtype" id="intelligibility" value="intelligibility">
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label" for="ddk_rate">Diadochokinetic rate</label>
                    <input class="form-check-input" type="checkbox" v-model="global_exercise_meta_data.speechtype" id="ddk_rate" value="ddk_rate">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-6">
          <p>Save to: </p>
          <select class="form-select" v-model="module_location">
            <option v-if="!module_folder_locations.includes('General')" value="General">General</option>
            <option v-for="ml in module_folder_locations" v-bind:key="ml" :value="ml">{{ ml }}</option>
            <option value="__other__">Other Location</option>
          </select>
        </div>
      </div>
      <div class="row justify-content-center mt-2" v-if="module_location === '__other__'">
        <div class="col-6">
          <input type="text" class="form-control" v-model="custom_location" placeholder="Name of location"/>
        </div>
      </div>
      <div class="row mt-4 mb-4 justify-content-center">
        <button class="btn btn-secondary w-25 me-2" type="button" @click="cancel">Cancel</button>
        <button class="btn btn-success w-25 me-2" type="submit" @click="add_homework = false">Save</button>
        <button class="btn btn-outline-success w-25" type="submit" @click="add_homework = true">Add to homework list</button>
      </div>
    </FormKit>
    <div class="modal fade" id="record_instructions_modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="video_type" class="modal-title">
              Record video
            </h5> 
            <h5 v-else class="modal-title">
              Record audio
            </h5>
          </div>
          <div class="modal-body">
            <Instructions_recording v-if="recording" :video_type="video_type" @audioClosed="audioClosed($event)" @videoClosed="videoClosed($event)"/>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="watch_instructions_modal" tabindex="-1" aria-labelledby="watch_instructions_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Watch video
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <Watch_recording ref="Watch_recording" @close="watch_instructions=false"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>
<script>

import {updateData, updateDataDirectory, setData, getDataDirectoryCallback} from '@/firebase/index'
import Instructions_recording from '../components/instructions_recording.vue'
import Watch_recording from '../components/watch_recording.vue'
import {getUserDataDir} from '@/firebase/index'
import { reactive } from 'vue'
import { Modal } from 'bootstrap'
import { useToast } from "vue-toastification";

export default {
  name: 'speech_custom_module_setup',
  components: {Instructions_recording, Watch_recording},
  props: {
    edit_meta_module_pointer: null,
    module_folder_locations: []
  },
  data(){
    return {
      module_location: 'General',
      custom_location: '',
      record_instructions: false,
      watch_instructions: false,
      audio_address:'',
      curr_item_index: 0,
      video_type: true,
      module_name: '',
      global_exercise_meta_data: {
        instruct: '',
        voicetype: [],
        speechtype: [],
        assist: "",
      },
      exercises: reactive([]),
      steps: [
        {
          target: '.v-speech_custom-0',
          content: "<div class='text-start'>Let’s create a customised voice and speech module!<br/><br/>You will need to input a module name and a general instruction on how to perform the exercises.</div>",
          params: {
            highlight: true
          }
        },
        {
          target: '.v-speech_custom-1',
          content: "<div class='text-start'>Then, input the number of exercises you would like to have. <br/><br/>For the exercise content, depending on what you would like your patient to perform, type in a specific word/sentence/instruction.</div>",
          params: {
            highlight: true
          }
        },
        {
          target: '.v-speech_custom-2',
          content: "<div class='text-start'>You can record a video demo for the exercise by clicking",
          params: {
            highlight: true
          }
        },
        {
          target: '.v-speech_custom-3',
          content: "<div class='text-start'>You can repeat the exercise multiple times by increasing this counter.",
          params: {
            highlight: true
          }
        },
        {
          target: '.v-speech_custom-4',
          content:  "<div class='text-start'>Here, you can choose various assistance and analysis options for the module by ticking the checkboxes." +
                    "<ul class='text-start'>" +
                    "<li><strong>Real time assistance:</strong> the selected assistance will appear in each exercise.</li>" +
                    "<li><strong>Voice and speech analysis:</strong> select the metrics you would like the platform to analyse. Results of these metrics will appear at the end of the exercises. These results are also stored and can be revisited via the Results tab.</li>" +
                    "</ul></div>",
          params: {
            highlight: true
          }
        },
      ],
      recording_modal: null,
      recording: false,
      custom_module_pointer: null,
      add_homework: false,
      curr_recording_index: null,

      client_uid: localStorage.getItem('client_uid')
    }
  },
  created() {

    let _this = this

    if (this.edit_meta_module_pointer) {

      let module_data = this.edit_meta_module_pointer.module_handle.val()

      _this.module_location = _this.edit_meta_module_pointer.location
      _this.module_name = _this.edit_meta_module_pointer.module_name

      for (let i=0; i<module_data['exercises'].length; i++) {

        let exercise_address = module_data['exercises'][i]['address']

        getDataDirectoryCallback(exercise_address, (err, exercise_data) => {

          let exercise_data_ = exercise_data.val()
          _this.exercises.push( {
            text: exercise_data_['text'],
            instruct: exercise_data_['instruct'],
            n_repeats: exercise_data_['n_repeats'] ? exercise_data_['n_repeats'] : 1,
            Audio_Instructions: exercise_data_['Audio_Instructions'] ? exercise_data_['Audio_Instructions'] : null,
            Video_Instructions: exercise_data_['Video_Instructions'] ? exercise_data_['Video_Instructions'] : null,
            Video_Address: exercise_data_['Video_Address'] ? exercise_data_['Video_Address'] : null,
            Audio_Address: exercise_data_['Audio_Address'] ? exercise_data_['Audio_Address'] : null, 
          })


          //these are global settings that we store in each exercise_data but for UI we only store once
          _this.global_exercise_meta_data['instruct'] = exercise_data_['instruct'] ? exercise_data_['instruct'] : ''
          _this.global_exercise_meta_data['voicetype'] = exercise_data_['voicetype'] ? exercise_data_['voicetype'] : []
          _this.global_exercise_meta_data['speechtype'] = exercise_data_['speechtype'] ? exercise_data_['speechtype'] : []
          _this.global_exercise_meta_data['assist'] = exercise_data_['assist'] ? exercise_data_['assist'] : []

        });
      }

      
    } else {
      this.exercises.push({
        text: '',
        n_repeats: 1,
        Audio_Instructions: null,
        Video_Instructions: null,
        Video_Address: null,
        Audio_Address: null,
      })
    }


    //TOUR   ///////////////////////////////////////////////////////////////////////////////

    
    let user_page_dir = 'Data/';

    getUserDataDir(user_page_dir , function (err, result) {
        
      let startTour = result.child('startTour').val();
      
      if (startTour === null || startTour['voice_speech_custom'] === undefined || startTour['voice_speech_custom'] === false) {
        _this.$tours['voice_speech_custom'].start()

        if (typeof startTour !== "object") {
          startTour = {}
        }
        
        startTour['voice_speech_custom'] = true

        let uid = localStorage.getItem('uid')
        var ref = _this.$firebase.database().ref('Users').child(uid).child('Data');
        updateData(ref, {'startTour': startTour})
      }
    });

  },
  mounted() {
    this.recording_modal = new Modal(document.getElementById('record_instructions_modal'))
  },
  methods: {
    openRecordingModal() {
      this.recording = true
      this.recording_modal.show()
    },
    closeRecordingModal() {
      this.recording = false
      this.recording_modal.hide()
    },
    addExercise() {
      this.exercises.push(
        {
          text: "",
          n_repeats: 1,
          Audio_Instructions: null,
          Video_Instructions: null,
          Video_Address: null,
          Audio_Address: null,
        }      
      )
    },
    deleteExercise(index) {
      if (this.exercises.length-1 > 0) {
        this.exercises.splice(index, 1)
      } else {
        alert("You need to have at least one exercise.")
      }
    },
    submit() {
      const _this = this

      let uid = localStorage.getItem('uid');
      
      if (this.module_location === '__other__') {
        if (this.custom_location === '') {
          this.module_location = 'General'
        } else {
          this.module_location = this.custom_location
        }
      }

      let module_page_ref = _this.$firebase.database().ref('/Custom_Modules').child(uid).child(this.module_location).child('SpeechModules');

      let module_info = {
        mod_name: _this.module_name
      }

      updateData(module_page_ref.child(_this.module_name), module_info);
      setData(module_page_ref.child(_this.module_name).child('exercises'), {});

      let base_exercise_address = 'Custom_Exercises/' + uid + '/' +this.module_location + '/SpeechModules/' + _this.module_name

      let exercise_page_ref = _this.$firebase.database().ref(base_exercise_address);

      let exercise_index = 0
      
      for (let n=0; n < _this.exercises.length; n++) {
        for (let k=0; k < _this.exercises[n].n_repeats; k++) {
          let push_key = exercise_page_ref.push().key;

          let temp_form = {
            instruct: _this.global_exercise_meta_data.instruct,
            text: _this.exercises[n].text,
            Audio_Instructions: _this.exercises[n].Audio_Instructions,
            Video_Instructions: _this.exercises[n].Video_Instructions,
            Audio_Address: _this.exercises[n].Audio_Address,
            Video_Address: _this.exercises[n].Video_Address,
            voicetype: _this.global_exercise_meta_data.voicetype,
            speechtype: _this.global_exercise_meta_data.speechtype,
            assist: _this.global_exercise_meta_data.assist,
          }

          if(_this.global_exercise_meta_data.voicetype.length==0 && _this.global_exercise_meta_data.speechtype.length==0 && _this.global_exercise_meta_data.assist.length==0) {
            temp_form.capture_audio = false
          }
          
          if(_this.global_exercise_meta_data.assist.includes('pace_ind')) {
            temp_form['exercise_type'] = 'pace_training'
          }
          

          let exercise_address = base_exercise_address + '/' + exercise_index
          
          updateDataDirectory(exercise_address, temp_form)

          let module_exercise_info = {
            address: exercise_address,
            exercise_range:'single_exercise'
          }

          console.log(module_exercise_info)
          updateData(module_page_ref.child(_this.module_name).child('exercises').child(exercise_index), module_exercise_info);

          exercise_index = exercise_index + 1
        }
      }

      _this.custom_module_pointer = module_page_ref.child(_this.module_name)

      if (this.add_homework) {
        this.addToHomeworkList()
      } else {
        const toast = useToast()
        toast.success("Module saved!")
        this.module_saved()
      }
    },
    async addToHomeworkList() {
      const _this = this
      let pro = await this.custom_module_pointer.get()
      _this.$emit('add_to_assignment_list', pro)
    },
    deleteVideo(index) {
      this.exercises[index].Audio_Instructions = null
      this.exercises[index].Audio_Address = null

      this.exercises[index].Video_Instructions = null
      this.exercises[index].Video_Address = null
    },
    watchVideo(index) {
      const _this = this

      let curr_video_address = this.exercises[index].Video_Address
      this.watch_instructions = true

      setTimeout(() => {
        _this.$refs.Watch_recording.watchVideoInstructions(curr_video_address)
      }, 100);

    },
    addVideo(index) {
      this.openRecordingModal()
      this.curr_item_index = index
      this.curr_recording_index = index
      this.record_instructions = true
      this.video_type = true

    },
    addAudio(index) {
      this.curr_item_index = index
      this.curr_recording_index = index
      this.record_instructions = true
      this.video_type = false
    },
    audioClosed(audio_address) {
      const _this = this

      console.debug('audio_address = ' + audio_address)
      _this.record_instructions = false

      if(audio_address!=null) {
        let index = _this.curr_item_index
        _this.curr_recording_index = null
        _this.exercises[index].Video_Instructions = null
        _this.exercises[index].Video_Address = null

        _this.exercises[index].Audio_Instructions = audio_address
        _this.exercises[index].Audio_Address = audio_address
      }
      this.closeRecordingModal()
    },
    videoClosed(video_address) {
      const _this = this

      console.debug('video_address = ' + video_address)
  
      _this.record_instructions = false

      if(video_address!=null) {
        let index = _this.curr_item_index
        _this.curr_recording_index = null
        console.debug('index = ' + index)

        
        _this.exercises[index].Video_Instructions = video_address
        _this.exercises[index].Video_Address = video_address

        _this.exercises[index].Audio_Instructions = null
        _this.exercises[index].Audio_Address = null
      }  

      this.closeRecordingModal()

    },
    cancel() {
      this.$emit('cancel')
    },
    module_saved () {
      this.$emit('module_saved', this.module_location)
    }
    
  },
}
</script>

<style scoped>

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
}
</style>