<template>
  <div class="basic_exercise">
    <div  v-if="showResults" style="text-align:center">
      <Results name="results" :start_object="start_object" :resultsTable="results_table" :results_type="results_type" emitClose=true @emitClose="closeDialog"/>
    </div>
    <div v-else>
      <div class="row">
        <p class="text-center">Exercise {{current_exercise_index+1}} of {{total_exercises}}</p>
      </div>
      <div class="row text-center" v-for="(line,lineNumber) of Ex_Description_Split" v-bind:key="lineNumber"> 
        <b style="fontSize:18px"> {{line}} </b>
      </div>
      <div class="row">
        <div class="card card-body" v-if="Ex_Text!==''">
          <div class="row text-center"
              v-for="(line,lineNumber) of Ex_Text_Split" 
              v-bind:key="lineNumber">
              <p class="lead"><strong>{{ line }}</strong></p>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" v-if="hasVideoInstructions || hasAudioInstructions" id="video_instructions_div" style="margin-top:-10px;text-align:center">
        <div class="col-10">
          <video id="movie" style="width:100%" controls autoplay> </video> 
        </div>
      </div>

      <div class="row justify-content-center"  style="margin-top:1%" id="meter-row" >
        <div class="col-6">
          <canvas id="meter" height="25"></canvas>
        </div>
      </div>


      <div v-if="audioCaptureOn" class="row justify-content-center text-center">
        <div class="mt-2">
          <div class="d-grid gap-2 d-md-block">
            <button :class="['btn w-25 me-2', play_pause_text === 'Record' ? 'btn-primary' : 'btn-info']"  @click="playPause()">{{play_pause_text}} </button>
            <button class="btn btn-secondary w-25 " @click="skipExercise()"> Skip </button>
          </div>
        </div>

        <div class="mt-2"> 
          <button v-if="has_results" class='btn btn-warning' @click="goToResults()">Your results so far </button>
        </div>
      </div>
      <div v-else class="row justify-content-center mt-2">
        <button class="btn btn-info w-50" @click="noAudioCaptureNext()"> Next </button>
      </div>
    </div>
  </div>
</template>

<script>


import homeplanImg from '@/assets/images/homeplan.png'
import {generate_results_path} from '@/utils/results/data_save_utils'

import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import {startRecordingMedia, addSpectrogram, removeSpectrogram, resumeRecordingMedia, pauseRecordingMedia, resetRecordingMedia, getRecordingMedia, checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia } from '@/media_input/microphone_recording'
import smoothie from 'smoothie'
import {removeWeirdCharactersFromString} from '@/media_input/utils'
import {updateDataDirectory } from '@/firebase/index'

import Results from '@/views/session/Normal_Session/End_results.vue'

export default {
    name: 'basic_exercise_popup',
    components: {Results},
    props: {
      exForm_list: {
        type: Array,
      },
    },
    data() {
        return {
            showResults: false,
            results_type: 'end',
            start_object: {
              pre_loaded_exercises: null,
              current_exercise_index: 0,
            },
            hasVideoInstructions: false,
            hasAudioInstructions: false,
            video_instructions_address: '',
            audio_instructions_address: '',
            sound_l:'',
            sound_l2:'',
            chart_data: null,
            chart_data_spect: null,
            smoothie_spect: null,
            smoothie: null,
            drawInterval: null,
            Ex_Text: '',    
            Ex_Description: [],
            ex_name: '',
            avg_loudness_array: [],
            full_avg_loudness_array: [],
            avg_loudness: 0,
            avg_loudness_show: 0,
            hasSPLMeter: false,
            last_loudness: 0,
            total_loudness: 0,
            counter_loudness: 0,
            audioCaptureOn: true,
            hasSpectrogram: false,
            chart_not_created: true,
            chart_not_created_spect: true,
            canvasContext: null,
            play_pause_text: 'Record',
            finished_msg: '',
            play_status: true,
            curr_exercise_number: 0,
            number_exercises: 1,
            homeplanImg: homeplanImg,
            results_table: {back:[],pre:[]},
            crossImg: crossImg,
            doneIcon: doneIcon,
            inputAcc:'',
            modules_results_path: null,
            resultsTableBack: [],
            has_results: false,
            is_recording: false,
            url:'',
            url2image:'',
            userType: localStorage.getItem('userCategory'),
            client_number: null,
            uid: localStorage.getItem('uid'),
            loud_target:65,
            styleCardWin: {
                width:'95%',
                margin: 'auto',
                position:'relative',
                top:'0px'
            },
            styleimgWin: {
                width:'40px',
                position:'absolute',
                top:'20px',
                right:'30px'
            },
            styleimgMob: {
                width:'20px',
                position:'absolute',
                top:'30px',
                right:'10px'
            },
            styleCardMob: {
                width:'100%',
                margin: 'auto',
                // position:'relative',
                // top:'50px'
            },
            styleBtn: {
                margin: 'auto',
                position:'relative',
                // left: '20px',
                top:'20px'
            },
            styleBtn2: {
                margin: 'auto',
                position:'relative',
                // left: '90px',
                top:'20px'
            },
            frameColour: ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
            styleCardOrig:
            {
              width:'310px',
              margin:'auto',
              // background:'#fce5c6'
            },
            exForm: ''
        }

        
    },
    created() {
      let _this = this;
      checkPermissionsMedia(); 

      console.debug('_this.exForm = '  + _this.exForm)

      if(this.userType=='slp')
      {
        let client_number = localStorage.getItem('client_uid');

        if(client_number!='undefined' && client_number!=null && client_number!='')
        {
            _this.client_number = client_number; 
        }
      }

      _this.current_exercise_index = -1
      _this.nextExercise();
    },
    computed: {
      Ex_Description_Split: function () {
        // `this` points to the vm instance
        return this.Ex_Description.toString().split('\n')
      },
      Ex_Text_Split: function()
      {
        return this.Ex_Text.toString().split('\n')
      },

    },
    beforeUnmount()
    {        
      const _this = this

      clearInterval(window.refreshIntervalId)


      if(_this.is_recording)
      {

        _this.endRecordingAudio(true);
      }
      else
      {
        _this.endRecordingAudio(false);
      }

      deleteSoundMeter()
    },
    mounted: function()
    {
      //this.renderGraphs()   
    },
    methods: 
    {
      renderGraphs()
      {
        const _this = this
            
        if(_this.hasSPLMeter)
        {
          document.getElementById("SPL-div").hidden = false
          
          _this.createChart()
        }
        else
        {
          document.getElementById("SPL-div").hidden = true
        }


        if(_this.hasSpectrogram)
        {
          document.getElementById("Spect-div").hidden = false
          
          _this.createChartSpect()
        }
        else
        {
          document.getElementById("Spect-div").hidden = true
        }

      },
      openURL()
      {
        const _this = this

        
        window.open(_this.url);


      },
     
      nextExercise()
      {
        const _this = this;

        console.debug(_this.is_recording)

        _this.pause();

        _this.loadSingleExercise()

        
      },
      loadSingleExercise()
      {
          
        const _this = this

        _this.resultsTableBack = []
        
        _this.current_exercise_index = _this.current_exercise_index + 1
        
        _this.exForm = _this.exForm_list[_this.current_exercise_index]

        _this.Ex_Text = _this.exForm.text; 

        console.debug('_this.exForm = ' + _this.exForm)
        _this.Ex_Description = _this.exForm.instruct;

        _this.total_exercises = _this.exForm_list.length

        let url = _this.exForm.url
        let url2image = _this.exForm.url2image

        if(url!=undefined && url!=null)
        {
          _this.url = url
        }

        if(url2image!=undefined && url2image!=null)
        {
          console.debug('url2image = ' + url2image)
          _this.url2image = url2image
          // document.getElementById(_this.img_1).src = url2image;

        }
        
        _this.Ex_Description = removeWeirdCharactersFromString(_this.Ex_Description)
        
        _this.Ex_Text = removeWeirdCharactersFromString(_this.Ex_Text)
    

        let captureAudio = _this.exForm.capture_audio


        if(captureAudio != undefined && captureAudio != null)
        {
          _this.audioCaptureOn = captureAudio
        }
        else
        {
            _this.audioCaptureOn = true
        }



        if(_this.Ex_Text == undefined || _this.Ex_Text == null )
        {
            _this.Ex_Text = ""
        }
        if(_this.Ex_Description == undefined || _this.Ex_Description == null )
        {
            _this.Ex_Description = ""
        }


        _this.checkAudioVideoInstructions()
        // _this.checkAssistance()


      },
      checkAudioVideoInstructions()
      {
        const _this = this

        let hasVideoInstructions = _this.exForm.Video_Instructions;

        console.debug('_this.exForm.videoIns = ' + _this.exForm.videoIns)

        if(hasVideoInstructions!=undefined && hasVideoInstructions!=null)
        {
            _this.hasVideoInstructions = hasVideoInstructions;
        }
        else
        {
            _this.hasVideoInstructions = false;
        }


        // let hasAudioInstructions = _this.exForm.audioIns;

        let audioIns = _this.exForm.audioIns

        if(audioIns!=undefined & audioIns!=null)
        {
            _this.hasAudioInstructions = true;
        }
        else
        {
            _this.hasAudioInstructions = false;
        }

        if(_this.hasVideoInstructions)
        {
            _this.video_instructions_address = _this.exForm.Video_Address;

            console.debug('_this.video_instructions_address = ' + _this.video_instructions_address)
            _this.watchVideoInstructions()
        }
        else if(_this.hasAudioInstructions)
        {
          _this.audio_instructions_address = audioIns
            // _this.audio_instructions_address = _this.exForm.audioAddress;

            _this.watchVideoInstructions()
        }

      },
      watchVideoInstructions()
      {
        const _this = this;

        if(_this.is_recording)
        {
          _this.is_recording = false
          _this.endRecordingAudio(false);
          _this.play_pause_text = "Record"
        }

        if(_this.hasVideoInstructions)
        {
          _this.is_recording = false;
      
          let storage = _this.$firebase.storage();

          storage.ref(_this.video_instructions_address).getDownloadURL()
            .then((url) => {
              _this.playVideoInstructions(url);

            })
            .catch((error) => {
              // Handle any errors
            });
        }
        else if(_this.hasAudioInstructions)
        {

          // document.getElementById('ex_div').hidden = true;

          // document.getElementById('mic_dist_div').hidden = true;

          // document.getElementById('video_instructions_div').hidden = false;

          stopRecordingMedia(null,null,null);
          
          // clearInterval(_this.drawInterval);
          clearInterval(window.refreshIntervalId);
  
          _this.drawLoop(0);

          _this.is_recording = false;
      
          var storage = _this.$firebase.storage();


          storage.ref(_this.audio_instructions_address).getDownloadURL()
            .then((url) => {
              _this.playAudioInstructions(url);

            })
            .catch((error) => {
              // Handle any errors
            });
        }
      },
      playVideoInstructions(url)
      {      

        console.debug('url = ' + url)
        // document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="440px" width="660px" controls autoplay> </video>';
        document.getElementById('movie').src = url
        var player =document.getElementById('movie');
        player.load();

        
      },  
      checkAssistance()
      {
        const _this = this

        let assistance = _this.exForm.assist



        _this.hasSPLMeter = false;

        if(assistance!=null)
        {
          console.debug('assistance = ' + assistance)
          
          if(Array.isArray(assistance) && assistance.includes('spm_live'))
          {
              _this.hasSPLMeter = true;


          }
          else if('spm_live' in assistance)
          {
              _this.hasSPLMeter = true;

          }

          if(Array.isArray(assistance) && assistance.includes('spl_live'))
          {
              _this.hasSPLMeter = true;


          }
          else if('spl_live' in assistance)
          {
              _this.hasSPLMeter = true;

          }

          if(Array.isArray(assistance) && assistance.includes('spectrogram'))
          {
              _this.hasSpectrogram = true;


          }
          else if('spl_live' in assistance)
          {
              _this.hasSpectrogram = true;

          }



        }


        // _this.hasSpectrogram = true

      },
      runTimer() {
    

        var interval_time = 250;

        const _this = this;

        clearInterval(window.refreshIntervalId)


        window.refreshIntervalId = setInterval(function () {

        if (soundMeter != null) {

            

            // if(element!=null)
            {

              // var sound_l = soundMeter.instant.toFixed(2) ;
              var sound_l = soundMeter.db.toFixed(2);
              _this.sound_l2 = soundMeter.db2.toFixed(2);
              var max_freq_index = soundMeter.max_freq_index.toFixed(2);


              // let sound_calibration = 20;



              let sound_threshold = 40;
              

            //   console.debug('sound_l = ' + sound_l)
              if(sound_l>sound_threshold)
              {
              
                _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
                
                _this.avg_loudness_show = _this.roundNumber(_this.avg_loudness,0)

                // console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)


                _this.counter_loudness += 1;

                
              
              }

                const min_spect_loudness = 45


                if(_this.hasSpectrogram && sound_l>min_spect_loudness)
                {
                  _this.addDataChartSpect(max_freq_index)

              }

              if(!(_this.hasSPLMeter||_this.hasSpectrogram))
              {

                let element = document.getElementById("meter")

                _this.canvasContext = element.getContext("2d");
                _this.drawLoop(sound_l);

              }

              if(_this.hasSPLMeter)
              {

              // _this.addDataChart(max_freq_index)
              _this.addDataChart(sound_l)
              }

            
            
            }

        }

        }, interval_time);
      

      },
      roundNumber(number,number_of_decimals)
      {   
        let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)

        return rounded_number;

      },
      skipExercise()
      {
        const _this = this

        // _this.$emit('nextExercise');

        if(_this.current_exercise_index>=(_this.exForm_list.length-1))
        {
            _this.goToResults()
        }
        else
        {
        _this.nextExercise()

        }


      },
      noAudioCaptureNext()
      {
        const _this = this;

        _this.is_recording = false

        var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: _this.avg_loudness_array}

        if(_this.current_exercise_index>=(_this.exForm_list.length-1))
        {
            _this.goToResults()
        }
        else
        {
          _this.nextExercise()

        }
        // _this.$emit('logExerciseResults', basicData);
        
        // _this.$emit('nextExercise');


      },
      goToResults()
      {
        console.debug(this.results_table)
        //console.debug(this.results_type)
        //console.debug(this.start_object)
        //console.debug(this.avg_loudness_array)
        this.showResults = true
      },
      playPause()
      {
          
        const _this = this;


        if(_this.play_status)
        {

          console.debug('_this.avg_loudness = ' + _this.avg_loudness)

          _this.last_loudness = _this.avg_loudness

          if(_this.avg_loudness>0)
          {
            _this.full_avg_loudness_array.push(_this.avg_loudness)
          }

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;



          if(_this.is_recording)
          {
            _this.is_recording = false

            _this.endRecordingAudio(true)

          }


          var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: _this.avg_loudness_array}

          _this.basicClosed(basicData)
          if(_this.current_exercise_index>=(_this.exForm_list.length-1))
          {
              _this.goToResults()
          }
          else
          {
            _this.nextExercise()

          }
        }
        else
        {
          _this.play_status = true;
          _this.play_pause_text = 'Stop'


          _this.resumeRecording()

          // if(_this.hasSPLMeter)
          {
            _this.runTimer()
          }

        }

      },
      basicClosed(basicData)
      {
        const _this = this


        let resultsTableBack = basicData.resultsTableBack

        if(resultsTableBack!=null)
        {
            for(let i in resultsTableBack)
            {
                _this.results_table['back'].push(resultsTableBack[i])
            }

        }

      },
      resumeRecording()
      {
          var _this = this;
      
          // resumeRecordingMedia();
          if(!_this.is_recording)
          {
              recordAudioMedia()
              _this.is_recording = true;
          }
  
      },
      pauseRecordingAudio()
      {

          const _this = this;

          if(_this.is_recording)
          {
              stopRecordingMedia(null,null,null);


              _this.is_recording = false;

          }
      

      },
      endRecordingAudio(save_audio)
      {
      
          const _this = this;

  
          if(save_audio)
          {

            console.debug('curr_exercise_path = ' + _this.exForm.curr_exercise_path)

            let results_addresses = generate_results_path(this.exForm)

            let random_identifier = results_addresses['random_identifier']

            let exercises_results_path = results_addresses['exercises_results_path']
            let modules_results_path = results_addresses['modules_results_path']

            
            let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;

            if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
              audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
            }

            var audio_address = 'audios/' + audio_folder + '.mp3';

            audio_address = audio_address.replaceAll('//','/')


            console.debug("Saving " + audio_address);


            
            let today = _this.exForm.today
            
            let voicetype = _this.exForm.voicetype
            let speechtype = _this.exForm.speechtype


            _this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')

            updateDataDirectory(modules_results_path, {plan_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})

            let name = _this.exForm.name
            if(name == undefined || name == null || name == '')
            {
              name = _this.Ex_Text
            }

            let push_structure = {
              'date': today,
              'name': name,
              'path': exercises_results_path , 
              'audio_address': audio_address,
              'uid': _this.uid,
              'loudness': _this.last_loudness
            }
            
            console.debug('today = ' + today)

            let ground_truth = _this.Ex_Text

            if(ground_truth!=undefined && ground_truth!=null)
            {
              ground_truth = ground_truth.replaceAll('\n','')

            }
            else{
              ground_truth = ''
            }
            let has_analysis = false



            if(voicetype!=null && voicetype!=undefined)
            {
              has_analysis = true

              if(Array.isArray(voicetype) && voicetype.includes('intelligibility'))
              {
              push_structure['intelligibility'] = ground_truth
              }
              else if('Intelligibility' in voicetype)
              {
              push_structure['intelligibility'] = ground_truth

              }

              if(Array.isArray(voicetype) && voicetype.includes('sentencegibility'))
              {

              push_structure['sentencegibility'] = ground_truth

              }
              else if('sentencegibility' in voicetype)
              {
              push_structure['sentencegibility'] = ground_truth
              }


              if(Array.isArray(voicetype) && voicetype.includes('max_phonation'))
              {
              push_structure['max_phonation'] = '1'
              }
              else if('max_phonation' in voicetype)
              {
              push_structure['max_phonation'] = '1'
              }


              if(Array.isArray(voicetype) && voicetype.includes('speech_rate'))
              {
              push_structure['speech_rate'] = '1'
              }
              else if('speech_rate' in voicetype)
              {
              push_structure['speech_rate'] = '1'
              }

              if(Array.isArray(voicetype) && voicetype.includes('jitter_shimmer'))
              {
              push_structure['jitter_shimmer'] = '1'
              }
              else if('jitter_shimmer' in voicetype)
              {
              push_structure['jitter_shimmer'] = '1'
              }
                    
            }


            if(speechtype!=null && speechtype!=undefined)
            {
              has_analysis = true

              if(Array.isArray(speechtype) && speechtype.includes('ddk_rate'))
              {
              push_structure['ddk_rate'] = '1'
              }

              if(Array.isArray(speechtype) && speechtype.includes('intelligibility'))
              {
              push_structure['intelligibility'] = ground_truth
              }
              else if('Intelligibility' in speechtype)
              {
              push_structure['intelligibility'] = ground_truth
              }


              if(Array.isArray(speechtype) && speechtype.includes('sentencegibility'))
              {
              push_structure['sentencegibility'] = ground_truth


              }
              else if('sentencegibility' in speechtype)
              {
              push_structure['sentencegibility'] = ground_truth


              }

              if(Array.isArray(speechtype) && speechtype.includes('speech_rate'))
              {
              push_structure['speech_rate'] = '1'
              }
              else if('speech_rate' in speechtype)
              {
              push_structure['speech_rate'] = '1'
              }

              if(Array.isArray(speechtype) && speechtype.includes('jitter_shimmer'))
              {
              push_structure['jitter_shimmer'] = '1'
              }
              else if('jitter_shimmer' in speechtype)
              {
              push_structure['jitter_shimmer'] = '1'
              }
            }


            console.debug('push structure = ' + push_structure)
            if(has_analysis)
            {
              _this.resultsTableBack.push(exercises_results_path)
              _this.modules_results_path = modules_results_path
              // console.debug('path = ' + modules_results_path)
              // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
              stopRecordingMedia(audio_address, push_structure, _this);
            } else {
              stopRecordingMedia(audio_address, null, null);
            }
          }
          else
          {
            stopRecordingMedia(null,null,null);
          }            
        this.is_recording = false;
      }, 
      createChart()
      {
        const _this = this
        
        if(_this.chart_not_created)
        {
          _this.chart_not_created = false
          console.debug('creating chart')
          // _this.smoothie = new SmoothieChart({millisPerPixel:11,labels:{fillStyle:'#000000', precision:0},grid:{fillStyle:'#dfd7d7',strokeStyle:'#e3abab'},maxValue:90,minValue:40})
          _this.smoothie = new smoothie.SmoothieChart({millisPerPixel:11,grid:{fillStyle:'#dfd7d7',verticalSections:2},labels:{fillStyle:'#000000',fontSize:15},horizontalLines:[{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}],maxValue:90,minValue:40})
          var canvas = document.getElementById("loud_canvas")
          _this.chart_data = new smoothie.TimeSeries();
          _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
          // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
          _this.smoothie.streamTo(canvas, 500);

        }
      },
      createChartSpect()
      {
        const _this = this
        
        if(_this.chart_not_created_spect)
        {
          _this.chart_not_created_spect = false
          console.debug('creating spec chart')


          _this.smoothie_spect = new smoothie.SmoothieChart({millisPerPixel:11,labels:{fillStyle:'#000000',  precision:0},grid:{fillStyle:'rgba(40,40,154,0.60)',strokeStyle:'#ffffff', verticalSections:4},maxValue:500,minValue:50})
          var canvas = document.getElementById("spect_canvas")
          _this.chart_data_spect = new smoothie.TimeSeries();

          _this.smoothie_spect.addTimeSeries(this.chart_data_spect, {lineWidth:3.3,strokeStyle:'#00ff00'});
          _this.smoothie_spect.streamTo(canvas, 500);

        }

      },
      addDataChart(in_data)
      {
        const _this = this
        if(_this.smoothie!=undefined && _this.smoothie!= null)
        {
          _this.chart_data.append(new Date().getTime(),in_data)
        }

      },
      addDataChartSpect(in_data)
      {
        const _this = this
        if(_this.smoothie_spect!=undefined && _this.smoothie_spect!= null)
        {
          _this.chart_data_spect.append(new Date().getTime(),in_data)
        }
      },
      pause()
      {
          let _this = this;
          _this.play_status = false;
          _this.play_pause_text = 'Record'

          clearInterval(window.refreshIntervalId)

      },
      drawLoop(sound_l) {
        // clear the background

        var _this = this;


        var WIDTH = 400;//document.getElementById('meter').clientWidth;
        var HEIGHT = 30;//document.getElementById('meter').clientHeight;;

        var green_threshold = 50;

        // console.debug("Width = " + WIDTH);
        
        // console.debug("HEIGHT = " + HEIGHT);

        _this.sound_l = sound_l;



        if(_this.canvasContext != undefined && _this.canvasContext !=null)
        {
          _this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
        
          if (sound_l > green_threshold) {
            _this.canvasContext.fillStyle = "green";

            // if(_this.silence_flag)
            // {
            //   _this.Repeat_Counter = _this.Repeat_Counter + 1;

            //   _this.silence_flag = false;

            //   if(_this.Repeat_Counter>=1)
            //   {
            //     _this.Repeat_Counter =0;
            //     _this.getNextExercise();
            //   }
            // }

          }
          else {

            // _this.silence_flag = true;

            _this.canvasContext.fillStyle = "red";
          }
          _this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
                  // _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);


        }

        // draw a bar based on the current volume


      },
      closeDialog() {
        this.$emit('closeDialog')
      },
      playAudioInstructions(url)
      {
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="50px" width="400px" controls autoplay> </video>';
        var player =document.getElementById('movie');
        player.load();

      },
    }
}
</script>

<style>
 
</style>