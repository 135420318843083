<template>
  <div>
    <div class="row mb-4 mt-4 justify-content-center">
      <div class="col-6">
        <img :src="imgChoice" style="width:150px;" v-bind:style="{'transform':'rotate('+randDirect+'deg)'}">
      </div>
    </div>
    <div class="row justify-content-center">
      Time: {{ countDown }}s
    </div>
    <hr/>
    <div class="row justify-content-center text-center">
      <span class="read-text"> Click on the direction that is <b style="color:#f56c6c">opposite</b> to what is shown.</span>
    </div>
    <div class="row mt-4">
      <div class="row justify-content-center">
        <div class="col-3" style="padding-right:0px">
          <button v-if="!clicked[0]" @click="gotoClick(0)" class="btn btn-light btn-lg float-end"><img :src="imgChoice" style="width:80px;"></button>
          <button v-if="clicked[0]" v-bind:class="[randDirect === 180 ? 'btn-success':'btn-danger', 'btn btn-lg float-end']"><img :src="imgChoice" style="width:80px;"></button>
        </div>
        <div class="col-3">
          <button v-if="!clicked[1]" @click="gotoClick(1)" class="btn btn-light btn-lg float-start"><img :src="imgChoice" style="width:80px;transform: rotate(180deg)"></button>
          <button v-if="clicked[1]" v-bind:class="[randDirect === 0 ? 'btn-success':'btn-danger', 'btn btn-lg float-start']"><img :src="imgChoice" style="width:80px; transform: rotate(180deg)"></button>
        </div>
      </div>
      <div class="row mt-2 justify-content-center">
        <div class="col-3" style="padding-right:0px">
          <button v-if="!clicked[2]" @click="gotoClick(2)" class="btn btn-light btn-lg float-end"><img :src="imgChoice" style="width:80px;transform: rotate(90deg)"></button>
          <button v-if="clicked[2]" v-bind:class="[randDirect === 270 ? 'btn-success':'btn-danger', 'btn btn-lg float-end']"><img :src="imgChoice" style="width:80px; transform: rotate(90deg)"></button>
        </div>
        <div class="col-3">
          <button v-if="!clicked[3]" @click="gotoClick(3)" class="btn btn-light btn-lg float-start"><img :src="imgChoice" style="width:80px; transform: rotate(270deg)"></button>
          <button v-if="clicked[3]" v-bind:class="[randDirect === 90 ? 'btn-success':'btn-danger', 'btn btn-lg float-start']"><img :src="imgChoice" style="width:80px; transform: rotate(270deg)"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import homeplanImg from '@/assets/images/homeplan.png'
import settingImg from '@/assets/images/setting.png'
import patientImg from '@/assets/images/patient.png'
import taskImg from '@/assets/images/task.png'
import addImg from '@/assets/images/add.png'
import warnImg from '@/assets/images/warn.png'
import homeImg from '@/assets/images/home.png'
import backImg from '@/assets/images/back.png'
import targetIcon from '@/assets/images/target.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import rightArrow from '@/assets/images/181-right-arrow.png'
import rightFinger from '@/assets/images/right_finger.png'
import handsup from '@/assets/images/065-hands-up.png'
import compass from '@/assets/images/compass-2.png'
import libImg from '@/assets/images/prePlans.png'

import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity, getTodayString} from '@/media_input/utils'
import { BV_Points } from '@/utils/bv_points'
import { useTTS } from '@/stores/tts'

export default {
  components: { },
  data() {
    return {
      homeplanImg: homeplanImg,
      settingImg: settingImg,
      patientImg: patientImg,
      taskImg: taskImg,
      rightArrow: rightArrow,
      addImg: addImg,
      warnImg: warnImg,
      homeImg: homeImg,
      backImg: backImg,
      targetIcon: targetIcon,
      doneIcon: doneIcon,
      rightFinger: rightFinger,
      handsup:handsup,
      compass:compass,
      countDown : 30,
      imgChoice:null,
      randDirect:0,
      randDirect_arr:[0,90,180,270],
      option_arr:[0,180,90,270],
      clicked:[false,false,false,false],
      correct_counter:0,
      incorrect_counter:0,
      libImg:libImg,
      bv_points: new BV_Points(),
      bv_tts: useTTS().tts_engine,

    }
  },
  created() 
  {
    const _this = this

    _this.userCategory = localStorage.getItem("userCategory")
    logActivity( 'accessed', 'reverse_direction')

    _this.startGame()
  },
  beforeUnmount()
  {

    clearInterval(window.countDownTimer)
  },
  methods: 
  {
    startGame() {
      this.today = getTodayString()
      this.countDownTimer();
      this.questionCreation();
    },
    gotoClick(num)
    {
      this.clicked = [false,false,false,false];
      this.clicked[num] = true;
      if(Math.abs(this.option_arr[num]-this.randDirect) == 180)
      {
        this.correct_counter = this.correct_counter + 1;
      } else {
        this.incorrect_counter = this.incorrect_counter+1
      }
      this.countDownDelay(1,500);
    },
    countDownTimer() {
      if(this.countDown > 0) {
        window.countDownTimer = setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.bv_tts.say('Good job. You managed to click ' + this.correct_counter + ' correct answers.' )
        window.countDownTimer = setTimeout(() => {
          this.endExercise()
        }, 3500)
      }
    },
    countDownDelay(time,dur) {
      if(time > 0) {
        setTimeout(() => {
            time -= 1
            this.countDownDelay()
        }, dur)
      } else {
        this.clicked = [false,false,false,false];
        this.questionCreation();
      }
    },
    questionCreation()
    {
      let imgSelect = Math.floor(Math.random() * 4);
      if(imgSelect == 0)
      {
        this.imgChoice = rightArrow;
      }
      else if(imgSelect == 1)
      {
        this.imgChoice = rightFinger;
      }
      else if(imgSelect == 2)
      {
        this.imgChoice = handsup;
      }
      else if(imgSelect == 3)
      {
        this.imgChoice = compass;
      }
      let rand = Math.floor(Math.random() * 4);
      this.randDirect = this.randDirect_arr[rand];
    },
    endExercise()
    {
      const _this = this

      let start_object = {
        assessment_type: "USER_TRAINING", 
        module_name: 'reverseDirection', 
        current_exercise_index: 0,
      }

      let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}

      let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}
      
      let results = {all_results:all_results, plot_results:plot_results}

      let resultsTablePre = {exercise_type: 'Brain Games', exercise: 'Reverse Direction Game', results: results}

      _this.saveResults(resultsTablePre)

      let reverse_direction_data = {start_object: start_object, resultsTablePre:resultsTablePre, results_type: 'end'}
      this.$emit('goToResults', reverse_direction_data)
    },
    saveResults(reverseDirectionData)
    {
      const _this = this

      let exForm = {}

      exForm.today = _this.today
      exForm.module_name = 'Reverse Direction'
      exForm.assessment_type = 'USER_TRAINING'

      exForm.curr_exercise_path = exForm.module_name + '/' 

      save_pre_computed_results(exForm,_this.$firebase,reverseDirectionData)

      _this.bv_points.add(10)
    },
  }  
}
</script>
<style scoped>


.button{
  font-size: 20px;
}

.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}

.text_item {
/* margin-bottom: 18px; */
font-size: 30px;
/* vertical-align: middle; */
/* padding: 40px 200px; */
/* height:40%; */
}

.custom-text{
  font-size: 20px;
    /* color:#FFFFFF; */
}


.menu__item {
  display: block;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 24px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}



.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

.button::-moz-focus-inner{
  border: 0;
  padding: 0;
}


</style>


<!-- Results Description:

"Reverse Direction":{
  "_d5c9aabc96e81": { 
    "audio_address": "<empty>",
    "exercise": "Reverse Direction Game",
      "exercise_type": "Brain Games",
      "results": {
        "all_results": {
          "Correct": "<<int>",
          "Incorrect": "<int>"
        },
        "plot_results": {
          "Correct": "<int>",
          "Incorrect": "<int>"
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING/SLP_TRAINING",
    "plan_name": "Reverse Direction"
  }
}
-->