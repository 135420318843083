<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="card-body">
          <span style="fontSize:26px" class="custom-text">{{instruct}}</span>

          <div v-if="!isToShow">
            <div class="card">
              <div class="card-body"> 
                <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="bv_tts.cancel();bv_tts.say(descript)">
                <p class="read-text" style="margin-top:10px" @click="bv_tts.cancel();bv_tts.say(descript)">{{descript}}</p>
              </div>
            </div>
          </div>
        
          <div v-if="isToShow" class="mt-2">
            <div class="card">
              <div class="card-body"> 
                <div class="row justify-content-center">
                  <img :src="audioImg" style="width:60px; cursor:pointer;" @click="bv_tts.cancel();bv_tts.say_sentences(qs_answers)">
                  <p class="read-text" style="margin-top:10px" @click="bv_tts.cancel();bv_tts.say_sentences(qs_answers)">{{descript_qs}}</p>
                </div>
                <div class="row justify-content-end">
                  <div class="col-8">
                    <div class="input-group justify-content-start">
                      <div class="form-check">
                        <label class="form-check-label fs-4" for="0" v-bind:style="radio_choices!==choiceA ? {backgroundColor:'#FFFFFF'} : choiceA === answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}">{{choiceA}}</label>
                        <input class="form-check-input" type="radio" v-model="radio_choices" id="0" :value="choiceA" @change="chooseAnswer(radio_choices)" >
                      </div>
                    </div>
                    <div class="input-group justify-content-start">
                      <div class="form-check">
                        <label class="form-check-label fs-4" for="1" v-bind:style="radio_choices!==choiceB ? {backgroundColor:'#FFFFFF'} : choiceB === answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}">{{choiceB}}</label>
                        <input class="form-check-input" type="radio" v-model="radio_choices" id="1" :value="choiceB" @change="chooseAnswer(radio_choices)" >
                      </div>
                    </div>
                    <div class="input-group justify-content-start">
                      <div class="form-check">
                        <label class="form-check-label fs-4" for="2" v-bind:style="radio_choices!==choiceC ? {backgroundColor:'#FFFFFF'} : choiceC === answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}">{{choiceC}}</label>
                        <input class="form-check-input" type="radio" v-model="radio_choices" id="2" :value="choiceC" @change="chooseAnswer(radio_choices)" >
                      </div>
                    </div>
                    <div class="input-group justify-content-start">
                      <div class="form-check">
                        <label class="form-check-label fs-4" for="3" v-bind:style="radio_choices!==choiceD ? {backgroundColor:'#FFFFFF'} : choiceD === answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}">{{choiceD}}</label>
                        <input class="form-check-input" type="radio" v-model="radio_choices" id="3" :value="choiceD" @change="chooseAnswer(radio_choices)" >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          
          :has_next="current_correct_counter!=0 || current_incorrect_counter!=0"
          @next="nextExercise()"

          :has_skip="current_correct_counter==0 && current_incorrect_counter==0"
          @skip="nextExercise()"

          :has_show_results="has_results"
          @show_results="goToResults()"
          >
          <template v-slot:top>
            <button v-if="current_correct_counter == 0 || current_incorrect_counter == 0"  class="btn btn-primary btn-lg w-100  mt-2" @click="showOptions()"> {{btn_text}} 
              <i v-bind:class="[btn_text==='Show question' ? 'bi bi-eye' : 'bi bi-arrow-clockwise']"></i>
            </button>     
            <!-- next was here -->
            <!-- skip was here -->
            <!-- show result was here -->
          </template>
        </Sidebar>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import homeplanImg from '@/assets/images/homeplan.png'
import settingImg from '@/assets/images/setting.png'
import patientImg from '@/assets/images/patient.png'
import taskImg from '@/assets/images/task.png'
import addImg from '@/assets/images/add.png'
import warnImg from '@/assets/images/warn.png'
import audioImg from '@/assets/images/audio_logo.png'
import backImg from '@/assets/images/back.png'
import targetIcon from '@/assets/images/target.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import rightArrow from '@/assets/images/bxs-right-arrow-white.png'
import libImg from '@/assets/images/prePlans.png'
import {startConfetti} from '@/common_js/confetti'

import { getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'
import { BV_Points } from '@/utils/bv_points'


export default {
  name: "Story_memory",
  props: {
    exForm: {
      type: Object,
    }
  },
  components: {Layout, Sidebar},
  data() {
    return {
      
      listen_count:0,
      has_results: false,
      homeplanImg: homeplanImg,
      settingImg: settingImg,
      patientImg: patientImg,
      taskImg: taskImg,
      rightArrow: rightArrow,
      addImg: addImg,
      warnImg: warnImg,
      audioImg: audioImg,
      backImg: backImg,
      targetIcon: targetIcon,
      doneIcon: doneIcon,
      descript:'test',
      descript_qs:'',
      subject:['jack','kate','Mary','additional'],
      occupation:['Student','gardener','chef','additional'],
      interests:[],
      topics:[],
      num_subject:3,
      show_results: false,
      curr_ex: 0,
      results_table: {back:[],pre:[]},
      question:'test',
      answer:'',
      radio_choices:'',
      current_correct_counter:0,
      current_incorrect_counter:0,
      choiceA:'testA',
      choiceB:'testA',
      choiceC:'testA',
      choiceD:'testA',
      choice_num: 4,
      btn_text: 'Show question',
      isToShow: false,
      correct_counter:0,
      incorrect_counter:0,
      instruct:'',
      descript_qs_answer:'',
      qs_answers: [],
      libImg:libImg,
      start_object: {
        assessment_type: "USER_TRAINING", 
        module_name: 'Story Description',
        current_exercise_index: 0
      },
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points()
    }
  },
  created() 
  {
    const _this = this

    _this.userCategory = localStorage.getItem("userCategory");

    _this.current_exercise_index = _this.exForm.current_exercise_index + 1

    _this.total_exercises = _this.exForm.total_all_type_exercises

    
    this.instruct = "Try to remember the story below. Press Show question to test what you remembered.";
    let selection = Math.floor(Math.random() * 6);
    if(selection<2)
    {
      this.setup(1);
    }
    else
    {
      this.setup(2);
    }

    logActivity( 'accessed', 'memory_story')

    _this.has_results = _this.exForm.has_results


    //_this.start_object = _this.$route.params.start_object

    if(_this.start_object!=undefined && _this.start_object!=null)
    {
      if(_this.start_object.results_table!=undefined && _this.start_object.results_table!=null)
      {
        _this.results_table = _this.start_object.results_table
      }
    }

  },
  beforeUnmount()
  {
    this.bv_tts.cancel()
  },
  methods: 
  {
    goToResults()
    {
      const _this = this

      _this.saveResults()

      let curr_ex_done = false

      if(this.current_correct_counter!=0 || this.current_incorrect_counter!=0)
      {
        curr_ex_done = true
      }

      var goToResultsData = {curr_ex_done:curr_ex_done}

      _this.$emit('goToResults', goToResultsData);

    },
    saveResults()
    {
      const _this = this

      if(this.current_correct_counter!=0 || this.current_incorrect_counter!=0)
      {
        let plot_results = {Correct:_this.current_correct_counter,Incorrect:_this.current_incorrect_counter}
        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:1,ListenCount:_this.listen_count}
        let results = {all_results:all_results, plot_results:plot_results}

        var storyData = {resultsTablePre: {exercise_type: 'Memory Games', exercise: 'Story Memory', results: results, audio_address:''}}


        _this.$emit('logExerciseResults', storyData);



        save_pre_computed_results(this.exForm,_this.$firebase,storyData)

        _this.bv_points.add(10)
      }
    },
    chooseAnswer(radio_choices)
    {

      const _this = this

      console.debug('radio_choices = ' + radio_choices)

      if(_this.answer==radio_choices)
      {

        if(_this.correct_counter==0 && _this.incorrect_counter==0)
        {
          _this.correct_counter = _this.correct_counter + 1
          _this.current_correct_counter  =  _this.current_correct_counter  +1

        }
        // _this.current_correct_counter  = 1
        // _this.current_incorrect_counter  = 0
        this.bv_tts.cancel();
        _this.bv_tts.say(radio_choices + ' is correct! Click Next to do another exercise')
        startConfetti(this)

      }
      else
      {
        
        if(_this.correct_counter==0 && _this.incorrect_counter==0)
        {
          _this.incorrect_counter = _this.incorrect_counter + 1
          _this.current_incorrect_counter  = _this.current_incorrect_counter + 1

        }
        // _this.incorrect_counter = _this.incorrect_counter + 1
        // _this.current_correct_counter  = 0
        // _this.current_incorrect_counter  = 1
        this.bv_tts.cancel();

        _this.bv_tts.say(radio_choices + ' is not the right one. Try again!')

      }

    },
    setup(scenario)
    {
      var _this = this;
      var do_once = true;

        _this.curr_ex = _this.curr_ex+1

        _this.show_results = _this.curr_ex>3
        
        let targetDir = 'Exercises/General/Aphasia/Story_understanding/'


      getData(targetDir, function (err, result) {

        if(do_once)
        {
          do_once = false

          let total_name = result.child('who').numChildren();
          let rand_name_id = Math.floor(Math.random() * total_name)+1;
          let rand_name = result.child('who').child(rand_name_id).val();
          _this.subject = [];
          while(_this.subject.length<_this.choice_num)
          {
            if(!_this.subject.includes(rand_name))
            {
              _this.subject.push(rand_name);
            }
            else
            {
              rand_name_id = Math.floor(Math.random() * total_name)+1;
              rand_name = result.child('who').child(rand_name_id).val();
            }
          }

          if(scenario == 1)
          {
            let total_occupation = result.child('occupation').numChildren();
            let rand_occupation_id = Math.floor(Math.random() * total_occupation)+1;
            let rand_occupation = result.child('occupation').child(rand_occupation_id).child('Type').val();
            let rand_topic_id = Math.floor(Math.random() * result.child('occupation').child(rand_occupation_id).child('Topic').numChildren())+1;
            _this.question = result.child('occupation').child(rand_occupation_id).child('Topic').child(rand_topic_id).val();
            _this.occupation = [];
            while(_this.occupation.length<_this.choice_num)
            {
              if(!_this.occupation.includes(rand_occupation))
              {
                  _this.occupation.push(rand_occupation);
              }
              else
              {
                  rand_occupation_id = Math.floor(Math.random() * total_occupation)+1;
                  rand_occupation = result.child('occupation').child(rand_occupation_id).child('Type').val();
              }
            }
          }
          else if(scenario == 2)
          {
            let total_interest = result.child('interests').numChildren();
            let rand_interest_id = Math.floor(Math.random() * total_interest)+1;
            let rand_interest = result.child('interests').child(rand_interest_id).child('Type').val();
            _this.interests = [];
            // _this.interests.push(rand_interest);
            let rand_topic_id = Math.floor(Math.random() * result.child('interests').child(rand_interest_id).child('Topic').numChildren())+1;
            _this.topics = [];
            // _this.topics.push(result.child('interests').child(rand_interest_id).child('Topic').child(rand_topic_id).val());
            while(_this.topics.length<_this.choice_num)
            {
              if(!_this.interests.includes(rand_interest))
              {
                _this.interests.push(rand_interest);
                rand_topic_id = Math.floor(Math.random() * result.child('interests').child(rand_interest_id).child('Topic').numChildren())+1;
                _this.topics.push(result.child('interests').child(rand_interest_id).child('Topic').child(rand_topic_id).val());
              }
              else
              {
                rand_interest_id = Math.floor(Math.random() * total_interest)+1;
                rand_interest = result.child('interests').child(rand_interest_id).child('Type').val();
              }
              
            }
          }
          

          _this.descriptionTemp(scenario);

        }

      });    
    },
    showOptions()
    {
        if(this.btn_text == 'Show question')
        {
            this.bv_tts.cancel()  

            this.isToShow = true;
            this.btn_text = 'Repeat story';
            this.instruct = "Answer the question below. If needed, press Repeat story to read the story again.";

            
            setTimeout(() => {
              this.bv_tts.cancel()  

              this.bv_tts.say_sentences(this.qs_answers)        
            }, 500)

        }
        else
        {
          this.bv_tts.cancel()  
            this.isToShow = false;
            this.btn_text = 'Show question';
            this.instruct = "Try to remember the story below. Press Show question to test what you remembered.";
        }
        
    },
    nextExercise()
    {
      const _this = this

      _this.saveResults()

      _this.$emit('nextExercise');
      // const _this = this
      // this.isToShow = false;
      // this.btn_text = 'Show question';
      // this.radio_choices = '';
      // let selection = Math.floor(Math.random() * 6);
      // if(selection<2)
      // {
      //   this.setup(1);
      // }
      // else
      // {
      //   this.setup(2);
      // }

      // this.saveResults()
      // _this.current_correct_counter = 0
      // _this.current_incorrect_counter = 0

    },
    descriptionTemp(scenario)
    {
      const _this = this
      if(scenario == 1)
      {
          this.descript = '';
          this.descript = 'You have ' + this.num_subject +' friends. ';
          for(let i=0; i<this.num_subject; i++)
          {
              this.descript = this.descript + this.subject[i] + ' is a '+ this.occupation[i] + '. ';
          }
          this.descript_qs = 'You now are thinking about ' + this.question + ' and are looking for advice. Which friend should you go to?';
          
          let rand_array = [];
          while(rand_array.length<this.choice_num)
          {
              let rand_num = Math.floor(Math.random() * this.choice_num);
              if(!rand_array.includes(rand_num))
              {
                  rand_array.push(rand_num);
              }
          }
          
          this.choiceA = this.subject[rand_array[0]];
          this.choiceB = this.subject[rand_array[1]];
          this.choiceC = this.subject[rand_array[2]];
          this.choiceD = this.subject[rand_array[3]];
          this.answer = this.subject[0];
          this.descript_qs_answer = this.descript_qs;

          // this.qs_answers = [this.descript_qs, 'A. ', this.choiceA, 'B. ', this.choiceB, 'C. ', this.choiceC , 'D. ',this.choiceD]
          this.qs_answers = [this.descript_qs, this.choiceA, this.choiceB, this.choiceC, this.choiceD]

      }
      else if(scenario == 2)
      {
        this.descript = '';
        let rand_question = Math.floor(Math.random() * 6);
        let rand_array = [];
        let rand_pos = Math.floor(Math.random() * 2);
        if(rand_pos == 0)
        {
          this.descript = 'You are at a dinner party. The person who sits on your left is '+this.subject[0]+' who '+this.interests[0]+'. The person sits on your right is '+this.subject[1]+' who '+this.interests[1]+'.';
        }
        else
        {
          this.descript = 'You are at a dinner party. The person who sits on your right is '+this.subject[1]+' who '+this.interests[1]+'. The person sits on your left is '+this.subject[0]+' who '+this.interests[0]+'.';
        }

          while(rand_array.length<this.choice_num)
          {
              let rand_num = Math.floor(Math.random() * this.choice_num);
              if(!rand_array.includes(rand_num))
              {
                  rand_array.push(rand_num);
              }
          }
        if(rand_question == 0)
        {
          this.descript_qs = 'Who is the person sits on your left?'            
          this.choiceA = this.subject[rand_array[0]];
          this.choiceB = this.subject[rand_array[1]];
          this.choiceC = this.subject[rand_array[2]];
          this.choiceD = this.subject[rand_array[3]];
          this.answer = this.subject[0];
        }
        else if(rand_question == 1)
        {
          this.descript_qs = 'Who is the person sits on your right?'            
          this.choiceA = this.subject[rand_array[0]];
          this.choiceB = this.subject[rand_array[1]];
          this.choiceC = this.subject[rand_array[2]];
          this.choiceD = this.subject[rand_array[3]];
          this.answer = this.subject[1];
        }
        else if((rand_question == 2)||(rand_question == 3))
        {
          this.descript_qs = 'Which of the following topics may be good to talk to '+this.subject[0]+' about?'            
          this.choiceA = this.topics[rand_array[0]];
          this.choiceB = this.topics[rand_array[1]];
          this.choiceC = this.topics[rand_array[2]];
          this.choiceD = this.topics[rand_array[3]];
          this.answer = this.topics[0];
        }
        else if((rand_question == 4)||(rand_question == 5))
        {
          this.descript_qs = 'Which of the following topics may be good to talk to '+this.subject[1]+' about?'            
          this.choiceA = this.topics[rand_array[0]];
          this.choiceB = this.topics[rand_array[1]];
          this.choiceC = this.topics[rand_array[2]];
          this.choiceD = this.topics[rand_array[3]];
          this.answer = this.topics[1];
        }
        // this.descript_qs_answer = this.descript_qs + '\nA.\n '+this.choiceA+'\nB.\n '+this.choiceB + '\nC\n'+this.choiceC + '\nD\n'+this.choiceD;
        this.descript_qs_answer = this.descript_qs;
        // this.qs_answers = [this.descript_qs, 'A. ', this.choiceA, 'B. ', this.choiceB, 'C. ', this.choiceC , 'D. ',this.choiceD]

        this.qs_answers = [this.descript_qs, this.choiceA, this.choiceB, this.choiceC, this.choiceD]

      }
      setTimeout(() => {
        this.bv_tts.cancel()
        this.bv_tts.say(this.descript)
      }, 500)

    },
  },
}
</script>
<style scoped>


li:hover {
  background-color: #e0e0e2;
  color: white;
}



</style>


<!-- Results Description:

  {
  "Story Memory": {
    "audio_address": "<empty>",
    "resultsTablePre": {
      "audio_address": "",
      "exercise": "Story Memory",
      "exercise_type": "Memory Games",
      "results": {
        "all_results": {
          "Correct": <int>,
          "Incorrect": <int>,
          "ListenCount": <int>,
          "Total": <int>>
        },
        "plot_results": {
          "Correct": <int>,
          "Incorrect": <int>
        }
      }
    },
    "results_type": "pre_computed"
  },
  "assessment_type": "USER_TRAINING/SLP_TRAINING",
  "plan_name": "Story Memory"
  
}


 -->