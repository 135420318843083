<template>
  <div class="row">

    <div class="row mb-2" v-if="active_tab !== 'create_module'">
      <button class="btn btn-outline-primary" @click="active_tab='create_module'">
        Create a module
      </button>
    </div>

    <My_modules 
      v-if="active_tab === 'my_modules' && module_folder_locations.length > 0"

      :module_folder_locations="module_folder_locations"
      :default_folder_location="default_folder_location"

      @add_to_assignment_list="addToAssignmentList"
      @start_clinic_module="startClinicModule"
      @edit_module="edit_module"
      @updated="updated"
      >
    </My_modules>
    
    <Create_module v-if="active_tab === 'create_module'"

      :edit_meta_module_pointer="edit_meta_module_pointer"
      :edit_mode="edit_meta_module_pointer != null"
      :module_folder_locations = "module_folder_locations"

      @add_to_assignment_list="addToAssignmentList"
      @show_my_modules="active_tab = 'my_modules'"
      @module_saved="module_saved"
      @cancel="cancel"
      >
    </Create_module>

  </div>
</template>

<script>
import { ref, triggerRef } from 'vue'
import {getDataDirectoryCallback} from '@/firebase/index'
import My_modules from './my_modules/my_modules.vue'
import Create_module from './create_module/create_module.vue'

export default {
  name: 'custom_training',
  components: {
    My_modules, Create_module
  },
  emits: ['addToAssignmentList', 'startClinicModule'],
  setup(props, { emit }) {
    const active_tab = ref('my_modules')

    const default_folder_location = ref('General')

    const edit_meta_module_pointer = ref(null)

    const uid = localStorage.getItem('uid')

    const module_folder_locations = ref([])
    const user_modules_dir = 'Custom_Modules/' + uid + '/'

    const check_folder_location = () => {

      getDataDirectoryCallback(user_modules_dir, (err, dir_data) => {

        module_folder_locations.value = []
        dir_data.forEach( (modules_type_handle) => {
          module_folder_locations.value.push(modules_type_handle.key)
        })
        
        if (!module_folder_locations.value.includes(default_folder_location.value)) {
          if (module_folder_locations.value.length > 0) {
            default_folder_location.value = module_folder_locations.value[0]
          }
        }

        triggerRef(module_folder_locations);
        if (module_folder_locations.value.length == 0) {
          active_tab.value = "create_module"
        } else {
          active_tab.value = "my_modules"
        }
      })
    }

    const edit_module = (meta_module) => {
      edit_meta_module_pointer.value = meta_module.value
      active_tab.value = 'create_module'
    }

    const addToAssignmentList = (module_) => {
      emit('addToAssignmentList', module_ )
    }

    const startClinicModule = (module_name, module_address) => {
      emit('startClinicModule', module_name, module_address )
    }

    const updated = () => {
      check_folder_location()
    }

    const module_saved = (module_location) => {

      default_folder_location.value = module_location      
      check_folder_location()
      active_tab.value = 'my_modules'
    }

    const cancel = () => {
      edit_meta_module_pointer.value = null
      active_tab.value = 'my_modules'
    }

    check_folder_location()

    return {
      active_tab,

      addToAssignmentList,
      startClinicModule,
      edit_module,
      cancel,
      updated,
      
      module_folder_locations,
      default_folder_location,
      edit_meta_module_pointer,
      module_saved

    }

  }
}
</script>