<template>
  <div class="row mt-4">
    <div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    <div class="d-flex flex-column justify-content-start mt-4 vh-100">
      <div class="row justify-content-center">
        <div class="col-md-6 text-end">
          <p>Choose your browser to turn on your microphone</p>
        </div>
        <div class="col-md-3">
          <select class="form-select text-center" v-model="browser_version">
            <option selected value="windows_chrome">Windows: Chrome</option>
            <option value="windows_edge">Windows: Edge</option>
            <option value="mac_safari">MacOS: Safari</option>
          </select>
        </div>
      </div>

      <div class="row mt-4">
        <p class="fs-5 text-start"><b>Steps: </b></p>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="" v-for="step, index in steps[browser_version]" v-bind:key="step">
            <div class="d-flex flex-row justify-content-start mb-2">
              <div v-if="index == current_step" 
                    class="rounded-circle 
                    shadow 
                    bg-success 
                    text-white 
                    d-flex 
                    justify-content-center 
                    align-items-center" 
                    style="width: 50px; height: 50px; cursor: pointer;" 
                    @click="current_step=index">
                <p class="m-0 fs-5"><b>{{ index + 1 }}</b></p>
              </div>
              <div v-else 
                    class="rounded-circle 
                    shadow 
                    bg-secondary 
                    text-white 
                    d-flex 
                    justify-content-center 
                    align-items-center" 
                    style="width: 50px; height: 50px; cursor: pointer;" 
                    @click="current_step=index">
                <p class="m-0 fs-5"><b>{{ index + 1 }}</b></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <img :src="steps[browser_version][current_step]" />
        </div>
      </div>
      <div class="row" v-if="granted">
        <div class="row mt-2 justify-content-center" >
          <div class="col-8">
            <h4 class="text-success mb-4">Your microphone is now enabled.</h4>
            <button class="btn btn-primary btn-lg" @click="confirm()">Continue</button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
  
  <script>
  import { ref, watch, onMounted, onUpdated } from 'vue';

  import mac_safari_1 from '@/assets/images/mic_permissions_guide/macos_safari/1.svg'
  import mac_safari_2 from '@/assets/images/mic_permissions_guide/macos_safari/2.svg'
  import mac_safari_3 from '@/assets/images/mic_permissions_guide/macos_safari/3.svg'
  import mac_safari_4 from '@/assets/images/mic_permissions_guide/macos_safari/4.svg'

  import windows_chrome_1 from '@/assets/images/mic_permissions_guide/windows_chrome/1.svg'
  import windows_chrome_2 from '@/assets/images/mic_permissions_guide/windows_chrome/2.svg'

  import windows_edge_1 from '@/assets/images/mic_permissions_guide/windows_edge/1.svg'
  import windows_edge_2 from '@/assets/images/mic_permissions_guide/windows_edge/2.svg'
  
  export default {
    name: 'guide',
    components: {  },
    emits: ['confirm'],
  
    setup(props, { emit }) {
      
      const supported_browser = ref(false)
      const granted = ref(false)
      const browser_version = ref('windows_chrome')
      const current_step = ref(0)

      const steps = {
        "windows_chrome": [
          windows_chrome_1,
          windows_chrome_2
        ],
        "mac_safari": [
          mac_safari_1,
          mac_safari_2,
          mac_safari_3,
          mac_safari_4,
        ],
        "windows_edge": [
          windows_edge_1,
          windows_edge_2
        ]
      }

      const unsupported_browser_prompt = () => {
        
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          console.debug('You let me use your mic!');

          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop());
          stream = null;

          granted.value = true;
          localStorage.setItem('mic_permission_allowed', true)
        })
        .catch((err) => {
          console.debug('No mic for you!');
          granted.value = false;
          localStorage.setItem('mic_permission_allowed', false)
        });
          
      }

      const getBrowserPermission = async () => {
        try {
          const permissionStatus = await navigator.permissions.query({ name: "microphone" });

          supported_browser.value = true
          console.debug("microphone permission state" + " " + permissionStatus.state); // granted, denied, prompt

          if (permissionStatus.state === "granted") {
            console.debug('You let me use your mic!');
            granted.value = true;
            localStorage.setItem('mic_permission_allowed', true)
          } else {
            console.debug('Not granted so run prompt!');
            unsupported_browser_prompt();
          }

          permissionStatus.onchange = () => {
            console.debug("Permission changed to " + permissionStatus.state);

            if (permissionStatus.state === "granted") {
              console.debug('You let me use your mic! (onchange)');
              granted.value = true;
            } else {
              console.debug('Not granted so run prompt! (onchange)');
              unsupported_browser_prompt();
            }
          };
        } catch (error) {
          supported_browser.value = false
          // probably firefox or unsupported browser in which get getUserMedia
          //console.debug('Error querying permissions:', error);

          if (localStorage.getItem('mic_permission_allowed')) {

            //alert(localStorage.getItem('mic_permission_allowed'))
            if (localStorage.getItem('mic_permission_allowed') === "true") {
              //do nothing
            } else {
              unsupported_browser_prompt()
            }
          } else {
            unsupported_browser_prompt()
          }
          //alert("supported browser?: " + supported_browser.value)
         }
      };



      watch(()=>browser_version.value, (new_browser_version) => {
        current_step.value = 0
      })

      
      const confirm = () => {
        emit('confirm');
      };

      onMounted(async () => {
        await getBrowserPermission();

      });
  
      onUpdated(async () => {
        await getBrowserPermission();
      })
  
      return {
        granted,
        confirm,
        steps,
        browser_version,
        current_step
      };
    },
  };
  </script>
  
  <style>
  </style>