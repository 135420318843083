<template>
  <div>
    <Layout>
      <template v-slot:content>
        <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->
          <div class="row">
            <p class="fs-3" :style="answer_text_color">{{answer_text}}</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card card-body">
                <div class="row">
                  <p class="text-30">
                      Press Start. {{instructions}}
                  </p>
 
                </div>
                <div class="row">
                  <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"  width="150" height="150" style="object-fit: cover"> 
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-body h-100">
                <p class="lead" style="font-size: x-large;">
                    Need help? 
                </p>
                <!-- <div v-if="half_word_url!=null || phoneme_single_target_word.length>1"> -->
                <!-- <button v-if="half_word_url!=null || phoneme_single_target_word.length>1" class="btn btn-secondary btn-lg mt-2" @click="listenBeginningSound()" >Hear the beginning of the word  <i class="bi bi-ear" style="margin-left: 3mm;"></i></button> -->
                <!-- </div> -->

                <button v-if="showOptions==false" class="btn btn-secondary btn-lg mt-4"  @click="showOptions=true;showedOptions=true" >Get choices <i class="bi bi-list-ol"  style="margin-left: 3mm;"></i></button>

                <div v-if="showHints">
                  <span>Hint: {{hints[0]}}
                    <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(hints[0])"><br>
                  </span>  
                </div>
                <div v-if="showOptions" >
                  <div class="card card-body mt-4" style="background-color: grey;">
                    <div class="row mb-4">
                        <span style="font-size: x-large;color: white;">Get choices: use one of the words answer the question</span>
                    </div>
                    <div class="row align-items-center">
                      <div class="col mt-3">
                      <button class="custom-button-BV" @click="bv_tts.say(answer_options[0])">{{answer_options[0]}} 
                          <br>
                          <img :src="audioImg" style="width:20px;cursor: pointer;"> 
                      </button>
                      </div>
                      <div class="col  mt-3">
                      <button class="custom-button-BV"  @click="bv_tts.say(answer_options[1])" >{{answer_options[1]}} 
                          <br>
                          <img :src="audioImg" style="width:20px;cursor: pointer;" >
                      </button>
                      </div>
                      <div class="col  mt-3">
                      <button class="custom-button-BV" @click="bv_tts.say(answer_options[2])" >{{answer_options[2]}} 
                          <br>
                          <img :src="audioImg" style="width:20px;cursor: pointer;" >
                      </button>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row justify-content-center" v-if="showOptions || userType=='slp'">
                    <button class="btn btn-bd-yellow btn-lg mt-4" style="width: 95%;" @click="showAnswer()" >Show answer <i class="bi bi-file-earmark-check" style="margin-left: 3mm;"></i></button>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
          @next="nextExercise()"

          :has_show_results="has_results"
          @show_results="goToResults()"   
          >
          <template v-slot:top>
            <button v-if="!started" class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()"><strong>{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></strong></button>
            <button v-if="started && !congatulationsOnce" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
            </button>
            <!-- <button v-if="started && congatulationsOnce" disabled class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></button> -->
            
            <!-- next was here -->
             
            
          </template>
        </Sidebar>
      </template>
    </Layout>

    <div class="modal fade" id="level_up_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Level up</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="m-3">
              <div class="row">
                <h4 >{{ level_up_message }}</h4>
              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import {get_phonetic_transcription,LevenshteinDistanceNoLower,LevenshteinDistance,LevenshteinDistanceWord_Phonemes} from '@/utils/text_distance_metrics'
import homeplanImg from '@/assets/images/homeplan.png'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import SpeechRec from '@/media_input/SpeechRecognition'
import audioImg from '@/assets/images/audio_logo.png'
import loadingSVG from '@/assets/images/loader.svg'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import constants from '@/utils/constants'
import {getData, getUserDataDir, updateDataDirectory} from '@/firebase/index'

import { Modal } from 'bootstrap'

import {logActivity,removePunctuationFromString} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import { useTTS } from '@/stores/tts'

import {BV_Points} from '@/utils/bv_points'

export default {
    name: 'give_directions',
    props: {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data() {
      return {
        instructions:'',
        sound_hints:false,
        recognition_setting: 0,
        client_demo: true,
        audioImg:audioImg,
        loadingSVG: loadingSVG,
        diffMarks:{
          0:{
            style:{color: '#000000'},
            label:'Auto'
          },
          1:{
            style:{color: '#000000'},
            label:'Manual'
          },
        },
        base_level_address:'/Levels/GiveDirections',
        level_address:'',
        total_exercises:1,
        level:1,
        level_up_message:'',
        level_up_counter:0,
        total_number_levels:4,
        level_up_target:3,
        level_properties:{
              1:{'level':1},
              2:{'level':2},
              3:{'level':3},
              4:{'level':4}
        },
        answer_text: '',
        answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
        imgSrc: loadingSVG,
        hints:['It is used to cook','It is made of metal'],
        showHints:false,
        answer_options:['Left','Straight','Right'],
        showOptions:false,
        showedOptions:false,
        showedAnswer:false,
        has_results:true,
        tested_words_phonetics:{},
        target_words_phonetics:{},
        possible_words:['right','left','straight','ahead','forward'],
        chosen_image: '',
        target_word: '',
        single_target_word: '',
        audio_player: null,
        phoneme_single_target_word: '',
        target_img_number: '',
        instructions_msg: '',
        homeplanImg: homeplanImg,
        crossImg: crossImg,
        doneIcon: doneIcon,
        inputAcc:'',
        play_pause_text: 'Start',
        current_exercise_index: 0,
        speech_rec: null,
        started: false,
        target_words: [],
        currentSentence: '',
        best_answer:'',
        results:{'correct':0,'incorrect':0,'total':0},
        uid: localStorage.getItem('uid'),
        userType: localStorage.getItem('userCategory'),
        region: localStorage.getItem('region'),
        resultsTable: [],
        half_word_url: null,
        retest_count: null,
        is_recording: false,
        congatulationsOnce: false,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
      }
    },
    created() {

      const _this = this

      logActivity( 'accessed', 'give_directions')


      // logActivity(_this.$firebase, 'accessed', 'give_directions')

      console.log('created')

      this.speech_rec = new SpeechRec(function(captions) {
        this.currentSentence = captions.original
      }.bind(this))


      this.setup();

    },
    mounted()
    {



      
    },
    beforeUnmount()
    {    
      this.bv_tts.cancel()
      this.speech_rec.stop()

      clearTimeout(window.countDownTimer)
      clearTimeout(window.countDownTimer2)
    },
    computed: {

    currentSentence_found: async function () {

      const _this = this
      

      if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
      {


        let curr_sentence = this.currentSentence.toString().toLowerCase()

        curr_sentence = removePunctuationFromString(curr_sentence)

        console.debug('curr_sentence = ' + curr_sentence)

        let found_something = false

        let split_sentence = curr_sentence.split(' ')

        let best_answer = null
        let minimum_distance = 100

        let convert_words = {'1st':'first','2ns':'second','3rd':'third','qing':'chin','ting':'chin','p':'pea','b':'bee','10':'ten','2':'two','3':'three','4':'four','6':'six','9':'nine'}

        for(let n in split_sentence)
        {
          let curr_word = split_sentence[n]

          if(convert_words[curr_word]!=undefined)
          {
            curr_word = convert_words[curr_word]
          }



          for(let i in this.possible_words)
          {
            let distance = 100

            

            distance = LevenshteinDistance(this.possible_words[i],curr_word)

            distance = distance/Math.max(this.possible_words[i].length,1)

            

            if(distance<minimum_distance && distance<0.30)
            {
              best_answer = this.possible_words[i]
              found_something = true
              minimum_distance = distance

            }

          
          }
          if(best_answer!=null)
          {

            if(_this.target_words.includes(best_answer))
            {
    
              this.found_correct_word(best_answer)

            }
            else
            {
              this.found_incorrect_word(best_answer)
            }
            
          }
          if(!found_something)
          {
            this.found_target_word = 'false'
          }
          else
          {
            return curr_sentence

          }
        }


      

        if(!found_something)
        {
          this.found_target_word = 'false'
        }
        else
        {
          return curr_sentence

        }

      }

      return ''

    },
    },
    
    methods: 
    {
      saveResults()
      {
        const _this = this

        console.debug("_this.results['correct'] = " + _this.results['correct'])

        if(this.results['correct']!=0 || _this.results['total']!=0)
        {
          let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'], Total:1, Show_Options:_this.showedOptions,Show_Answer:_this.showedAnswer, Sound_Hints:_this.sound_hints,Identified_Answer:_this.best_answer}

          let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}

          let results = {all_results:all_results, plot_results:plot_results}

          var word_naming_data = {resultsTablePre: {exercise_type: 'Give Directions', exercise: 'Give Directions', results: results, audio_address:'',level:_this.level}}


          save_pre_computed_results(_this.exForm, _this.$firebase, word_naming_data)

          _this.bv_points.add(10)

          _this.$emit('logExerciseResults', word_naming_data);
        }
      },
      nextExercise()
      {
        const _this = this;
        _this.saveResults()

        _this.$emit('nextExercise');
      },
      updateLevel(increment)
      {
        let has_levelled_up = false
        let level_down_target = 0-this.level_up_target
        this.level_up_counter = this.level_up_counter + increment

        // if(this.max_level)
        // {
        //   this.level = this.total_number_levels
        // }

        let new_level = this.level


        console.debug('this.level_up_counter = ' + this.level_up_counter)
        if(this.level_up_counter >= this.level_up_target && this.level<this.total_number_levels)
        {
          console.debug('here')
            this.level_up_counter = 0
            new_level = new_level + 1


            this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
            let level_up_pop = new Modal(document.getElementById('level_up_pop'));
            level_up_pop.show()


            has_levelled_up = true



          
        }
        else if(this.level_up_counter <= level_down_target)
        {
          this.level_up_counter = 0
          new_level = new_level -1
          if(new_level<1)
          {
            new_level = 1
          }
        }

        
        updateDataDirectory(this.level_address,{current_level:new_level,level_up_counter:this.level_up_counter})
        
        return has_levelled_up


        
      },
      goToResults()
      {
        const _this = this

        _this.saveResults()
        let goToResultsData = {curr_ex_done:false}
        _this.$emit('goToResults', goToResultsData);
      },
      listenBeginningSound()
      {
        const _this = this
        _this.sound_hints = true

        this.bv_tts.cancel()


        if(_this.half_word_url!=null)
        {
          _this.playSound(_this.half_word_url)
        }
        else
        {
          _this.listenPhoneme()
        }
      },
      listenPhoneme()
      {
        const _this = this
        let phoneme = _this.phoneme_single_target_word[1]

        let simple_representation_phoneme = ''
        for(let i in constants.phonemeDisplayDictionaryIPA)
        {
          if(constants.phonemeDisplayDictionaryIPA[i]==phoneme)
          {
            simple_representation_phoneme = i
            break
          }
        }
        if(simple_representation_phoneme!='')
        {
          let audio_file_location = '/BV/audio_resources/audio_hints/phonemes/'+simple_representation_phoneme+'.mp3'
          // let audio_file_location = 'audios/phoneme_pronunciation_new/'+phoneme+'.mp4'
          console.debug(audio_file_location)
          this.playAudio(audio_file_location)
        }
      },
      get_half_word_url(word)
      {
        let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'

        const _this = this;
        const storage = _this.$firebase.storage();

        storage.ref(audio_file_location).getDownloadURL()
        .then((url) => {
          _this.half_word_url = url
          console.debug('Found half word url!!')
        })
        .catch((error) => {
          console.debug('error ' + error )
        });
      },
      playAudio(audio_file_location)
      {
        const _this = this;
        const storage = _this.$firebase.storage();

        storage.ref(audio_file_location).getDownloadURL()
        .then((url) => {
          _this.playSound(url);
        })
        .catch((error) => {
          console.debug('error ' + error )
        });      
      },
      playSound(url) {
        const _this = this
        if(_this.audio_player==null)
        {
          _this.audio_player = new Audio(url);
          _this.audio_player.play();
        }
        else
        {
          if(!_this.audio_player.paused)
          {
            _this.audio_player.pause();
          }
          _this.audio_player = new Audio(url);
          _this.audio_player.play();
        }
      },
      setup()
      {

        console.debug('setup')
        let _this = this;

        _this.answer_text = '';

        _this.has_results = _this.exForm.has_results

        _this.current_exercise_index = _this.exForm.current_exercise_index + 1
        _this.total_exercises = _this.exForm.total_all_type_exercises


        let client_demo = _this.exForm.client_demo


        if(client_demo!=undefined && client_demo!=null)
        {
            _this.client_demo = client_demo
        }

        this.getDifficulty()

      },
      LoadLevel()
      {

        const _this = this


        let level_address = '/Exercises/Resources/GiveDirections/Levels/' + this.level

        console.debug('LoadLevel ' + level_address)



        getData(level_address , function (err, result) {

          let level_data = result.val()
          let n_exercises = level_data.length

          let random_exercise = Math.floor(Math.random() * n_exercises)

          console.debug('random_exercise = ' + random_exercise)

          let curr_exercise = level_data[random_exercise]


          let img_address = curr_exercise.imageAddress

          // img_address = '/BV/images/Count_in_images/animals1.png'



          let questions = curr_exercise['Questions']

          let question = questions[0]

          _this.target_word = question['Answer'].toLowerCase()

          _this.target_words = [_this.target_word]

          if(_this.target_word==='straight')
          {

            _this.target_words.push('forward')
            
            _this.target_words.push('ahead')
          }

          _this.instructions = question['Question']

          _this.bv_tts.say('Press start. ' + _this.instructions) 


          let storageRef = _this.$firebase.storage().ref();

          storageRef.child(img_address).getDownloadURL().then(function(url) {
              _this.imgSrc = url;
          }).catch(function(error) {

        });




        })

      },
      getDifficulty()
      {

          let _this = this

          let userType = localStorage.getItem('userCategory');


          let uid = localStorage.getItem('uid');

          this.level_address = '/Users/' + uid + this.base_level_address


          if(userType!=undefined || userType==='slp')
          {
              let client_number = localStorage.getItem('client_uid');

              if(client_number!='undefined' && client_number!=null && client_number!='')
              {

                  this.level_address = '/Users/'+ client_number + this.base_level_address

              }
          }


          

          let do_once = true

          getData(_this.level_address, function (err, result) {

              if(do_once)
              {
                do_once = false


                let current_level = undefined
                let lvl_up_counter =  undefined

                console.debug('here1')

                result = result.val()


                if(result!=undefined && result!=null)
                {


                  if('current_level' in result && 'level_up_counter' in result)
                  {
                    current_level = result['current_level']
                    lvl_up_counter = result['level_up_counter']
                  }
                  console.debug('here2')
 
                }

                console.debug('current_level = ' + current_level)

      
                



                if(current_level==undefined || lvl_up_counter==undefined)
                {
                  current_level = 1
                  lvl_up_counter = 0
                  updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter})
                }


                _this.level = current_level


                console.debug('current_level = ' + current_level)

                console.debug('lvl_up_counter = ' + lvl_up_counter)
              
                _this.level_up_counter = lvl_up_counter
                _this.LoadLevel()
              }

          })
      },
      async setup_target_words_phonetics()
      {
        this.target_words_phonetics = {}

        const promiseArray = []



        for(let k in this.target_words)
        {
          console.debug('this.target_words[k] = ' + this.target_words[k])
          promiseArray.push(get_phonetic_transcription(this.target_words[k]))
        }

        const all_results = await Promise.all(promiseArray)

        for(let k in all_results)
        {
          this.target_words_phonetics[this.target_words[k]] = all_results[k]
        }

        console.debug(this.target_words_phonetics)


      },

      playPause()
      {
        let _this = this;

        this.bv_tts.cancel()

        _this.play_status = true;
        _this.resumeRecording()
        // _this.results['total'] = 1
      },
      resumeRecording()
      {
        let _this = this;
        // recordAudioMedia()
        _this.is_recording = true;
        _this.congatulationsOnce = false;
        // _this.runTimer()
        _this.start_recording()
      },
      start_recording() {
        const _this = this
        this.speech_rec.start(this.possible_words,false,this.region)
        this.started = true
        this.play_pause_text = 'Recording'
        console.debug('started')

        clearTimeout(window.countDownTimer)

        clearTimeout(window.countDownTimer2)
        
        window.countDownTimer2 = setTimeout(() => {
          _this.answer_text = 'It is recording now, answer the question'
          _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
        }, 500)
          
        window.countDownTimer = setTimeout(() => {
          if(_this.play_status)
          {
            _this.started = false
            _this.play_pause_text = 'Start'

            _this.answer_text = "Oops we missed what you said! Press Start and try again"
            _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
          }
          _this.stop_recording()
        }, 45000)
      },
      stop_recording() {
        const _this = this
        _this.play_status = false;
        _this.currentSentence = ''
        _this.speech_rec.stop()
      },
      found_correct_word(curr_sentence)
      {
        const _this = this

        let has_levelled_up = false

        if(this.results['correct']==0)
        {
          startConfetti(this)

          has_levelled_up = this.updateLevel(1)
        }

        if(_this.results['total']==0)
        {
          _this.results['total'] = 1

          _this.results['correct'] = 1
          _this.results['incorrect'] = 0

        }

        console.debug("_this.results['correct'] = " + _this.results['correct'])

        this.congatulationsOnce = true
        

        this.speech_rec.stop()



        _this.best_answer = curr_sentence

        _this.answer_text = 'Congratulations! You said the right word: ' + curr_sentence + '. \n Press next to do more.'
        _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'
        _this.play_pause_text = 'Start'



        clearTimeout(window.countDownTimer)
        clearTimeout(window.countDownTimer2)

        if(!has_levelled_up)
        {
          window.moveWindow = setTimeout(() => {

          _this.nextExercise()

          }, 1000)


        }

        
      },
      found_incorrect_word(curr_sentence)
      {
        const _this = this

        if(this.results['total']==0)
        {
          _this.results['total'] = 1


          _this.results['correct'] = 0
          _this.results['incorrect'] = 1

          let has_levelled_up = this.updateLevel(-1)

          
        }


        _this.answer_text =  curr_sentence.charAt(0).toUpperCase() + curr_sentence.slice(1) + ' is not correct. \n Try again.'
        _this.answer_text_color = 'fontSize:24px;color:red;white-space: pre-wrap'



        clearTimeout(window.countDownTimer)
        clearTimeout(window.countDownTimer2)
      },
      showAnswer()
      {
        let _this = this;
        _this.showOptions = true
        _this.answer_text = 'The answer is '+_this.target_words[0];
        _this.answer_text_color = 'fontSize:24px;color:blueviolet;white-space: pre-wrap'

        if(_this.results['correct'] == 0)
        {
          _this.showedAnswer = true
          _this.results['correct'] = 0
          _this.results['incorrect'] = 1
          _this.results['total'] = 1
        }
      },
      pause()
      {
        let _this = this;
        _this.play_status = false;
        _this.play_pause_text = 'Start'
      },
    }
}
</script>

<style scoped>
.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: #195e52;
    height: 20mm;
    width: 30mm;
}
</style>
