<template>
<div>
  <Layout>
    <template v-slot:content>
      <div class="row">
        <span hidden>{{currentSentence_found}}</span>
        <div class="card card-body">
          <span class="read-text"> Press Record. Say one of the words below, until it's gone.</span>
        </div>
      </div>        
      <div class="row justify-content-center mt-2">
        <h5 style="color: blue;">{{ instruction_msg }} </h5>
      </div>
      <div class="row justify-content-center mt-2">
        <div class="col-6">
          <div class="parent">

            <img class="backimg" :src="backImg" width="500" height="350" style="object-fit: cover">
            <button :id="all_btn_info[num-1].id" v-for="num in (num_pair*2)" class="frontbtn" :style="all_btn_info[num-1].loc" >{{ all_btn_info[num-1].word }}</button>
              <!-- <img v-for="num in frontImg.length" :key="num" class="frontimg" :src="frontImg[num-1]"
              :style="frontImg_info[num-1].size" style="cursor: pointer;margin-left: 50%;" @click="interaction('front',num-1)"> -->
          </div>
        </div>
      </div>
    </template>

    <template v-slot:sidebar>
      <Sidebar class="h-100"
        :current_exercise_index="current_exercise_index"
        :total_exercises="total_exercises"

        :has_skip="true"
        @skip="nextExercise()"
        >
        <template v-slot:top>
          <button id="record_btn" v-if="recording_text==='Record'" class="btn btn-primary btn-lg w-100" @click="start_recording()"> <strong> {{recording_text}} </strong>
            <i class="bi bi-play-circle" style="margin-left: 8mm;"></i>
          </button>
          <!-- <button v-if="recording_text==='Recording'" class="btn btn-primary btn-lg w-100 mt-2" disabled > {{recording_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></button> -->
          <button v-if="recording_text==='Recording'" class="btn btn-primary btn-lg w-100 mt-2" disabled>
            <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
          </button>
          
          <!-- skip was here -->
          <button class="btn btn-outline-primary btn-lg w-100 rounded" style="margin-top: 45%;" @click="show_demo=true">
            Sounds demo
          </button>
          <div v-if="show_demo">
            <button class="w-100 mt-2" @click="bv_tts.say(target_words[0].replaceAll('/',''))" style="font-size: xx-large;"> {{target_words[0]}} <img :src="audioImg" style="width:30px;;cursor: pointer;" ></button>
            <button class="w-100 mt-2" @click="bv_tts.say(target_words[1].replaceAll('/',''))" style="font-size: xx-large;"> {{target_words[1]}} <img :src="audioImg" style="width:30px;;cursor: pointer;"></button>
          </div>          
        </template>
      </Sidebar>
    </template>
  </Layout>

</div>
</template>
<script>
  
  import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
  import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
  import SpeechRec from '@/media_input/SpeechRecognition'
  import {save_pre_computed_results} from '@/utils/results/data_save_utils'

  import {logActivity,removePunctuationFromString} from '@/media_input/utils'
  import audioImg from '@/assets/images/audio_logo.png'
  import words from '@/media_input/words'
  import constants from '@/utils/constants'

  import {LevenshteinDistance,LevenshteinDistanceWord_Phonemes,get_phonetic_transcription,LevenshteinDistanceNoLower} from '@/utils/text_distance_metrics'

  import {startConfetti} from '@/common_js/confetti'
  import { useTTS } from '@/stores/tts'
  import {BV_Points} from '@/utils/bv_points'

  /*

  {
   "exercise_type":"minimal_pairs",
   "instruct":"Read  one of these words",
   "mod_name":"minimal_pairs",
   "words":[
      "chin",
      "bin"
   ],
   "plan_name":"Word pairs",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Word pairs/0",
   "ex_path":"Word pairs/0",
   "today":"2023-07-11 17:00",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"minimal_pairs",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"Read  one of these words",
   "module_name":"Word pairs",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":3
  }

  */
  export default {
    name: 'Minimal_pairs',
    props: 
    {
        exForm: {
        type: Object,
        },

    },
    components: {Layout, Sidebar},
    data(){
      return {
        audioImg:audioImg,
        audio_player: null,
        userCategory:'',
        region:'UK',
        recording_text: 'Record',
        target_words: ['pig','big'],
        speech_rec: null,
        currentSentence: '',
        found_words:[0,0],
        number_listen_cue:[0,0],
        tested_words_phonetics:{},
        target_words_phonetics:{},
        started:false,
        start_rec_time: 0,
        end_rec_time: 0,
        ellapsed_times:[],
        current_exercise_index: 0,
        total_exercises: 0,
        backImg:null,
        num_pair:3,
        all_btn_info:[],
        pair1_ids:[],
        pair2_ids:[],
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
        show_demo:false,
        instruction_msg:' '
      }
    },
    created() {

      const _this = this
      this.userCategory = localStorage.getItem('userCategory');
      let region = localStorage.getItem('region');
        
      if(region!=undefined && region!=null)
      {
        _this.region = region
      }

      logActivity( 'accessed', 'minimal_pairs')


      this.speech_rec = new SpeechRec(function(captions) {
        this.currentSentence = captions.original
      }.bind(this))

      _this.setup()
    },
    beforeUnmount()
    {
      this.bv_tts.cancel()
      this.speech_rec.stop()
      clearTimeout(window.countDownTimer2)
      
      clearTimeout(window.countDownTimer)
    },
    computed: {
  
      currentSentence_found: async function () {
  
        console.debug('found_words = ' + this.found_words)
  
        if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
        {

  
          let curr_sentence = this.currentSentence.toString().toLowerCase()

          curr_sentence = removePunctuationFromString(curr_sentence)
  
          console.debug('curr_sentence = ' + curr_sentence)
  
          let found_something = false

          let split_sentence = curr_sentence.split(' ')

          let best_answer = null
          let minimum_distance = 100

          let convert_words = {'1st':'first','2ns':'second','3rd':'third','qing':'chin','ting':'chin','p':'pea','b':'bee','10':'ten','2':'two','3':'three','4':'four','6':'six','9':'nine'}

          for(let n in split_sentence)
          {
            let curr_word = split_sentence[n]

            if(convert_words[curr_word]!=undefined)
            {
              curr_word = convert_words[curr_word]
            }

  
            

            for(let i in this.target_words)
            {

              // if(this.found_words[i]<3)
              {
                let distance = 100

                if(curr_word in this.tested_words_phonetics && this.tested_words_phonetics[curr_word]!=null)
                {

                  distance = LevenshteinDistanceNoLower(this.target_words_phonetics[this.target_words[i]],this.tested_words_phonetics[curr_word])
                  distance = distance/Math.max(this.target_words_phonetics[this.target_words[i]].length,1)

                

                }
                else if(curr_word in this.tested_words_phonetics && this.tested_words_phonetics[curr_word]==null)
                {

                  distance = LevenshteinDistance(this.target_words[i],curr_word)

                  distance = distance/Math.max(this.target_words[i].length,1)

                }
                else
                {
                  const promiseArray = []
                  promiseArray.push(LevenshteinDistanceWord_Phonemes(this.target_words[i],this.target_words_phonetics[this.target_words[i]],curr_word))
                  const all_results = await Promise.all(promiseArray)

                  distance = all_results[0]['normalised_distance']
                  let phonetic = all_results[0]['phonetic']

                  this.tested_words_phonetics[curr_word] = phonetic

                }

                console.debug('this.target_words[i] = ' + this.target_words[i])
                console.debug('distance = ' + distance)

                if(distance!=undefined && distance<minimum_distance && distance<0.50)
                {
                  best_answer = i
                  found_something = true
                  minimum_distance = distance

                }



              }

            }
          }

          if(found_something)
          {

            if(this.found_words[best_answer]<3)
            {
              let i = best_answer
              this.found_words[i] = this.found_words[i] + 1
              this.found_target_word = 'true'

              this.stop_recording()
              found_something = true;

              if(this.found_words[i]>0)
              {
                this.compute_ellapsed_time()
                this.fadeout(i)
              }

            }
           



          }
  
         
  
          if(!found_something)
          {
            this.found_target_word = 'false'
          }
          else
          {
            return curr_sentence

          }

        }

        return ''
  
      },
    },
    methods: {
      async setup_target_words_phonetics()
      {
        this.target_words_phonetics = {}

        const promiseArray = []



        for(let k in this.target_words)
        {
          promiseArray.push(get_phonetic_transcription(this.target_words[k]))
        }

        const all_results = await Promise.all(promiseArray)

        for(let k in all_results)
        {
          this.target_words_phonetics[this.target_words[k]] = all_results[k]
        }

        console.debug(this.target_words_phonetics)


      },

      fadeout(input)
      {
        // $(document).ready(function(){
        // // $("button").click(function(){
        //   // $("#div1").fadeOut();
        //   // $(this.all_btn_info[0].id).fadeOut("slow");
        //   this.all_btn_info[0].id.fadeOut(3000);
        //  // });
        // });
        if(input == 0)
        {
          if(this.pair1_ids.length>0)
          {
            let rand_id_pair1 = Math.floor(Math.random() * this.pair1_ids.length)
            let element_id = this.pair1_ids[rand_id_pair1]
            document.getElementById(this.all_btn_info[element_id].id).hidden = true
            this.pair1_ids.splice(rand_id_pair1,1)
            this.instruction_msg = "Well done! one word is gone. Press Record to read out another word."
          }
          
        }
        else if (input == 1)
        {
          if(this.pair2_ids.length>0)
          {
            let rand_id_pair2 = Math.floor(Math.random() * this.pair2_ids.length)
            let element_id = this.pair2_ids[rand_id_pair2]
            document.getElementById(this.all_btn_info[element_id].id).hidden = true
            this.pair2_ids.splice(rand_id_pair2,1)
            this.instruction_msg = "Well done! one word is gone. Press Record to read out another word."
          }
        }
        else
        {
          console.debug('ERR: input unknown: '+input)
        }

        if((this.pair1_ids.length == 0)&&(this.pair2_ids.length == 0))
        {
          this.bv_tts.say('Well done, you got all words')
          startConfetti(this)
          this.recording_text='Done'
          // document.getElementById("record_btn").hidden = true
          setTimeout(() => {
              this.nextExercise()
          }, 2000);
        }
      },
      flipCard(input)
      {
        if(input == 0)
        {
          var cards = document.querySelectorAll('.card1');
  
          [...cards].forEach((card)=>{
            card.classList.toggle('is-flipped');
            // card.addEventListener( 'click', function() {
            //   card.classList.toggle('is-flipped');
            // });
          });
        }
        else if(input == 1)
        {
          var cards2 = document.querySelectorAll('.card2');
  
          [...cards2].forEach((card2)=>{
            card2.classList.toggle('is-flipped');
            // card.addEventListener( 'click', function() {
            //   card.classList.toggle('is-flipped');
            // });
          });
        }
        
      },
      listenCue(index)
      {
        const _this = this

        if(_this.number_listen_cue[index]==0)
        {
          _this.number_listen_cue[index] = _this.number_listen_cue[index] + 1
          _this.listenPhoneme(index)
        }
        else
        {
          _this.bv_tts.say(_this.target_words[index].replaceAll('/',''))
        }

      },
      listenPhoneme(index)
      {
          const _this = this

          let curr_word = _this.target_words[index]

          let phoneme_single_target_word = words.word_2_phoneme[curr_word]

          if(phoneme_single_target_word==undefined || phoneme_single_target_word==null)
          {
            _this.bv_tts.say(_this.target_words[index].replaceAll('/',''))
          }
          else
          {
            let phoneme = phoneme_single_target_word[1]

            let simple_representation_phoneme = ''

            for(let i in constants.phonemeDisplayDictionaryIPA)
            {
                if(constants.phonemeDisplayDictionaryIPA[i]==phoneme)
                {
                    simple_representation_phoneme = i
                    break
                }
            }

            if(simple_representation_phoneme!='')
            {
                let audio_file_location = '/BV/audio_resources/audio_hints/phonemes/'+simple_representation_phoneme+'.mp3'

                console.debug(audio_file_location)
                this.playAudio(audio_file_location)

            }

          }




      },
      playAudio(audio_file_location)
      {

          const _this = this;
          const storage = _this.$firebase.storage();

          storage.ref(audio_file_location).getDownloadURL()
          .then((url) => {


              _this.playSound(url);

          })
          .catch((error) => {
          });
      
      },
      playSound(url) {
          const _this = this

          if(_this.audio_player==null)
          {
              _this.audio_player = new Audio(url);

              _this.audio_player.play();

          }
          else
          {


              if(!_this.audio_player.paused)
              {
              _this.audio_player.pause();

              
              }
              // else
          
              {
              _this.audio_player = new Audio(url);

              _this.audio_player.play();

              }
          }

      },
      compute_ellapsed_time()
      {
        const _this = this
        _this.end_rec_time = new Date();
        let timeDiff = _this.end_rec_time - _this.start_rec_time; //in ms
        timeDiff /= 1000;

        _this.ellapsed_times.push(timeDiff)


      },
      start_recording() {
        const _this = this
        this.instruction_msg = ''
        clearTimeout(window.countDownTimer2)
        
        clearTimeout(window.countDownTimer)
        this.speech_rec.start(this.target_words,false,this.region)

        // this.speech_rec.start(this.target_words)

        this.started = true
  
        window.countDownTimer = setTimeout(() => {
          _this.recording_text = 'Recording'
          _this.start_rec_time = new Date();

  
        }, 600)

        window.countDownTimer2 = setTimeout(() => {
          _this.stop_recording()
          
          
        }, 30000)
      },
      stop_recording() {
        this.started = false
        this.currentSentence = ''
        this.speech_rec.stop()
        this.recording_text = 'Record'
  
      },
      nextExercise()
      {

          const _this = this;

          console.debug('next exercise')

          if(_this.found_words[0]>0 || _this.found_words[1]>0)
          {

            console.debug('next found')

            
            let plot_results = {Target_Words:_this.target_words,Found_Words:_this.found_words,Ellapsed_Times:_this.ellapsed_times}
            let all_results = {Target_Words:_this.target_words,Found_Words:_this.found_words,Number_Listen_Cue:_this.number_listen_cue,Ellapsed_Times:_this.ellapsed_times}
            let results = {all_results:all_results, plot_results:plot_results}
    
            var minimalPairsData = {resultsTablePre: {exercise_type: 'Minimal Pairs', exercise: 'Minimal Pairs', results: results, audio_address:''}}

            save_pre_computed_results(_this.exForm,_this.$firebase, minimalPairsData)

            _this.bv_points.add(10)

            _this.$emit('logExerciseResults', minimalPairsData);

          }

          _this.$emit('nextExercise');
      },
      setup()
      {

        const _this = this

        _this.target_words = _this.exForm.words

        this.setup_target_words_phonetics()

        _this.current_exercise_index = _this.exForm.current_exercise_index + 1

        _this.total_exercises = _this.exForm.total_all_type_exercises

        let rand_pic_id = Math.floor(Math.random() * constants.num_meme_imgs)
        _this.backImg = new URL(`../../../../../assets/images/well_done/well_done${rand_pic_id}.jpeg`, import.meta.url)
        ////this.$forceUpdate()
        this.create_btn_info()

      },
      create_btn_info()
      {
        let full_loc = []
        let full_words = []
        this.pair1_ids = []
        this.pair2_ids = []
        for(let i=0; i<this.num_pair;i++)
        {
          for(let j = 0; j<this.target_words.length;j++)
          {
            full_words.push(this.target_words[j])
            let current_loc = 'top:'+j*200+'px; left:'+i*200+'px;'
            full_loc.push(current_loc)
          }
        }
        // console.debug("loc= "+full_loc+' words= '+full_words)
        for(let i=0; i<this.num_pair;i++)
        {
          for(let j = 0; j<this.target_words.length;j++)
          {
            let rand_id_loc = Math.floor(Math.random()*full_loc.length)
            let rand_id_word = Math.floor(Math.random()*full_words.length)
            let colour = ''
            if(full_words[rand_id_word] == this.target_words[0])
            {
              colour = 'background-color:slateblue;color:white;border-color:white'
              this.pair1_ids.push(i*this.target_words.length+j)
            }
            else
            {
              colour = 'background-color:orange;border-color:white'
              this.pair2_ids.push(i*this.target_words.length+j)
            }
            let element = {'id':i*this.target_words.length+j,'word':full_words[rand_id_word],'loc':full_loc[rand_id_loc]+colour}
            this.all_btn_info.push(element)
            // this.btn_info_cut.push(element)
            full_loc.splice(rand_id_loc, 1);
            full_words.splice(rand_id_word,1);
          }
        }
        console.debug('any one loc= '+this.all_btn_info[1].loc)
        ////this.$forceUpdate()
      }
    },
  }
  </script>
  <style scoped>

  .scene1 {
    display: inline-block;
    width: 150px;
    height: 200px;
  /*   border: 1px solid #CCC; */
    margin: 20px 0;
    perspective: 600px;
  }
  
  .card1 {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 1s;
  }
  
  .card1.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }
  
  .card1__face {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 180px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    backface-visibility: hidden;
  }
  
  .card1__face--front {
    background: orange;
  }
  
  .card1__face--back {
    background: orange;
    transform: rotateY(180deg);
  }
  .scene2 {
    display: inline-block;
    width: 150px;
    height: 200px;
  /*   border: 1px solid #CCC; */
    margin: 20px 0;
    perspective: 600px;
  }
  
  .card2 {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 1s;
  }
  
  .card2.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }
  
  .card2__face {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 180px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    backface-visibility: hidden;
  }
  
  .card2__face--front {
    background: slateblue;
  }
  
  .card2__face--back {
    background: slateblue;
    transform: rotateY(180deg);
  }

.parent {
  position: relative;
  top: 0;
  left: 0;
}
.backimg {
  position: relative;
  top: 0;
  left: 0;
  /* border: 1px red solid; */
}
.frontimg {
  position: absolute;
  /* top: 60px;
  left: 500px; */
  /* border: 1px green solid; */
}

.frontbtn{

  position: absolute;
  width: 240px;
  height: 200px;
  font-size: xx-large;
  transition: 1s;
}
</style>

<!-- Results Description
{
  "Minimal Pairs": {
    "<key>": {
      "audio_address": "<empty>",
      "resultsTablePre": {
        "exercise": "Minimal Pairs",
        "exercise_type": "Minimal Pairs",
        "results": {
          "all_results": {
            "Ellapsed_Times": [ // for each instance of a word said
              "<float_seconds>"
            ],
            "Found_Words": [
              "<int>", //number times target word was said
              "<int>"
            ],
            "Number_Listen_Cue": [
              "<int>", //number of times the cue for this words was listened 
              "<int>"
            ],
            "Target_Words": [
              "<string>",
              "<string>"
            ]
          },
          "plot_results": {
            "Ellapsed_Times": [ // for each instance of a word said
            "<float_seconds>"
            ],
            "Found_Words": [
              "<int>", //number times target word was said
              "<int>"
            ],
          
            "Target_Words": [
              "<string>",
              "<string>"
            ]
          }
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Minimal Pairs"
  }
} -->