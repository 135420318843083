<template>
  <div>

    <Layout>
      <template v-slot:content>
        <div v-if="task_type==='semantic_memory'" class="justify-content-md-center">

          <!-- <div class="row read-text justify-content-md-center">
            Which of the pictures goes best with this image:
          </div> -->
          <div class="card card-body justify-content-center"  :body-style="{ padding: '0px' }">
            <span  class="h3 align-bottom"> Which of the pictures goes best with this image:</span>
            <div class="row justify-content-center">
              <div class="col-6">
                <div v-bind:style="{'height': '250px','width':'400px'}">
                  <img id= "img_sample" v-bind:src="semantic_memory_data['sample']['src']"   class="img-fluid w-100 h-100" style="object-fit: contain;">        
                </div>
              </div>
              
            </div>
            
          </div>
        </div>

        <div v-if="task_type==='recognition_memory'" class="justify-content-md-center">
          <div class="row read-text justify-content-md-center">
            Which of these pictures we have seen on a previous exercise:
          </div>   
        </div>

        <div v-if="task_type==='written_words_comprehension' " class="justify-content-md-center">
          <div class="row read-text justify-content-md-center">
            Which of these pictures go with the word:
          </div>   
          <span class="h3 align-bottom">{{target_word}}</span>
        </div>

        <div v-if="task_type==='written_sentences_comprehension'" class="justify-content-md-center">
          <div class="row read-text justify-content-md-center">
            Which of these pictures go with the sentence:
          </div>   
          <span class="h3 align-bottom">{{target_sentence}}</span>
        </div>

        <div v-if="task_type==='spoken_words_comprehension'" class="justify-content-md-center">
          <div class="row read-text justify-content-md-center">
            Which of these pictures go with the word I say
          </div>   
          <div class="row mt-2  mb-2 justify-content-center">
            <button class="btn btn-primary btn-lg mt-2 w-100" @click="hearWord()" ><i class="bi bi-play" style="font-size: 1.5rem"></i> Hear word</button>
          </div>
        </div>

        <div v-if="task_type==='spoken_sentences_comprehension'" class="justify-content-md-center">
          <div class="row read-text justify-content-md-center">
            Which of these pictures go with the sentence I say

          </div>   
          <div class="row mt-2  mb-2 justify-content-center">
              <button class="btn btn-primary btn-lg mt-2 w-100" @click="bv_tts.say_sentences(['Which of these pictures go with the sentence I say: ', this.target_sentence])" ><i class="bi bi-play" style="font-size: 1.5rem"></i> Hear word</button>
          </div>
        </div>

        <div v-if="all_img.length" class="row mt-2 justify-content-center">
          <div class="col-md-5">
            <div class="card card-body" v-bind:style="{'background':frameColour[0], 'height': '225px','width':'400px'}">
              <img id= "img_1" v-bind:src="all_img[0]"  @click="isNotClickable!=0 ? null : selectImg(0)" class="img-fluid w-100 h-100" style="object-fit: contain;">        
            </div>
          </div>

          <div class="col-md-5">
            <div class="card card-body" v-bind:style="{'background':frameColour[1], 'height': '225px','width':'400px'}">
              <img id= "img_2" v-bind:src="all_img[1]"  @click="isNotClickable!=0 ? null : selectImg(1)" class="img-fluid w-100 h-100" style="object-fit: contain;">
            </div>
          </div>
        </div>
        <div class="row mt-2 justify-content-center" >
          <div class="col-md-5">
            <div class="card card-body" v-bind:style="{'height': '225px','width':'400px', 'background':frameColour[2]}" >
              <img id= "img_3"  v-bind:src="all_img[2]" @click="isNotClickable!=0 ? null : selectImg(2)" class="img-fluid w-100 h-100" style="object-fit: contain;">        
            </div>
          </div>
          <div class="col-md-5">
            <div class="card card-body" v-bind:style="{'height': '225px','width':'400px', 'background':frameColour[3]}" >
              <img id= "img_4" v-bind:src="all_img[3]"  @click="isNotClickable!=0 ? null : selectImg(3)" class="img-fluid w-100 h-100" style="object-fit: contain;">
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          >
          <template v-slot:top>
            <hr/>
            <p class="lead" font-size="medium">Task {{curr_task+1}} of {{n_tasks}}</p>
            <div>
              <button v-if="score!=''" class="btn btn-info btn-lg w-100 mt-2" @click="nextTask()" type="button">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
              <button v-else class="btn btn-secondary btn-lg w-100 mt-2" @click="nextTask()" type="button">Skip <i class="bi bi-chevron-bar-right" style="margin-left: 8mm;"></i></button>

              <div v-if="hasScoreDropdown && task_type==='spoken_words_comprehension'">
                <label class="form-label mt-2">Score</label>
                <select class="form-select mb-3" v-model="score" placeholder="Select" style="width:100%" @change="updateDropdown()">
                  <option key="1" label="0 - Not correct" value=0> </option>
                  <option key="2" label="1 - Correct (delay>5s)" value=1-D> </option>
                  <option key="3" label="1 - Correct (self-correction)" value=1-Sc> </option>
                  <option key="4" label="1 - Correct (repetition of stimulus)" value=1-R> </option>
                  <option key="5" label="2 - Correct" value=2> </option>
                </select>
              </div>

              <div v-if="hasScoreDropdown && task_type==='written_words_comprehension'">
                <label class="form-label mt-2">Score</label>
                <select class="form-select mb-3" v-model="score" placeholder="Select" style="width:100%" @change="updateDropdown()">
                  <option key="1" label="0 - Not correct" value=0> </option>
                  <option key="2" label="1 - Correct (delay>5s)" value=1-D> </option>
                  <option key="3" label="1 - Correct (self-correction)" value=1-Sc> </option>
                  <option key="5" label="2 - Correct" value=2> </option>
                </select>
              </div>
            </div>           
          </template>
        </Sidebar>
      </template>
    </Layout>

    <div class="modal fade" id="result_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{finish_statement}}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row m-2">
              <div class="row">
                <h4 >{{finish_detail}}</h4>

              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import { Modal } from 'bootstrap'
import {getTodayString,getSeconds} from '@/media_input/utils'


import {getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'

import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'

export default {
  name: "Word_memory",
  /*

  {
   "exercise_type":"memory_image",
   "instruct":"memory_image",
   "n_images":8,
   "n_words":5,
   "name":4,
   "plan_name":"Memory (image)",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Memory (image)/4",
   "ex_path":"Memory (image)/4",
   "today":"2023-07-11 17:24",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"memory_image",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"memory_image",
   "module_name":"Memory (image)",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
  props: {
    exForm: {
      type: Object,
    }
  },
  components: {Layout, Sidebar},
  data() {
    return {
      assessment_start:'',
      assessment_end:'',
      listen_count:0,
      task_start:'',
      task_end:'',
      on_hold_for_next_task: 0,
      score:'',
      hasScoreDropdown:false,
      previous_selections:[],
      task_types:['semantic_memory','recognition_memory','written_words_comprehension','written_sentences_comprehension','spoken_sentences_comprehension','spoken_words_comprehension'],
      task_type:'written_words_comprehension',
      semantic_memory_data:{},
      semantic_memory_answers:{},
      recognition_memory_data:{},
      recognition_memory_answers:{},
      target_word:'dog',
      target_sentence:'the woman is standing up',
      exercise_data:{},
      exercise_results:{},
      curr_task:0,
      n_tasks:0,
      listen_count:0,
      total_exercises:1,
      destroying: false,
      n_words: 2,
      n_images: 4,
      results:{correct:0,incorrect:0,n_words:0,n_images:0, total:0},
      finish_statement:'',
      finish_detail:'',
      frameColour: ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
      frameColour_mem:  ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
      Ex_Description_Split: '',
      question_words:[],
      all_finished: false,
      qs_image_idx:[],
      all_img:['','','',''],
      btn_text:'Start',
      delayShow:false,
      img_idx: ['img_1','img_2','img_3','img_4'],
      count_ex:0,
      chosen_images:[],
      audioTimeout: null,
      curr_ex_done:false,
      current_exercise_index:1,
      number_exercises: 1,
      isNotClickable:0,
      delayShow:false,
      show_results:false,
      bv_tts: useTTS().tts_engine,
    }
  },
  created() 
  {
    let _this = this;
    _this.count_ex = 0;
    
    logActivity( 'accessed', 'cats_image_selection')

    setTimeout(()=>{
      this.delayShow=true;
    },500)

    console.debug('this.exForm = ' + this.exForm)
    console.debug(Object.keys(this.exForm))

    _this.task_type = this.exForm.task_type
    _this.setup()

    this.assessment_start = getTodayString()
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()


    this.destroying = true

    clearTimeout(this.audioTimeout)
  },
  mounted()
  {
    const _this = this
  },
  methods: 
  {
    say_sentences(sentences)
    {
      let _this = this
      _this.isNotClickable = 2
      _this.btn_text = 'Repeat'
      _this.bv_tts.say_sentences(sentences, 500, ()=> {_this.isNotClickable = 0;})
    },
    setup()
    {

      const _this = this


      var database_ref = ''
      if(this.task_type==='semantic_memory')
      {
        database_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('semantic_memory');

      }
      else if(this.task_type==='recognition_memory')
      {
        database_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('recognition_memory');
      }
      else if(this.task_type==='spoken_words_comprehension')
      {
        this.hasScoreDropdown = true
        database_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('BV_spoken_words_comprehension');
      }
      else if(this.task_type==='written_words_comprehension')
      {
        this.hasScoreDropdown = true
        database_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('BV_written_words_comprehension');
      }



      this.exercise_results['type'] = this.task_type
      this.exercise_results['results'] = []
      getData(database_ref , function (err, result) {

        _this.exercise_data = result.val()

        _this.n_tasks = _this.exercise_data.length

        if(_this.task_type==='semantic_memory')
        {
          _this.startSemanticMemoryTask(0)

        }
        else if(_this.task_type==='recognition_memory')
        {
          _this.startRecognitionMemoryTask(0)

        }
        else if(_this.task_type==='spoken_words_comprehension')
        {
          _this.startSpokenWordsTask(0)
        }
        else if(_this.task_type==='written_words_comprehension')
        {
          _this.startWrittenWordsTask(0)
        }

      }
      )



    },
    setupSemanticMemory()
    {
      let _this = this;
      var database_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('semantic_memory');
      var do_once = true;

      this.exercise_results['type'] = 'semantic_memory'
      this.exercise_results['results'] = []
      getData(database_ref , function (err, result) {

        _this.exercise_data = result.val()

        _this.n_tasks = _this.exercise_data.length

        _this.startSemanticMemoryTask(0)


      }
      )


    },
    nextTask()
    {
      this.curr_task = this.curr_task+1

      if(this.curr_task<this.n_tasks)
      {

        console.debug('this.n_tasks = ' + this.n_tasks)
        console.debug('this.curr_task = ' + this.curr_task)

        this.clearHighlight()
      
        if(this.task_type==='semantic_memory')
        {
          this.startSemanticMemoryTask(this.curr_task)

        }
        else if(this.task_type==='recognition_memory')
        {
          this.startRecognitionMemoryTask(this.curr_task)
        }
        else if(this.task_type==='spoken_words_comprehension')
        {
          this.startSpokenWordsTask(this.curr_task)
        }
        else if(this.task_type==='written_words_comprehension')
        {


          this.startWrittenWordsTask(this.curr_task)
        }

        
      }
      else
      {

        this.nextExercise()

      }

   

    },
    startSemanticMemoryTask(n)
    {
      const _this = this

      this.curr_task=n

      let curr_exercise = this.exercise_data[n]

      this.semantic_memory_data = {}

      this.semantic_memory_data['sample'] = curr_exercise['sample']
      this.semantic_memory_data['target'] = curr_exercise['target']
      this.semantic_memory_data['close semantic distractor'] = curr_exercise['close semantic distractor']
      this.semantic_memory_data['distant semantic distractor'] = curr_exercise['distant semantic distractor']
      this.semantic_memory_data['unrelated distractor'] = curr_exercise['unrelated distractor']

      this.semantic_memory_answers = {}

      let random_numbers = [0,1,2,3]


      for(let k in this.semantic_memory_data)
      {

        let image_address = this.semantic_memory_data[k]['image_address']
        var storageRef = _this.$firebase.storage().ref();

        storageRef.child(image_address).getDownloadURL().then(function(url) {
          _this.semantic_memory_data[k]['src'] = url;


          if(k!=='sample')
          {

            let index = Math.floor(Math.random() * random_numbers.length)    

            _this.semantic_memory_answers[random_numbers[index]] = k

            _this.semantic_memory_data[k]['img_index']= random_numbers[index]

            _this.all_img[random_numbers[index]] = url;
          
            random_numbers.splice(index,1)

            

          }

          _this.on_hold_for_next_task = _this.on_hold_for_next_task+1


          if(_this.on_hold_for_next_task>=4)
          {
            _this.task_start = getSeconds()
            
          }

        }).catch(function(error) {

        });

        
      }

      this.exercise_results['results'].push(this.semantic_memory_data)

      this.exercise_results['results'][this.exercise_results.length-1]['selection'] = ''



    },
    setupRecognitionMemory()
    {
      let _this = this;
      var database_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('recognition_memory');
      var do_once = true;

      this.exercise_results['type'] = 'recognition_memory'
      this.exercise_results['results'] = []
      getData(database_ref , function (err, result) {

        _this.exercise_data = result.val()

        _this.n_tasks = _this.exercise_data.length

        _this.startRecognitionMemoryTask(0)


      }
      )


    },
    startRecognitionMemoryTask(n)
    {
      const _this = this

      this.curr_task=n

      let curr_exercise = this.exercise_data[n]

      this.recognition_memory_data = {}

      this.recognition_memory_data['target'] = curr_exercise['target']
      this.recognition_memory_data['1'] = curr_exercise['1']
      this.recognition_memory_data['2'] = curr_exercise['2']
      this.recognition_memory_data['3'] = curr_exercise['3']

      this.recognition_memory_answers = {}

      let random_numbers = [0,1,2,3]


      for(let k in this.recognition_memory_data)
      {

        let image_address = this.recognition_memory_data[k]['image_address']
        var storageRef = _this.$firebase.storage().ref();

        storageRef.child(image_address).getDownloadURL().then(function(url) {
          _this.recognition_memory_data[k]['src'] = url;

          let index = Math.floor(Math.random() * random_numbers.length)    

          _this.recognition_memory_answers[random_numbers[index]] = k

          _this.recognition_memory_data[k]['img_index']= random_numbers[index]

          _this.all_img[random_numbers[index]] = url;


          random_numbers.splice(index,1)
          _this.on_hold_for_next_task = _this.on_hold_for_next_task+1

          if(_this.on_hold_for_next_task>=4)
          {
            _this.task_start = getSeconds()
            
          }

        

        }).catch(function(error) {

        });

        
      }

      this.exercise_results['results'].push(this.recognition_memory_data)

      this.exercise_results['results'][this.exercise_results.length-1]['selection'] = ''


    },
    hearWord()
    {


      this.bv_tts.say('Which of these pictures go with the word I say: ' + this.target_word)

      this.listen_count = this.listen_count+1
      if(this.listen_count<=1)
      {
        this.task_start = getSeconds()
        
      }

    },
    startSpokenWordsTask(n)
    {
      const _this = this

      _this.listen_count = 0

      _this.on_hold_for_next_task = 0

      this.score = ''

      this.curr_task=n

      let curr_exercise = this.exercise_data[n]

      this.spoken_word_data = curr_exercise

      this.spoken_word_answers = {}

      this.target_word = this.spoken_word_data['target']['name']

      let random_numbers = [0,1,2,3]


      for(let k in this.spoken_word_data)
      {

        if(this.spoken_word_data[k]['image_address'])
        {

          let image_address = this.spoken_word_data[k]['image_address']
          var storageRef = _this.$firebase.storage().ref();

          storageRef.child(image_address).getDownloadURL().then(function(url) {
            _this.spoken_word_data[k]['src'] = url;

            let index = Math.floor(Math.random() * random_numbers.length)    

            _this.spoken_word_answers[random_numbers[index]] = k

            _this.spoken_word_data[k]['img_index']= random_numbers[index]

            _this.all_img[random_numbers[index]] = url;


            random_numbers.splice(index,1)


            _this.on_hold_for_next_task = _this.on_hold_for_next_task + 1
            if(_this.on_hold_for_next_task>=4)
            {
              _this.task_start = getSeconds()
              // put this when they hear the word so doesnt matter
              
            }

          

          }).catch(function(error) {

          });
          
        }

        

        
      }

      {

        this.exercise_results['results'].push({})
        this.exercise_results['results'][this.curr_task]['selection'] = ''
        this.exercise_results['results'][this.curr_task] = this.spoken_word_data

      }


    },
    startWrittenWordsTask(n)
    {
      console.debug('startWrittenWordsTask')
      const _this = this

      _this.on_hold_for_next_task = 0

      this.score = ''

      this.curr_task=n

      let curr_exercise = this.exercise_data[n]

      console.debug('n = ' + n)

      this.written_word_data = curr_exercise

      this.written_word_answers = {}

      this.target_word = this.written_word_data['target']['name']

      let random_numbers = [0,1,2,3]


  


      for(let k in this.written_word_data)
      {

        if(this.written_word_data[k]['image_address'])
        {

          let image_address = this.written_word_data[k]['image_address']

          console.debug('image_address = ' + image_address)
          var storageRef = _this.$firebase.storage().ref();

          storageRef.child(image_address).getDownloadURL().then(function(url) {
            _this.written_word_data[k]['src'] = url;

            let index = Math.floor(Math.random() * random_numbers.length)    

            _this.written_word_answers[random_numbers[index]] = k

            _this.written_word_data[k]['img_index']= random_numbers[index]

            _this.all_img[random_numbers[index]] = url;


            random_numbers.splice(index,1)

            _this.on_hold_for_next_task = _this.on_hold_for_next_task+1

            if(_this.on_hold_for_next_task>=4)
            {
              _this.task_start = getSeconds()
              
            }

          

          }).catch(function(error) {

          });
          
        }
        
      }
      {

      this.exercise_results['results'].push({})
      this.exercise_results['results'][this.curr_task]['selection'] = ''
      this.exercise_results['results'][this.curr_task] = this.written_word_data

      }
    },
    nextExercise()
    {


      
      const _this = this;

      this.assessment_end = getTodayString()

      this.exercise_results['assessment_start'] = this.assessment_start
      
      this.exercise_results['assessment_end'] = this.assessment_end

      let results = {}
      results[this.task_type] = this.exercise_results
       
      var imageSelectionData = {resultsTablePre: {exercise_type: 'cats', exercise: this.task_type, results: results, audio_address:''}}


      _this.saveResults(imageSelectionData)
      _this.$emit('logExerciseResults', imageSelectionData);
      
      // _this.$emit('nextExercise');

      var goToResultsData = {curr_ex_done:true}

      _this.$emit('goToResults', goToResultsData);




    },
    saveResults(imageSelectionData)
    {
      const _this = this

      save_pre_computed_results(_this.exForm,_this.$firebase,imageSelectionData)
    },
    selectImg(img_num)
    {

   


      if(this.task_type==='semantic_memory' || this.task_type==='recognition_memory')
      {
        this.SemanticRecognitionMemorySelectImg(img_num)      
      }
      else if(this.task_type==='spoken_words_comprehension')
      {
        this.SpokenWordsSelectImg(img_num)      

      }
      else if(this.task_type==='written_words_comprehension')
      {
        this.WrittenWordsSelectImg(img_num)      

      }

    },
    updateDropdown()
    {
      if(this.task_type==='spoken_words_comprehension')
      {

        this.SpokenWordsSelectImg(null)      

      }
      else if(this.task_type==='written_words_comprehension')
      {

        this.WrittenWordsSelectImg(null)      

      }

    },
    SpokenWordsSelectImg(img_num)
    {
      const _this = this

      console.debug('here1')

      let current_selection = []

      let length = this.exercise_results['results'].length 

      if(this.curr_task>=length)
      {

        this.exercise_results['results'].push({})
        this.exercise_results['results'][this.curr_task]['selection'] = ''
        this.exercise_results['results'][this.curr_task] = this.spoken_word_data


      }

      if(!('delay' in this.exercise_results['results'][this.curr_task]))
      {
        this.task_end = getSeconds()

        this.exercise_results['results'][this.curr_task]['delay'] = this.task_end-this.task_start

      }



      if(img_num!=null)
      {
        

        current_selection = this.spoken_word_answers[img_num]

        console.debug(this.exercise_results['results'].length)

        this.exercise_results['results'][this.curr_task]['selection'] = current_selection

        this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];

        if(current_selection==='target')
        {

          if(this.score=='')
          {
            this.score = '2'

          }
          this.frameColour[img_num] = '#663399';

          
        }
        else
        {
          if(this.score=='')
          {
            this.score = '0'

          }
          this.frameColour[img_num] = '#663399';


        }

      }

      

      this.exercise_results['results'][this.curr_task]['score'] = this.score

    },
    WrittenWordsSelectImg(img_num)
    {
      const _this = this

      console.debug('here1')

      let current_selection = []

      let length = this.exercise_results['results'].length 

      // if(this.curr_task>=length)
      // {
      //   console.debug('here2')

      //   this.exercise_results['results'].push({})
      //   this.exercise_results['results'][this.curr_task]['selection'] = ''
      //   this.exercise_results['results'][this.curr_task] = this.written_word_data

      // }

      if(!('delay' in this.exercise_results['results'][this.curr_task]))
      {
        this.task_end = getSeconds()

        this.exercise_results['results'][this.curr_task]['delay'] = this.task_end-this.task_start

      }


      if(img_num!=null)
      {
        current_selection = this.written_word_answers[img_num]
        console.debug(this.exercise_results['results'].length)

        

        this.exercise_results['results'][this.curr_task]['selection'] = current_selection


        this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];


        if(current_selection==='target')
        {

          if(this.score=='')
          {
            this.score = '2'

          }
          this.frameColour[img_num] = '#663399';

          
        }
        else
        {
          if(this.score=='')
          {
            this.score = '0'

          }
          this.frameColour[img_num] = '#663399';


        }

      }


      this.exercise_results['results'][this.curr_task]['score'] = this.score

    },
    SemanticRecognitionMemorySelectImg(img_num)
    {
      const _this = this


      if(this.on_hold_for_next_task<4)
      {
        return
      }

      let current_selection = []

      let index = this.exercise_results['results'].length-1




      if(this.task_type==='semantic_memory')
      {
        current_selection = this.semantic_memory_answers[img_num]
        // this.exercise_results['results'][index] = this.semantic_memory_data

        this.exercise_results['results'][index]['selection'] = current_selection

      }
      else if(this.task_type==='recognition_memory')
      {

        current_selection = this.recognition_memory_answers[img_num]

        // this.exercise_results['results'][index] = this.recognition_memory_data

        this.exercise_results['results'][index]['selection'] = current_selection

      }


      if(!('delay' in this.exercise_results['results'][this.curr_task]))
      {
        this.task_end = getSeconds()

        this.exercise_results['results'][this.curr_task]['delay'] = this.task_end-this.task_start

      }

      this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];




      if(current_selection==='target')
      {
        this.exercise_results['results'][index]['correct'] = true 
        this.frameColour[img_num] = '#663399';

        
      }
      else
      {
        this.exercise_results['results'][index]['correct'] = false 

        this.frameColour[img_num] = '#663399';


      }

   
        this.on_hold_for_next_task = 0
        setTimeout(() => { _this.nextTask()}, 500);

    




      
      
    },
    clearHighlight()
    {
      this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];

    },
    oldSelectImg(img_num)
    {

    
        const _this = this
        this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];
        this.frameColour = this.frameColour_mem;

        _this.results['n_words'] = _this.n_words    

        _this.results['n_images'] = _this.n_images    

        if(this.qs_image_idx.includes(this.chosen_images[img_num]) && !this.previous_selections.includes(img_num))
        {
          this.previous_selections.push(img_num)
            _this.results['correct'] = _this.results['correct']  + 1           
            this.frameColour[img_num] = '#663399';

            let images_left = this.qs_image_idx.length-_this.results['correct']



            let correct_text = 'Correct.'
            
            if(images_left==0)
            {
              this.all_finished = true
              correct_text = 'You got them all. Press Next to move to the next exercise.'
              this.finish_statement = 'Congrats! You got all ' + this.qs_image_idx.length + ' words.';
              this.finish_detail = 'Click Next to go to the next exercise.'
              let result_pop = new Modal(document.getElementById('result_pop'));
              result_pop.show()

            }
            else if(images_left==1)
            {
              correct_text = 'Correct. ' + images_left + ' word left.'

            }
            else
            {

              correct_text = 'Correct. ' + images_left + ' words left.'
            }
            _this.bv_tts.say_sentences([correct_text])

        }
        else if(!this.qs_image_idx.includes(this.chosen_images[img_num]))
        {
            _this.results['incorrect'] = _this.results['incorrect']  + 1           

            this.frameColour[img_num] = '#663399';

            let incorrect_text = 'Not correct'
            _this.bv_tts.say_sentences([incorrect_text])

        }
        _this.curr_ex_done = true
        _this.results['total'] = _this.n_words
        this.frameColour_mem = this.frameColour;

    },

  },
}
</script>
<style scoped>
.std-pre{
  word-break: break-all

}
.page_title {
  width: 100%;
  height: 13mm;
  background-color: #7cc543;
    /* opacity: 80%; */
}
.page_main {
  width: 100%;
  height: calc(100vh - 13mm);
  background-color: #195e52;
  /* opacity: 80%; */
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 13mm;
  margin-left: 2%;
  font-size: 30px;
}
.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}

.text_item {
/* margin-bottom: 18px; */
font-size: 30px;
/* vertical-align: middle; */
/* padding: 40px 200px; */
/* height:40%; */
}

.custom-text{
  font-size: 20px;
    /* color:#FFFFFF; */
}

.menu__item {
  display: block;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 24px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}

.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

.button::-moz-focus-inner{
  border: 0;
  padding: 0;
}


</style>

<!-- Result Description
{
  "Memory Image":
  {
    "<key>": {
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>", //audio address is here because that's how I used to find where the results are
        "exercise": "Memory Image",
        "exercise_type": "Memory Games",
        "results": {
          "all_results": { 
            "Correct": "<int>",
            "Incorrect": "<int>",
            "ListenCount": "<int>",
            "Total": "<int>"
          },
          "plot_results": { //These values are the ones that feed into the end results plot
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Memory Image"
  }
} -->