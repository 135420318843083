<template>
  <div class="row d-flex mb-3" style="min-height: 100vh;">
    <div class="row">
      <div class="col-xl-10 pe-0 d-flex flex-column">
        <div class="card card-body h-100">
          <div class="row">
            <div class="card card-body">
              <h4>
                  Click start, watch what simon does. When it’s your turn, follow what simon did.

              </h4>
              <h4 v-if="set_level" style="color: blueviolet;">
                The goal is to copy him till level {{ max_rounds }}.
              </h4>
              <h4 class="mt-2" v-if="!set_level && previous_top" style="color: blueviolet;">
                Your previous record is level {{ top_result }}. Try to beat it!
              </h4>
            </div>
          </div>
          <div class="row mt-2">
            <span style="font-size: x-large;">
              Level {{ level }}
            </span>
          </div>
          <div class="row mt-4">
              <span :style="interaction_message_color"> {{  interaction_message}}</span> 
          </div>
          <div id='colour_buttons' class="row mt-4 justify-content-center tile-container js-container unclickable">
            <div  class="tile tile-magenta" data-tile="red" @click="handleClick('red')"></div>
            <div  class="tile tile-teal" data-tile="green" @click="handleClick('green')"></div>
            <div  class="tile tile-blue" data-tile="blue" @click="handleClick('blue')"></div>
            <div  class="tile tile-orange" data-tile="yellow" @click="handleClick('yellow')"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-2" style="padding-left: 0px">
        <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
          <button v-if="!starting" class="btn-primary btn btn-lg w-100 mt-2"  @click="start()">Start <i class="bi bi-play-circle" style="margin-left: 5mm;"></i></button>
            <button v-if="level>0 && starting" class="btn-success btn btn-lg w-100 mt-2"  @click="repeat()">Repeat <i class="bi bi-arrow-clockwise" style="margin-left: 5mm;"></i></button>
        </div>
      </div>
    </div>
    <div class="hidden">
      <audio
        src="https://s3.amazonaws.com/freecodecamp/simonSound1.mp3"
        data-sound="red"
      ></audio>
      <audio
        src="https://s3.amazonaws.com/freecodecamp/simonSound2.mp3"
        data-sound="green"
      ></audio>
      <audio
        src="https://s3.amazonaws.com/freecodecamp/simonSound3.mp3"
        data-sound="blue"
      ></audio>
      <audio
        src="https://s3.amazonaws.com/freecodecamp/simonSound4.mp3"
        data-sound="yellow"
      ></audio>
    </div>
  </div>    
</template>

<script>  
  import { Modal } from 'bootstrap'
  import { useTTS } from '@/stores/tts'
  import {getUserDataDir, updateDataDirectory } from '@/firebase/index'
  import Footer from '@/views/Footer.vue'
  import Navbar from '@/views/Navbar.vue'

  export default {
    components: { Navbar, Footer},
    name: 'Simon_says',
    props: {
        exForm: {
        type: Object,
        },
      },

    data() {
      return {
        simon_turn:false,
        simon_sequence:[],
        human_sequence:[],
        level:0,
        max_rounds:100,
        starting:false,
        interaction_message_color:'',
        interaction_message:'',
        set_level: false,
        previous_top:false,
        bv_tts: useTTS().tts_engine,
        top_result:0
      }
    },
    created() 
    {
      const _this = this
      this.userCategory = localStorage.getItem('userCategory');

      if(this.exForm['type']==='set_level')
      {
        this.set_level = true
        this.max_rounds = this.exForm['level']
      }
      setTimeout(() => {
        this.bv_tts.say_sentences(['Click start, watch what simon does.',' When it’s your turn, follow what simon did.'])
      }, 500)

      this.checkPreviousTopResult()

    },
    beforeUnmount()
    {
      this.bv_tts.cancel()

    },

    methods: 
    {
      onTTSend() {
        console.debug("simon says: show this at end of tts")
      },
      checkPreviousTopResult()
      {
        const _this = this


        getUserDataDir('Top Results',function (err, result) {

          let top_result_data = result.val()

          if(top_result_data!=undefined && top_result_data!=null)
          {
            if('Simon Says' in top_result_data)
            {
              _this.previous_top = true

              if('top_result' in top_result_data['Simon Says'])
              {
                _this.top_result = top_result_data['Simon Says']['top_result']
              }
            }
            else
            {
              _this.previous_top = false
            }
          }
          else{
            _this.previous_top = false
          }
        })
      },
      updateTopLevel()
      {

        let uid = localStorage.getItem('uid');
        let ref = 'Users/' + uid + '/Top Results/Simon Says/'


        updateDataDirectory(ref, {'top_result':this.level})

      },
        start()
        {
          this.level += 1;
          this.bv_tts.cancel()
          this.nextRound()

        },
        repeat()
        {
          const nextSequence = [...this.simon_sequence];
          this.playRound(nextSequence);
          this.human_sequence = []
          setTimeout(() => {
              this.humanTurn(this.level);
          }, this.level * 600 + 1000);
        },
        nextRound() {
            // this.level += 1;

            // document.getElementById('colour_buttons').classList.add('unclickable')


            // tileContainer.classList.add('unclickable');
            this.starting = true;
            const nextSequence = [...this.simon_sequence];
            nextSequence.push(this.nextStep());
            this.playRound(nextSequence);

            this.simon_sequence = [...nextSequence];
            setTimeout(() => {
                this.humanTurn(this.level);
            }, this.level * 600 + 1000);
        },
        nextStep() {
            const tiles = ['red', 'green', 'blue', 'yellow'];
            const random = tiles[Math.floor(Math.random() * tiles.length)];

            return random;
        },
        playRound(nextSequence) {
          this.interaction_message = "Simon's turn"
          this.interaction_message_color = 'fontSize:x-large;color:blueviolet;white-space: pre-wrap'
          console.debug('simon round = '+this.simon_sequence)
          this.simon_turn=true

            nextSequence.forEach((color, index) => {
                setTimeout(() => {
                this.activateTile(color);

                if(index==nextSequence.length-1)
                {
                  this.simon_turn=false

                  setTimeout(() => {
                    this.bv_tts.say_sentences('Your turn')
                  },400)


                }
                }, (index + 1) * 690);
            });
        },
        activateTile(color) {
            const tile = document.querySelector(`[data-tile='${color}']`);
            const sound = document.querySelector(`[data-sound='${color}']`);

            tile.classList.add('activated');
            sound.play();

            setTimeout(() => {
                tile.classList.remove('activated');
            }, 300);
        },
        humanTurn(level)
        {
            document.getElementById('colour_buttons').classList.remove('unclickable')
            this.interaction_message = `Your turn: ${level} click${level > 1 ? 's' : ''}`;
            this.interaction_message_color = 'fontSize:x-large;color:blueviolet;white-space: pre-wrap'  

        },
        handleClick(tile) {

          if(this.simon_turn)
          {
            return
          }
          console.debug('tile= '+tile)
            const index = this.human_sequence.push(tile) - 1;
            const sound = document.querySelector(`[data-sound='${tile}']`);
            sound.play();
            console.debug('simon sequence = '+this.simon_sequence)

            const remainingTaps = this.simon_sequence.length - this.human_sequence.length;

            console.debug('simon sequence [index]= '+this.simon_sequence[index]+' human = '+this.human_sequence[index])

            if (this.human_sequence[index] !== this.simon_sequence[index]) {
                if(this.level>this.top_result)
                {
                  let level = this.level-1
                  this.interaction_message = "Oops, wrong click. Press Repeat to try again. Your highest level is " + level + "."

                }
                else
                {
                  this.interaction_message = "Oops, wrong click.  Press Repeat and try again."

                }
                this.bv_tts.say(this.interaction_message, this.onTTSend)

                this.interaction_message_color = 'fontSize:x-large;color:red;white-space: pre-wrap'  
                return
                // return resetGame('Oops! Game over, you pressed the wrong tile.');
            }

            if (this.human_sequence.length === this.simon_sequence.length) {
                if (this.human_sequence.length === this.max_rounds) {
                    this.interaction_message = "You have finished all levels for this challenge. Congrats!"
                    this.bv_tts.say(this.interaction_message)

                    this.interaction_message_color = 'fontSize:x-large;color:blueviolet;white-space: pre-wrap'  
                    this.human_sequence = [];

                    return

                // return resetGame('Congrats, You Legend! You completed all the levels');
                }
                let correct = true
                for(let i = 0;i<this.human_sequence.length;i++)
                {
                  if(this.human_sequence[i] == this.simon_sequence[i])
                  {
                    correct = correct && true
                  }
                  else
                  {
                    correct = correct && false
                  }
                }
                if(correct)
                {
                  this.human_sequence = [];
                  this.interaction_message = "Congrats! You got it. Click start for the next level."
                  this.interaction_message_color = 'fontSize:x-large;color:green;white-space: pre-wrap'  

                        
                  if(this.level+1>this.top_result)
                  {
                    this.updateTopLevel()
                  }



                  if((this.level+1)%5==0)
                  {
                    let temp_level = this.level+1

                    this.interaction_message = 'You got to level ' + temp_level, "Let's see how far you can go"

                    this.bv_tts.say_sentences(['You got to level ' + temp_level, "Let's see how far you can go"])

                  }
     
                  
                  // //this.$forceUpdate()

                }
                
                // setTimeout(() => {
                // this.nextRound();
                // }, 1000);
                this.starting = false;
                return;
            }

        }


       
    }
  }
  </script>
  <style scoped>
  .custom-button-BV
  {
      width: 130px;
      height: 130px;
      border-radius: 100px;
  }
  li:hover {
    background-color: #e0e0e2;
    color: white;
  }
  .keyboard-button
  {
    width: 20mm;
    height: 20mm;
    font-size: xx-large;
    margin-left: 5mm;
  }
  .maths-button
  {
    width: 50mm;
    height: 25mm;
    font-size: xx-large;
    margin-left: 1mm;
    background-color: white;
    border-color: blueviolet;
  }
  .details
  {
    font-size: 24px;
  }

  
.tile-container {
  display: grid;
  grid-template-rows: 180px 180px;
  grid-template-columns: 180px 180px;
  grid-gap: 25px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;
}

.unclickable {
  pointer-events: none;
}

.tile-red {
  background-color: #f25022;
  box-shadow: 0 0 0 1px #f25022 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #c93a12, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-red:hover,
.tile-red:focus {
  background-color: #ff451a;
  box-shadow: 0 0 0 1px #FF3000 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #e62b00, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-red:active,
.tile-red.activated {
  box-shadow: 0 0 0 1px #ff5c36 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #ff5c36;
  transform: translateY(10px);
}

.tile-magenta {
  background-color: #FB196C;
  box-shadow: 0 0 0 1px #FB196C inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #A4023E, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-magenta:hover,
.tile-magenta:focus {
  background-color: #D41159;
  box-shadow: 0 0 0 1px #A0023C inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #D80151, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-magenta:active,
.tile-magenta.activated {
  box-shadow: 0 0 0 1px #fc689e inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #fc689e;
  transform: translateY(10px);
}
.tile-green {
  background-color: #7fba00;
  box-shadow: 0 0 0 1px #7fba00 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #638f05, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-green:hover,
.tile-green:focus {
  background-color: #8cc218;
  box-shadow: 0 0 0 1px #8cc218 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #74a802, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6)
}

.tile-green:active,
.tile-green.activated {
  box-shadow: 0 0 0 1px #a0ea00 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #a0ea00;
  transform: translateY(10px);
}

.tile-teal {
  background-color: #64F3DB;
  box-shadow: 0 0 0 1px #64F3DB inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #0DA48B, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-teal:hover,
.tile-teal:focus {
  background-color: #17c6a8;
  box-shadow: 0 0 0 1px #17c6a8 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #02BFA0, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6)
}

.tile-teal:active,
.tile-teal.activated {
  box-shadow: 0 0 0 1px #04E8C2 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #04E8C2;
  transform: translateY(10px);
}
.tile-yellow {
  background-color: #ffb900;
  box-shadow: 0 0 0 1px #ffb900 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #c28e07, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-yellow:hover,
.tile-yellow:focus {
  background-color: #ffb900;
  box-shadow: 0 0 0 1px #ffb900 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #c28e07, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.tile-yellow:active,
.tile-yellow.activated {
  box-shadow: 0 0 0 1px #ffca3d inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #ffca3d;
  transform: translateY(10px);
}

.tile-orange {
  background-color: #FFB000;
  box-shadow: 0 0 0 1px #FFB000 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #966802, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-orange:hover,
.tile-orange:focus {
  background-color: #af7801;
  box-shadow: 0 0 0 1px #af7801 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #c28e07, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.tile-orange:active,
.tile-orange.activated {
  box-shadow: 0 0 0 1px #fbc550 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #fbc550;
  transform: translateY(10px);
}
.tile-blue {
  background-color: #00a4ef;
  box-shadow: 0 0 0 1px #00a4ef inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #0883bc, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-blue:hover,
.tile-blue:focus {
  background-color: #139ddd;
  box-shadow: 0 0 0 1px #139ddd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #0c7cb0, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-blue:active,
.tile-blue.activated {
  box-shadow: 0 0 0 1px #2bbcff inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #2bbcff;
  transform: translateY(10px);
}

.tile-blue-cb {
  background-color: #0C7BDC;
  box-shadow: 0 0 0 1px #0C7BDC inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #155B98, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.15s;
}

.tile-blue:hover,
.tile-blue:focus {
  background-color: #025EAF;
  box-shadow: 0 0 0 1px #025EAF inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 10px 0 0 #0c7cb0, 0 10px 0 1px rgba(0, 0, 0, 0.4), 0 10px 8px 1px rgba(0, 0, 0, 0.6);
}

.tile-blue:active,
.tile-blue.activated {
  box-shadow: 0 0 0 1px #69B7FB inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 0 0 1px rgba(0, 0, 0, 0.4);
  background-color: #69B7FB;
  transform: translateY(10px);
}

  </style>