<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row" v-for="(line,lineNumber) of Ex_Description_Split" v-bind:key="lineNumber" style="margin-top:1%"> 
          <p><b style="fontSize:20px"> {{line}} </b></p>
        </div>
        <div class="row">
          <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->
        </div>
        <div class="row">
          <div class="card card-body" v-if="Ex_Text!==''">
            <div class="row mt-2" v-for="(line,lineNumber) of Ex_Text_Split" v-bind:key="lineNumber">
              <p class="h2">{{ line }}</p>             
            </div>
            <div class="row justify-content-center" v-if="tts_enabled">
              <img :src="audioImg" style="width:65px;cursor: pointer;" @click="bv_tts.say_sentences(Ex_Text)">
              <img v-if="url2image !== ''" id="img_big" v-bind:src="url2image" width="320" height="180" style="object-fit: scale-down" >
            </div>
          </div>
        </div>

        <div class="modal fade" id="demo_video_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Therapist demo</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row justify-content-around">
                  <div class="col-md-10">
                    <div class="card card-body h-100">
                      <div class="row" id="video_demo_instructions_div">
                        <div id="video_demo_placeholder"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="card card-body h-100">
                      <p class="lead">Demo {{demo_video_index+1}} of {{demo_video_pool.length}}</p>
                      <button v-if="demo_video_index<(demo_video_pool.length-1)" class="btn btn-info w-100 mt-2" @click="nextDemoVideo()" >Next <i class="bi bi-skip-forward" style="margin-left: 4mm;"></i> </button>
                      <button class="btn btn-secondary w-100 mt-2"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4"> <!--graphs / indicators-->
          <div class="row" v-if="hasVideoInstructions || hasAudioInstructions" id="video_instructions_div">
            <div id="video_placeholder"></div>
          </div>
        
          <div class="row justify-content-center" v-if="!(hasSPLMeter||hasSpectrogram||show_countdown)" id="meter-row" >
            <div class="col-6">
              <canvas id="meter" height="30"></canvas>
            </div>
          </div>

          <Transition name="slide-fade">
            <!-- doesn't seem to work but will come back to it https://vuejs.org/guide/built-ins/transition.html#css-based-transitions -->
            <div id="SPL-div" v-show="!hasSPLMeter" class="row align-items-center">
              <div class="row" v-if="show_countdown && show_countdown!=false">
                <div class="col-md-6">
                  <div class="row">
                    <p class="h4" style="color:#228B22;white-space: pre-wrap">Good job!</p>  
                  </div>
                  <div class="row">
                    <p class="h5" style="color:#228B22;white-space: pre-wrap">Get ready for the next exercise. </p>
                  </div>
                </div>        
                <div class="col-md-6">
                  <div class="row text-center">
                    <p class="fs-4">{{timerCount}}</p>
                  </div>
                  <div class="row">
                    <p>Time left</p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>

          <div id="Spect-div" v-show="hasSpectrogram">
            <div class="row" v-show="play_status">
              <div class="col-md-6">
                <canvas style="margin-top:-1%"  ref="spect_canvas" id="spect_canvas" width="400" height="250"></canvas> 
                <p>Your speech frequency graph</p>
              </div>

              <div class="col-md-6">
                <div>
                  {{timerCount}}
                </div>
                <div class="row">
                  <p>Time left</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100" 
          :current_exercise_index = "current_exercise_index" 
          :total_exercises = "total_exercises"
          :has_demo_video = "demo_video_pool && demo_video_pool.length>0"
          :mic_on = "is_recording"
          :audioCaptureOn = "true"
          @next = "noAudioCaptureNext()"
          @started = "start_stop()"
          @stopped = "start_stop()"
          @toggled_background_noise = ""
          @toggled_demo_video = "gotoVideoDemo()"
          >
        </Sidebar>
      </template>
    </Layout>

  </div>
</template>
  
<script>
  import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
  import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
  import audioImg from '@/assets/images/audio_logo.png'
  import smoothie from 'smoothie'
  import SpeechRec from '@/media_input/SpeechRecognition'

  import { checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia } from '@/media_input/microphone_recording'
  import { removeWeirdCharactersFromString } from '@/media_input/utils'
  import { updateDataDirectory } from '@/firebase/index'
  import { LevenshteinDistance } from '@/utils/text_distance_metrics'
  import { generate_results_path } from '@/utils/results/data_save_utils'
  import { logActivity } from '@/media_input/utils'
  import { useTTS } from '@/stores/tts'
  import { Modal } from 'bootstrap'


  /*
  {
   "Countdown_Number":"3",
   "Countdown_Timer":"10",
   "exercise_type":"single_word",
   "instruct":"Press Start. Read the following words with a loud and clear voice and speech. Press Next.",
   "name":"Good morning",
   "speechtype":[
      "sentencegibility"
   ],
   "text":"Good morning",
   "voicetype":[
      "spl"
   ],
   "plan_name":"Functional phrases",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Functional phrases/Ex1",
   "ex_path":"Functional phrases/Ex1",
   "today":"2023-07-11 16:53",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"single_word",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Text":"Good morning",
   "Ex_Description":"Press Start. Read the following words with a loud and clear voice and speech. Press Next.",
   "module_name":"Functional phrases",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}


{
   "Description":"",
   "Title":"Ashley Young due for Everton medical after leaving Aston Villa as free agent",
   "Type":"article",
   "Url":"https://www.theguardian.com/football/2023/jul/11/ashley-young-everton-medical-aston-villa-free-agent-sean-dyche-signing",
   "Urltoimage":"https://i.guim.co.uk/img/media/96d32595866772d9c971305bf993cac7a8018f90/199_151_3239_1944/master/3239.jpg?width=465&dpr=1&s=none",
   "assist":[
      "spm_live"
   ],
   "exercise_type":"basic_module",
   "instruct":"Press Start. Read the following news with a loud and clear voice and  speech. Press Next.",
   "name":"Ashley Young due for Everton medical after leaving Aston Villa as free agent",
   "speechtype":[
      "sentencegibility"
   ],
   "text":"Ashley Young due for Everton medical after leaving Aston Villa as free agent. \n ",
   "plan_name":"News headline",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"News headline/2",
   "ex_path":"News headline/2",
   "today":"2023-07-11 16:54",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"basic_module",
   "url":"https://www.theguardian.com/football/2023/jul/11/ashley-young-everton-medical-aston-villa-free-agent-sean-dyche-signing",
   "url2image":"https://i.guim.co.uk/img/media/96d32595866772d9c971305bf993cac7a8018f90/199_151_3239_1944/master/3239.jpg?width=465&dpr=1&s=none",
   "audioIns":null,
   "Ex_Text":"Ashley Young due for Everton medical after leaving Aston Villa as free agent. \n ",
   "Ex_Description":"Press Start. Read the following news with a loud and clear voice and  speech. Press Next.",
   "module_name":"News headline",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/

  export default {
    name: 'single_word_basic',
    props: {
      exForm: {
        type: Object,
      },
    },
    components: { Layout, Sidebar },
    data() {
      return {
        demo_video_pool:[],
        demo_video_index:0,
        currentSentence:'',
        started_speech_rec:false,
        show_countdown: false,
        countdown_timeout:null,
        start_timer_count:4,
        timerCount: 4,
        hasVideoInstructions: false,
        hasAudioInstructions: false,
        video_instructions_address: '',
        audio_instructions_address: '',
        audioImg: audioImg,
        tiny_text:false,
        big_text: true,
        sound_l:'',
        sound_l2:'',
        chart_data: null,
        chart_data_spect: null,
        smoothie_spect: null,
        start_btn_text: 'Start',
        smoothie: null,
        drawInterval: null,
        Ex_Text: '',    
        Ex_Description: [],
        ex_name: '',
        avg_loudness: 0,
        max_loudness:0,
        avg_loudness_show: 0,
        hasSPLMeter: false,
        last_loudness: 0,
        total_loudness: 0,
        counter_loudness: 0,
        audioCaptureOn: true,
        hasSpectrogram: false,
        chart_not_created: true,

        chart_not_created_spect: true,
        canvasContext: null,
        tts_enabled: true,
        play_pause_text: '`Record`',
        play_status: true,
        current_exercise_index: 0,
        total_exercises: 1,
        
        modules_results_path: null,
        resultsTableBack: [],
        has_results: false,
        is_recording: false,
        url:'',
        url2image:'',
        loud_target:65,
        client_number: null,

        region: localStorage.getItem('region'),
        userCategory: localStorage.getItem('userCategory'),
        uid: localStorage.getItem('uid'),
        bv_tts: useTTS().tts_engine,
      }
    },
    watch: {
      show_countdown(value) {
        if (value == true) {
            this.countdown_timeout = setTimeout(() => {
                this.timerCount--;
            }, 1000);
        } else {
          this.timerCount = this.start_timer_count
        }
      },
      timerCount: {
        handler(value) {

          if (value > 0 && this.show_countdown) {
              setTimeout(() => {
                  this.timerCount--;
              }, 1000);
          } else if (value == 0 && this.show_countdown) {
            this.endCountdown()
          }

        },
        immediate: true // This ensures the watcher is triggered upon creation
      }
    },
    created() {
      let _this = this;
      
      checkPermissionsMedia(); 
      _this.nextExercise();

      logActivity( 'accessed', 'single_word_exercise')

      if(!this.iOS()) {
        this.speech_rec = new SpeechRec(function(captions) {
          this.currentSentence = captions.original
        }.bind(this))
      }

      if(this.userCategory=='slp') {
        let client_number = localStorage.getItem('client_uid');

        if(client_number!='undefined' && client_number!=null && client_number!='') {
          _this.client_number = client_number; 
        }
      }
      
    },
    computed: {

      currentSentence_found: function () {

      const _this = this

      let best_answer = ''
      let found_something = false

      if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started_speech_rec) {

        let curr_sentence = this.currentSentence.toString().toLowerCase()

        var regex = /[.,]/g;

        curr_sentence = curr_sentence.replace(regex, '');

        curr_sentence = curr_sentence.split(' ')

        console.debug('this.Ex_Text_Split.length = ' + this.Ex_Text_Split.length)

        let target_words = _this.Ex_Text_Split[this.Ex_Text_Split.length-1]
        console.debug('target_words1 = ' + target_words)

        let chosen_word = ''

        chosen_word = target_words.split(' ')
        console.debug('chosen_word = ' + chosen_word)
      
        if(chosen_word.length==1) {
          //single word exercise, will recognise any word 
          this.stop_recording()
        } else {

          if(chosen_word.length>1) {
            chosen_word = chosen_word[chosen_word.length-1]
          }
        
          target_words = [chosen_word]

          console.debug('curr_sentence = ' + curr_sentence)
          console.debug('target_words = ' + target_words)

          for(let k in curr_sentence) {
            let word = curr_sentence[k]
            
            let minimum_distance = 100

            for(let i in target_words) {

              let distance = LevenshteinDistance(target_words[i].toString(),word.toString())

              distance = distance/Math.max(target_words[i].length,1)

              console.debug('distance = ' + distance)
              console.debug('target_words[i] = ' + target_words[i])
              
              console.debug('word = ' + word)

              if(distance<minimum_distance && distance<0.35)
              {
                console.debug('Found answer!!')
                best_answer = target_words[i]
                this.stop_recording()
                found_something = true
              }
            }
          }

        }
      }

      return ''

    },
    Ex_Description_Split: function () {
      return this.Ex_Description.toString().split('\n')
    },

    Ex_Text_Split: function()
    {

      console.debug('this.Ex_Text = ' + this.Ex_Text)

      let split_text =  this.Ex_Text.toString().split('\n')

      console.debug('split_text = ' + split_text)

      console.debug('split_text.length = ' + split_text.length)
      
      //  let total_characters = 0

      //   for(let n in split_text)
      //   {
      //     total_characters = split_text[n].length + total_characters
      //   }

      //   console.debug('total characters = ' + total_characters)
      //   console.debug('split_text = ' + split_text.length)

      if(split_text.length<=1)
      {
        this.big_text = false
      } else {
        this.big_text = true
      }
      console.debug('this.big_text =  ' + this.big_text)
      return split_text
    },

    },
    beforeUnmount() {
        
      const _this = this
      this.bv_tts.cancel()

      clearInterval(window.refreshIntervalId)
      
      if(_this.speech_rec!=null) {
        _this.speech_rec.stop()
      }

      if(_this.is_recording) {
        _this.endRecordingAudio(true);
      } else {
        _this.endRecordingAudio(false);
      }

      deleteSoundMeter()
    },
    mounted() {
      const _this = this
    },
    methods: 
    {
      renderGraphs()
      {
        const _this = this
            
        if(_this.hasSPLMeter) {
          _this.createChart()
        }

        if(_this.hasSpectrogram) {
          _this.createChartSpect()
        }
      },
      nextExercise()
      {
        const _this = this;
        
        if(_this.is_recording) {
          //_this.endRecordingAudio(true)
        }

        _this.pause();
        _this.loadSingleExercise()  
      },
      stop_recording()
      {
        const _this = this
        _this.show_countdown = true        
      },
      repeatExercise()
      {
        const _this = this

        this.currentSentence = ''

        this.speech_rec.stop()

        this.speech_rec = null

        _this.timerCount = this.start_timer_count
        _this.repeat_exercise = true

        clearTimeout(this.countdown_timeout)

        _this.show_countdown = false

        if(_this.play_status) {

          console.debug('_this.avg_loudness = ' + _this.avg_loudness)

          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0) {
            avg_loudness_array.push(_this.avg_loudness)
          }

          console.debug('avg_loudness_array = ' + avg_loudness_array)

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;


          if(_this.is_recording) {
            _this.is_recording = false
            _this.endRecordingAudio(true)
          }
          _this.max_loudness = 0

          let resultsTableBack = []
          for(let i in _this.resultsTableBack) {
            resultsTableBack.push(_this.resultsTableBack[i])
          }

          var basicData = {resultsTableBack: resultsTableBack, avg_loudness_array: avg_loudness_array}

          _this.resultsTableBack = []
          _this.$emit('logExerciseResults', basicData);

          _this.play_status = false

          _this.play_pause_text = _this.start_btn_text
        }

        this.currentSentence = ''

        this.speech_rec = new SpeechRec(function(captions) {
            this.currentSentence = captions.original
        }.bind(this))
        
      },
      loadSingleExercise()
      {           
        const _this = this

      
        _this.Ex_Text = _this.exForm.text; 
      
        _this.demo_video_pool = _this.exForm.demo_video_pool

        _this.tts_enabled = true

        if(_this.Ex_Text==undefined || _this.Ex_Text==null)
        {
          _this.tts_enabled = false
        }
        else
        {
          let list_tts_fail_words = ['phoneme','/ah/','/eh/','/ih/','/oh/','/uh/']

          let ex_text_lower = _this.Ex_Text
          ex_text_lower = ex_text_lower.toLowerCase()

          for(let i in list_tts_fail_words)
          {
            if(ex_text_lower.includes(list_tts_fail_words[i]))
            {
              _this.tts_enabled = false
              break
            }
          }
        }

        if(_this.exForm.image_address!=undefined && _this.exForm.image_address!=null && _this.exForm.image_address!='')
        {
          _this.showimage(_this.exForm.image_address)
        }

        _this.Ex_Description = _this.exForm.instruct;

        _this.current_exercise_index = _this.exForm.current_exercise_index + 1
        
        _this.has_results = _this.exForm.has_results
        _this.total_exercises = _this.exForm.total_all_type_exercises

        let url = _this.exForm.url
        let url2image = _this.exForm.url2image

        if(url!=undefined && url!=null)
        {
          _this.url = url
        }

        if(url2image!=undefined && url2image!=null)
        {
          _this.url2image = url2image
        }


        if(_this.exForm.start_btn_text!=undefined && _this.exForm.start_btn_text!=null)
        {
          _this.start_btn_text = _this.exForm.start_btn_text
          _this.play_pause_text = _this.start_btn_text
        }

        _this.Ex_Description = removeWeirdCharactersFromString(_this.Ex_Description)
        
        _this.Ex_Text = removeWeirdCharactersFromString(_this.Ex_Text)

        let ex_text_split = _this.Ex_Text.split(' ')

        if(ex_text_split!=undefined && ex_text_split!=null)
        {
          _this.tiny_text = ex_text_split.length<=22
        }

        if(_this.exForm.search_img!=undefined && _this.exForm.search_img!=null && _this.exForm.search_img!='')
        {
          _this.imgSearchWord(_this.Ex_Text)
        }

        if(_this.exForm.sound_autoplay!=undefined && _this.exForm.sound_autoplay!=null && _this.exForm.sound_autoplay==true)
        {
          // setTimeout(() => {
          //     _this.bv_tts.say_sentences(_this.Ex_Text)
          // }, 750)
        }      

        let captureAudio = _this.exForm.capture_audio

        if(captureAudio != undefined && captureAudio != null)
        {
          _this.audioCaptureOn = captureAudio
        }

        if(_this.Ex_Text == undefined || _this.Ex_Text == null )
        {
          _this.Ex_Text = ""
        }
        if(_this.Ex_Description == undefined || _this.Ex_Description == null )
        {
          _this.Ex_Description = ""
        }
  
        _this.checkAudioVideoInstructions()
        _this.checkAssistance()

      },
      imgSearchWord(word)
      {
        const _this = this
      
        let CSE_ID = '0a00ce12c9207f97c'
        let API_KEY = 'AIzaSyAyaTunYyJEdu_QbzvBN_xMJYmRDMWB_sc'

        const Http = new XMLHttpRequest();
        const url='https://customsearch.googleapis.com/customsearch/v1?cx='+CSE_ID+'&key='+API_KEY+'&q='+word+'&searchType=image&imgType=clipart&page=1&num=1&fields=items(image/thumbnailLink)'
        //&imgType=clipart'
        console.debug(url)
        Http.open("GET", url);
        Http.send();

        Http.onreadystatechange = (e) => {

          let json_response = JSON.parse(Http.response)

          // console.debug(json_response['items'][0])

          if(json_response['items'].length>0)
          {
            _this.url2image = json_response['items'][0]['image']['thumbnailLink']
          }
        }

        // const imageSearch = require('image-search-google');

        // const client = new imageSearch(CSE_ID, API_KEY);

        // window.setImmediate = window.setTimeout
        // const options = {page:1,num:1};  

        // client.search(word, options).then(images => {

        //   if(images!=undefined && images!=null && images.length>0)
        //   {
        //     _this.url2image = images[0]['thumbnail']

        //   }


        //   /*
        //   [{
        //       'url': item.link,
        //       'thumbnail':item.image.thumbnailLink,
        //       'snippet':item.title,
        //       'context': item.image.contextLink
        //   }]
        //   */
        // }).catch(error => console.debug('error = ' + error));


      },
      checkAudioVideoInstructions()
      {
        const _this = this

        console.debug('_this.exForm.videoIns = ' + _this.exForm.videoIns)

        let hasVideoInstructions = _this.exForm.videoIns;

        if(hasVideoInstructions!=undefined && hasVideoInstructions!=null)
        {
          _this.hasVideoInstructions = hasVideoInstructions;
        } else {
          _this.hasVideoInstructions = false;

          if(_this.exForm.Video_Address!=undefined && _this.exForm.Video_Address!=null)
          {
            _this.hasVideoInstructions = true
            _this.exForm.videoAddress = _this.exForm.Video_Address
          }
        }

        // let hasAudioInstructions = _this.exForm.audioIns;

        let audioIns = _this.exForm.audioIns

        if(audioIns!=undefined & audioIns!=null)
        {
          _this.hasAudioInstructions = true;
        }
        else
        {
          _this.hasAudioInstructions = false;
        }

        if(_this.hasVideoInstructions)
        {
          _this.video_instructions_address = _this.exForm.videoAddress;

          console.debug('_this.video_instructions_address = ' + _this.video_instructions_address)
          _this.watchVideoInstructions()
        }
        else if(_this.hasAudioInstructions)
        {
          _this.audio_instructions_address = audioIns
          // _this.audio_instructions_address = _this.exForm.audioAddress;

          _this.watchVideoInstructions()
        }

      },
      watchVideoInstructions()
      {
        const _this = this;

        if(_this.is_recording)
        {
          _this.is_recording = false
          _this.endRecordingAudio(false);
          _this.play_pause_text = _this.start_btn_text
        }

        if(_this.hasVideoInstructions)
        {
          _this.is_recording = false;
      
          var storage = _this.$firebase.storage();
  
          storage.ref(_this.video_instructions_address).getDownloadURL()
            .then((url) => {
              _this.playVideoInstructions(url);

            })
            .catch((error) => {
              // Handle any errors
            });
        }
        else if(_this.hasAudioInstructions)
        {

          // document.getElementById('ex_div').hidden = true;

          // document.getElementById('mic_dist_div').hidden = true;

          // document.getElementById('video_instructions_div').hidden = false;

          stopRecordingMedia(null,null,null);
          
          // clearInterval(_this.drawInterval);
          clearInterval(window.refreshIntervalId);
  
          _this.drawLoop(0);

          _this.is_recording = false;
      
          var storage = _this.$firebase.storage();


          storage.ref(_this.audio_instructions_address).getDownloadURL()
            .then((url) => {
              _this.playAudioInstructions(url);

            })
            .catch((error) => {
              // Handle any errors
            });
        }
      },
      playVideoInstructions(url)
      {      
        console.debug('url = ' + url)
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="380px" width="600px" controls autoplay> </video>';
        var player =document.getElementById('movie');
        player.load();
      },
      checkAssistance()
      {
        const _this = this

        let assistance = _this.exForm.assist

        _this.hasSPLMeter = false;

        // if(assistance!=null)
        // {
        //   console.debug('assistance = ' + assistance)
          
        //   if(Array.isArray(assistance) && assistance.includes('spm_live'))
        //   {
        //       _this.hasSPLMeter = true;


        //   }
        //   else if('spm_live' in assistance)
        //   {
        //       _this.hasSPLMeter = true;

        //   }

        //   if(Array.isArray(assistance) && assistance.includes('spl_live'))
        //   {
        //       _this.hasSPLMeter = true;


        //   }
        //   else if('spl_live' in assistance)
        //   {
        //       _this.hasSPLMeter = true;

        //   }

        //   if(Array.isArray(assistance) && assistance.includes('spectrogram'))
        //   {
        //       _this.hasSpectrogram = true;


        //   }
        //   else if('spl_live' in assistance)
        //   {
        //       _this.hasSpectrogram = true;

        //   }



        // }

        // _this.hasSpectrogram = true

      },
      runTimer() {

        let interval_time = 250;

        const _this = this;

        clearInterval(window.refreshIntervalId)

        window.refreshIntervalId = setInterval(function () {

        console.debug('_this.show_countdown = ' + _this.show_countdown)

          if (soundMeter != null) {

              // if(element!=null)
              {
  
                // var sound_l = soundMeter.instant.toFixed(2) ;
                var sound_l = soundMeter.db.toFixed(2);
                _this.sound_l2 = soundMeter.db2.toFixed(2);
                var max_freq_index = soundMeter.max_freq_index
  
                if(max_freq_index==undefined || max_freq_index==null)
                {
                  max_freq_index = 0
                }
                
                max_freq_index = max_freq_index.toFixed(2);
  
                // let sound_calibration = 20;
                let sound_threshold = 40;
                
                // console.debug('sound_l = ' + sound_l)
                if(sound_l>sound_threshold)
                {                  
                  _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
                  
                  _this.avg_loudness_show = _this.roundNumber(_this.avg_loudness,0)

                  _this.max_loudness = Math.max(_this.max_loudness,sound_l)
  
                //   console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)
                _this.counter_loudness += 1;
              }

              const min_spect_loudness = 45

              if(_this.hasSpectrogram && sound_l>min_spect_loudness)
              {
                _this.addDataChartSpect(max_freq_index)
              }

              if(!(_this.hasSPLMeter||_this.hasSpectrogram))
              {
                let element = document.getElementById("meter")
                if(element!=undefined && element!=null)
                {
                  _this.canvasContext = element.getContext("2d");
                  _this.drawLoop(sound_l);
                }
              }

              if(_this.hasSPLMeter)
              {
                // _this.addDataChart(max_freq_index)
                _this.addDataChart(sound_l)
              }
            }
          }
        }, interval_time);
      

      },
      roundNumber(number,number_of_decimals) {   
        let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)
        return rounded_number;
      },
      showimage(img_address) {

        const _this = this
        var storageRef = _this.$firebase.storage().ref();
        storageRef.child(img_address).getDownloadURL().then(function(url) {
            _this.url2image = url
            // var test = url;
            // document.getElementById('img_naming').src = test;

        }).catch(function(error) {

        });
      },
      skipExercise()
      {
        const _this = this

        _this.$emit('nextExercise');

      },
      noAudioCaptureNext()
      {
        const _this = this;
        _this.is_recording = false  
        let avg_loudness_array = []

        var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}
        _this.$emit('logExerciseResults', basicData);
        _this.$emit('nextExercise');
      },  
      goToResults() {
        const _this = this

        var goToResultsData = {curr_ex_done:false}

        if(_this.play_status)
        {
          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0)
          {
            avg_loudness_array.push(_this.avg_loudness)
          }

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;

          if(_this.is_recording)
          {
            _this.is_recording = false

            _this.endRecordingAudio(true)
          }
          _this.max_loudness = 0

          var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}
          goToResultsData.curr_ex_done = true

          _this.$emit('logExerciseResults', basicData);
        }
        _this.$emit('goToResults', goToResultsData);

      },
      endCountdown() {
        const _this = this

        if(_this.repeat_exercise) {
          _this.repeat_exercise = false
          _this.show_countdown = false
          _this.timerCount = this.start_timer_count
        }
        
        _this.start_stop()
          
      },
      start_stop() { 
        const _this = this;
        
        if(_this.play_status) {

          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0) {
            avg_loudness_array.push(_this.avg_loudness)
          }

          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;

          if(_this.is_recording) {
            _this.is_recording = false
            _this.endRecordingAudio(true)
          }

          _this.max_loudness = 0

          let basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}
          _this.$emit('logExerciseResults', basicData)
          _this.$emit('nextExercise');
        } else {
          _this.play_status = true;
          _this.play_pause_text = 'Next'
          
          _this.resumeRecording()

          // if(_this.hasSPLMeter)
          {    
            _this.runTimer()
          }
        }
      },
      iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      },
      resumeRecording() {
        let _this = this;
      
        // resumeRecordingMedia();
        if(!_this.is_recording) {
          console.debug('this.Ex_Text_Split[0] = ' + this.Ex_Text_Split[0])

          if(!this.iOS()) {
            this.speech_rec.start(this.Ex_Text_Split[0],false,this.region)
          }

          this.started_speech_rec = true

          recordAudioMedia()
          _this.is_recording = true;
        }
      },
      pauseRecordingAudio() {
        const _this = this;

        if(_this.is_recording) {
          stopRecordingMedia(null,null,null);
          _this.is_recording = false;
        }  
      },
      endRecordingAudio(save_audio) {      
        const _this = this;
  
        if(save_audio) {
          console.debug('curr_exercise_path = ' + _this.exForm.curr_exercise_path)
          
          let results_addresses = generate_results_path(this.exForm)

          let random_identifier = results_addresses['random_identifier']

          let exercises_results_path = results_addresses['exercises_results_path']
          let modules_results_path = results_addresses['modules_results_path']

          let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;
          
          if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
            audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
          }

          var audio_address = 'audios/' + audio_folder + '.mp3';

          audio_address = audio_address.replaceAll('//','/')

          console.debug("Saving " + audio_address);
          
          let today = _this.exForm.today
          
          let voicetype = _this.exForm.voicetype
          let speechtype = _this.exForm.speechtype
        
          _this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')

          console.debug('exercises_results_path = ' + exercises_results_path)
          console.debug('assessment_type = ' + _this.exForm.assessment_type)

          updateDataDirectory(modules_results_path,{module_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})

          let name = _this.exForm.name
          if(name == undefined || name == null || name == '') {

            console.debug('curr_exercise_path = ' + _this.exForm.curr_exercise_path)
            
            let results_addresses = generate_results_path(this.exForm)

            let random_identifier = results_addresses['random_identifier']

            let exercises_results_path = results_addresses['exercises_results_path']
            let modules_results_path = results_addresses['modules_results_path']



            let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;
            
            
            if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
              audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
            }

            var audio_address = 'audios/' + audio_folder + '.mp3';

            audio_address = audio_address.replaceAll('//','/')


            console.debug("Saving " + audio_address);


            
            let today = _this.exForm.today
            
            let voicetype = _this.exForm.voicetype
            let speechtype = _this.exForm.speechtype
          
            _this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')

            console.debug('exercises_results_path = ' + exercises_results_path)
            console.debug('assessment_type = ' + _this.exForm.assessment_type)

            updateDataDirectory(modules_results_path, {module_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})

            let name = _this.exForm.name
            if(name == undefined || name == null || name == '')
            {
              name = _this.Ex_Text
            }

            let push_structure = {
              'date': today,
              'name': name,
              'path': exercises_results_path, 
              'audio_address': audio_address,
              'uid': _this.uid,
              'loudness': _this.last_loudness,
              'localisation': _this.region
            }

            let ground_truth = _this.Ex_Text

            if(ground_truth!=undefined && ground_truth!=null)
            {
              ground_truth = ground_truth.replaceAll('\n','')

            }
            else{
              ground_truth = ''
            }
            let has_analysis = false




            if(voicetype!=null && voicetype!=undefined)
            {
                has_analysis = true               

              if(Array.isArray(voicetype) && voicetype.includes('intelligibility'))
              {
              push_structure['intelligibility'] = ground_truth
              }
              else if('Intelligibility' in voicetype)
              {
              push_structure['intelligibility'] = ground_truth

              }

              if(Array.isArray(voicetype) && voicetype.includes('sentencegibility'))
              {

              push_structure['sentencegibility'] = ground_truth

              }
              else if('sentencegibility' in voicetype)
              {
              push_structure['sentencegibility'] = ground_truth
              }


              if(Array.isArray(voicetype) && voicetype.includes('max_phonation'))
              {
              push_structure['max_phonation'] = '1'
              }
              else if('max_phonation' in voicetype)
              {
              push_structure['max_phonation'] = '1'
              }


              if(Array.isArray(voicetype) && voicetype.includes('speech_rate'))
              {
                console.debug('speech_rate')
              push_structure['speech_rate'] = '1'
              }
              else if('speech_rate' in voicetype)
              {
              push_structure['speech_rate'] = '1'
              }

              if(Array.isArray(voicetype) && voicetype.includes('jitter_shimmer'))
              {
              push_structure['jitter_shimmer'] = '1'
              }
              else if('jitter_shimmer' in voicetype)
              {
              push_structure['jitter_shimmer'] = '1'
              }

              if(Array.isArray(voicetype) && voicetype.includes('f0'))
              {
              push_structure['f0'] = '1'
              }
              else if('f0' in voicetype)
              {
              push_structure['f0'] = '1'
              }
                    
            }


            if(speechtype!=null && speechtype!=undefined)
            {
              has_analysis = true

              if(Array.isArray(speechtype) && speechtype.includes('ddk_rate'))
              {
              push_structure['ddk_rate'] = '1'
              }

              if(Array.isArray(speechtype) && speechtype.includes('intelligibility'))
              {
              push_structure['intelligibility'] = ground_truth
              }
              else if('Intelligibility' in speechtype)
              {
              push_structure['intelligibility'] = ground_truth
              }


              if(Array.isArray(speechtype) && speechtype.includes('sentencegibility'))
              {
              push_structure['sentencegibility'] = ground_truth


              }
              else if('sentencegibility' in speechtype)
              {
              push_structure['sentencegibility'] = ground_truth


              }

              if(Array.isArray(speechtype) && speechtype.includes('speech_rate'))
              {
              push_structure['speech_rate'] = '1'
              }
              else if('speech_rate' in speechtype)
              {
              push_structure['speech_rate'] = '1'
              }

            }

            console.debug('hasanalysis = ' + has_analysis)

            console.debug('here!!')

            console.debug('push structure = ' + push_structure)

            for(let k in push_structure)
            {
              console.debug('push_structure['+k+'] = ' + push_structure[k])

            }



            if(has_analysis)
            {
              _this.resultsTableBack.push(exercises_results_path)
              _this.modules_results_path = modules_results_path
              console.debug('path = ' + modules_results_path)
              stopRecordingMedia(audio_address, push_structure, _this);

              // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
          
            }
            else
            {
              stopRecordingMedia(audio_address, null,null);

            }
              
          }

          let push_structure = {'date':today,'name': name,'path':exercises_results_path , 'audio_address':audio_address,'uid':_this.uid,'loudness':_this.last_loudness,'max_loudness':this.max_loudness}
          console.debug('today = ' + today)

          let ground_truth = _this.Ex_Text

          if(ground_truth!=undefined && ground_truth!=null)
          {
            ground_truth = ground_truth.replaceAll('\n','')
          }
          else{
            ground_truth = ''
          }
          let has_analysis = false

          if(voicetype!=null && voicetype!=undefined)
          {
            has_analysis = true               

            if(Array.isArray(voicetype) && voicetype.includes('intelligibility'))
            {
              push_structure['intelligibility'] = ground_truth
            }
            else if('Intelligibility' in voicetype)
            {
              push_structure['intelligibility'] = ground_truth
            }

            if(Array.isArray(voicetype) && voicetype.includes('sentencegibility'))
            {
              push_structure['sentencegibility'] = ground_truth
            }
            else if('sentencegibility' in voicetype)
            {
              push_structure['sentencegibility'] = ground_truth
            }

            if(Array.isArray(voicetype) && voicetype.includes('max_phonation'))
            {
              push_structure['max_phonation'] = '1'
            }
            else if('max_phonation' in voicetype)
            {
              push_structure['max_phonation'] = '1'
            }

            if(Array.isArray(voicetype) && voicetype.includes('speech_rate'))
            {
              console.debug('speech_rate')
              push_structure['speech_rate'] = '1'
            }
            else if('speech_rate' in voicetype)
            {
              push_structure['speech_rate'] = '1'
            }

            if(Array.isArray(voicetype) && voicetype.includes('jitter_shimmer'))
            {
              push_structure['jitter_shimmer'] = '1'
            }
            else if('jitter_shimmer' in voicetype)
            {
              push_structure['jitter_shimmer'] = '1'
            }

            if(Array.isArray(voicetype) && voicetype.includes('f0'))
            {
              push_structure['f0'] = '1'
            }
            else if('f0' in voicetype)
            {
              push_structure['f0'] = '1'
            }                    
          }

          if(speechtype!=null && speechtype!=undefined)
          {
            has_analysis = true

            if(Array.isArray(speechtype) && speechtype.includes('ddk_rate'))
            {
              push_structure['ddk_rate'] = '1'
            }

            if(Array.isArray(speechtype) && speechtype.includes('intelligibility'))
            {
              push_structure['intelligibility'] = ground_truth
            }
            else if('Intelligibility' in speechtype)
            {
              push_structure['intelligibility'] = ground_truth
            }


            if(Array.isArray(speechtype) && speechtype.includes('sentencegibility'))
            {
              push_structure['sentencegibility'] = ground_truth


            }
            else if('sentencegibility' in speechtype)
            {
              push_structure['sentencegibility'] = ground_truth
            }

            if(Array.isArray(speechtype) && speechtype.includes('speech_rate'))
            {
              push_structure['speech_rate'] = '1'
            }
            else if('speech_rate' in speechtype)
            {
              push_structure['speech_rate'] = '1'
            }

          }

          console.debug('hasanalysis = ' + has_analysis)

          console.debug('push structure = ' + push_structure)
          if(has_analysis)
          {
            _this.resultsTableBack.push(exercises_results_path)
            _this.modules_results_path = modules_results_path
            console.debug('path = ' + modules_results_path)
            stopRecordingMedia(audio_address, push_structure, _this);

            // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
          } else {
            stopRecordingMedia(audio_address, null,null);
          }  
        } else {
          stopRecordingMedia(null,null,null);
        }            

        this.is_recording = false;
      }, 
      createChart() {
        const _this = this
        
        if(_this.chart_not_created) {
          _this.chart_not_created = false
          console.debug('creating chart')
          // _this.smoothie = new SmoothieChart({millisPerPixel:11,labels:{fillStyle:'#000000', precision:0},grid:{fillStyle:'#dfd7d7',strokeStyle:'#e3abab'},maxValue:90,minValue:40})
          _this.smoothie = new smoothie.SmoothieChart({millisPerPixel:11,grid:{fillStyle:'#dfd7d7',verticalSections:2},labels:{fillStyle:'#000000',fontSize:15},horizontalLines:[{color:'#ffffff',lineWidth:1,value:0},{color:'#ff0000',lineWidth:2,value:_this.loud_target}],maxValue:90,minValue:40})
          var canvas = document.getElementById("meter")
          console.debug('canvas')
          _this.chart_data = new smoothie.TimeSeries();
          _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
          // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
          _this.smoothie.streamTo(canvas, 500);
        }
      },
      createChartSpect() {
        const _this = this
        
        if(_this.chart_not_created_spect)
        {
          _this.chart_not_created_spect = false
          console.debug('creating spec chart')  
  
          _this.smoothie_spect = new smoothie.SmoothieChart({millisPerPixel:11,labels:{fillStyle:'#000000',  precision:0},grid:{fillStyle:'rgba(40,40,154,0.60)',strokeStyle:'#ffffff', verticalSections:4},maxValue:500,minValue:50})
          var canvas = document.getElementById("spect_canvas")
          _this.chart_data_spect = new smoothie.TimeSeries();
  
          _this.smoothie_spect.addTimeSeries(this.chart_data_spect, {lineWidth:3.3,strokeStyle:'#00ff00'});
          _this.smoothie_spect.streamTo(canvas, 500);
        }
      },
      addDataChart(in_data) {
        const _this = this
        if(_this.smoothie!=undefined && _this.smoothie!= null)
        {
          _this.chart_data.append(new Date().getTime(),in_data)
        }
      },
      addDataChartSpect(in_data) {
        const _this = this
        if(_this.smoothie_spect!=undefined && _this.smoothie_spect!= null)
        {
          _this.chart_data_spect.append(new Date().getTime(),in_data)
        }
      },
      pause() {
        const _this = this;
        _this.play_status = false;
        _this.play_pause_text = _this.start_btn_text

        clearInterval(window.refreshIntervalId)
      },
      drawLoop(sound_l) {
        // clear the background
  
        var _this = this;
  
        var WIDTH = 400;//document.getElementById('meter').clientWidth;
        var HEIGHT = 30;//document.getElementById('meter').clientHeight;;
  
        var green_threshold = 50;
  
        // console.debug("Width = " + WIDTH);
        
        // console.debug("HEIGHT = " + HEIGHT);
  
        _this.sound_l = sound_l;
  
        if(_this.canvasContext != undefined && _this.canvasContext !=null)
        {
          _this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
        
          if (sound_l > green_threshold) {
            _this.canvasContext.fillStyle = "green";
          } else {
            // _this.silence_flag = true;  
            _this.canvasContext.fillStyle = "red";
          }
          _this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
          // _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);
        }
        // draw a bar based on the current volume  
      },  
      gotoVideoDemo() {

        let demo_video_pop = new Modal(document.getElementById('demo_video_pop'));
        demo_video_pop.show()

        this.demo_video_index = 0

        this.playDemoVideoInstructions()

        //to be programmed - for showing demo examples 1,2,3
      },
      nextDemoVideo() {
        this.demo_video_index = this.demo_video_index+1

        this.playDemoVideoInstructions()


      },
      playDemoVideoInstructions() {
        const _this = this

        let curr_video = this.demo_video_pool[this.demo_video_index]


        let demo_video_instructions_address = curr_video["Video_Address"]

        var storage = _this.$firebase.storage();


        storage.ref(demo_video_instructions_address).getDownloadURL()
          .then((video_url) => {
            document.getElementById('video_demo_placeholder').innerHTML='<video  id="movie_demo" src="'+video_url+'" height="380px" width="600px" controls autoplay> </video>';
            var player =document.getElementById('movie_demo');
            player.load();
            

            console.debug('here1')

            document.getElementById('demo_video_pop').addEventListener('hidden.bs.modal', function (event) {
              
              console.debug('here2')

              if(player!=undefined && player!=null)
              {
                if(!player.paused)
                {
                  player.pause();

                }
              }  
                // do something...
            })

  

          })
          .catch((error) => {
            // Handle any errors
          });          
      },
      playAudioInstructions(url) {
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="50px" width="400px" controls autoplay> </video>';
        var player =document.getElementById('movie');
        player.load();
      },
    }
  }
</script>
  
<style scoped>
  /*
    Enter and leave animations can use different
    durations and timing functions.
  */
  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }
  
  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
  
  .text {
    font-size: 14px;
  }

  .text_item {
    /* margin-bottom: 18px; */
    font-size: 30px;
    /* vertical-align: middle; */
    padding: 40px 0;
    /* height:80px; */
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 60%;
    margin:auto;
  }

  .demo-input-label {
    display: inline-block;
    width: 130px;
  }
</style>

<!-- Result Description
{
  "<plan_name?>":{
    "<key>": {
      "audio_address": "<storage_audio_address>",
      "loudness": {
        "loudness": "<int>"
      },
      "name": "<exercise_name>", // Back end analysis results (if they are requested):
      "sentencegibility": "<sentencegibility_dict_see_below>", // single words
      "intelligibility": "<intelligibility_dict_see_below>", //multi words
    },
    "assessment_type": "<USER_TRAINING/SLP_TRAINING/SLP_ASSESSMENT>",
    "plan_name": "<plan_name>"
  }
}

{
  "<sentencegibility_dict>":
  {
    "accuracy_score": "<int>",
    "audio_duration": "<float in sec>",
    "fluency_score": "<int>",
    "reference_text": "<target_text>",

    "Words":[
      // For each word recognised
      {
        "word":"<ground truth word>",
        "accuracy_score": "<int>",
        "error_type": "<string>",
        "phonemes": [
          // For each phoneme in the word
          {
            "accuracy_score":"<int>",
            "phoneme":"<using iso text>"
          }
        ]
      }

    ]

  }
}
 -->