<template>
<div>
  <div class="vh-100" style="background-color: #195e52; background-size:cover;">
    <div id="signIn_page" class="mb-4">
      <h1 class="display-1 pt-5 pb-4" style="text-align: center; color:#FFFFFF;">BeautifulVoice</h1>
      <div class="container mt-5">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="card card-body rounded shadow-lg border" style="background-color: #c8e3cf;">
                <div v-if="!show_message" class="m-3">
                  <div class="row mb-4">
                    <p class="lead">Please enter your email address below to reset your password</p>
                    <FormKit input-class="form-control" id="email" type="email" name="Email address" v-model="email" 
                          :sections-schema="{
                            messages: { $el: 'div' },
                            message: { $el: 'span' },
                            options: { $el: 'div' },
                            option: { $el: 'div' },
                          }"
                          messages-class="text-danger"
                        placeholder="Enter your email address" validation="required | email" />
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <button class="float-start btn btn-secondary mt-4" @click="$router.push({ name: 'signIn' })">Go Back</button>
                    </div>
                    <div class="col-7">
                      <button class="float-end btn btn btn-primary mt-4" @click="forgotPassword()">Reset Password</button>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <hr/>
                    <p class="">If you have any difficulty resetting your password, send us an email at <a href="mailto:contact@beautifulvoice.co.uk">contact@beautifulvoice.co.uk</a>. We will help you sort it out!</p>
                  </div>
                </div>
                <div v-else class="m-3">
                  
                  <div class="row mb-4">
                    <div class="col" v-if="message == null">
                      <p class="lead">An e-mail with a reset password link has been sent to  <span style="color: rebeccapurple;">{{ email }}</span>. Follow the instructions to create a new password for this account. Check your spam folder or junk folder if you cannot find this e-mail.</p>
                    </div>
                    <div class="col" v-else>
                      <p class="lead">{{ message }}</p>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-4">
                      <button class="btn btn btn-primary w-100" @click="$router.push({ name: 'signIn' })">OK</button>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <hr/>
                    <p class="">If you have any difficulty resetting your password, send us an email at <a href="mailto:contact@beautifulvoice.co.uk">contact@beautifulvoice.co.uk</a>. We will help you sort it out!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer style="position: fixed; bottom:0px; width: 100%"></Footer>
    </div>
  </div>
</div>
</template>

<script>
import { resetPassword, emailLogin } from '@/firebase/index'
import Footer from '@/views/Footer.vue'
import { useToast } from "vue-toastification";

export default {
  name: 'Forgot_password',
  components: { Footer },
  data() {
    return {
      email: '',
      show_message: false,
      message: null,
    }
  },
  created()
  {
   
  },
  methods: {

    forgotPassword()
    {
      const _this = this;

      let email = _this.email
      let is_email = validateEmail(email)

      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
      
      if(is_email)
      {
        resetPassword(_this.email)
        .then(() => {

          _this.show_message = true
        }).catch((error) => {
          let errorCode = error.code;
          
          if(errorCode == 'auth/user-not-found') {
            _this.message = 'This e-mail is not registered in BeautifulVoice yet. Click on register to create an account.'
          } else {
            _this.message = null
          }
          _this.show_message = true


        });
      } else {
        alert('Please enter your email address in the email field')
        
      }
      
    },
  },
}
</script>

<style scoped>
#signIn_page {
  background-image: url('@/assets/images/beautiful-voice-merge.svg');
  background-color: #195e52;
  background-size: contain;
  background-repeat: no-repeat;

  background-position: 100% 50%;
}
.enterBtn,
.enterBtn:focus,
.enterBtn:hover {
  background-color: #7cc543;
  font-size: 20px;
  border-radius: 10px;
  border-color: #7cc543;
  /* margin-top: 8px; */
}

</style>
