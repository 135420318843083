<template>
  <div class="row mt-2">

      

          <div class="col">
            <div class="row mt-2 mb-4" style="text-align: left;">
            <div class="col">
              <h4>FDA Total:</h4>
            </div>
            <div class="col">
              <h4>{{curr_fda['Score']['Total']['score'].toFixed(0)}}/{{curr_fda['Score']['Total']['total'].toFixed(0)}}</h4>

            </div>
          </div>
          
        </div>



        <div class="row">
          <div class="col">
            <div class="row mt-2" style="text-align: left;">

              
              <div class="col">


                <div class="mb-4">
                
 
                <!-- Grid Container with Label and Headers -->
                <div class="grid-container">
                  <!-- Vertical Label -->
                  <div class="vertical-label">

                    <div class="label-top">No func. ------------------------- Normal &nbsp; &nbsp; &nbsp;</div>

                  </div>

                  <div class="grid-area">
                    <!-- Domain Headers -->
                    <div
                      class="domain-header"
                      :style="{ gridTemplateColumns: `repeat(${curr_fda['Summary'].length}, 1fr)` }"
                    >
                      <div
                        v-for="(domain, index) in domainSpans"
                        :key="`domain-${index}`"
                        :style="{ gridColumn: `span ${domain.count}` }"
                        class="domain-cell"
                      >
                        {{ domain.name }}
                      </div>
                    </div>

                    <!-- Grid -->
                    <div
                      class="grid"
                      :style="{ 
                        gridTemplateColumns: `repeat(${curr_fda['Summary'].length}, 1fr)`,
                        gridTemplateRows: 'repeat(5, 40px)'
                      }"
                    >
                      <!-- Iterate over columns -->
                      <div
                        v-for="(item, colIndex) in curr_fda['Summary']"
                        :key="colIndex"
                      >
                        <!-- Iterate through rows in each column -->
                        <div
                          v-for="rowIndex in 5"
                          :key="`cell-${colIndex}-${rowIndex}`"
                          :class="[ 
                            'cell', 
                            { black: rowIndex > item.selection_index },
                            { divider: shouldHaveDivider(colIndex,curr_fda['Summary'].length) }
                          ]"
                          :style="{ gridColumn: colIndex + 1, gridRow: 6 - rowIndex }"
                        ></div>
                      </div>
                    </div>

                    <!-- Column Headers Below Grid -->
                    <div
                      class="header"
                      :style="{ gridTemplateColumns: `repeat(${curr_fda['Summary'].length}, 1fr)` }"
                    >
                      <div
                        v-for="(item, colIndex) in curr_fda['Summary']"
                        :key="`label-${colIndex}`"
                        class="label"
                      >
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>

            <br>
            <br>

            <hr style="height:2px;border:none;color:#333;background-color:green;" />

            <div class="mt-4">
            <div  class="row" style="text-align: left;">
              <p class="lead">FDA by domain:</p>

            </div>

            <div  class="col">
              
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Domain</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row, index in curr_fda['Score']['Domains']" v-bind:key="index" >
                  <td>{{row['Domain']}}</td>
                  <td>{{row['score']}}/{{ row['total'] }}</td>
                </tr>
               
               
              </tbody>
            </table>
          </div>
          </div>
        </div>
        </div>

      
    </div>
</template>

<script>
import { ref, onUpdated, computed, onMounted } from "vue";

export default {
  name: "single_fda",
  props: {
    curr_fda: {
      type: Object,
      default: null,
    },
  },
  methods: {
    // Method to determine if a divider should be applied to a column
    shouldHaveDivider(colIndex,size) {
      let columnCounter = 0;
      let totalColumns = 0;

      // Iterate over the domain spans and check if the columnIndex is after a domain boundary
      for (const domain of this.domainSpans) {
        columnCounter += domain.count;
        if ((colIndex >= totalColumns && colIndex < columnCounter - 1) || (colIndex >= (size-1))) {
          return false; // No divider within the domain boundary
        }
        totalColumns = columnCounter;
      }
      return true; // Place divider at domain boundaries
    },
  },
  setup(props) {
    // Compute spans for each domain
    const domainSpans = computed(() => {
      if (
        !props.curr_fda ||
        !props.curr_fda["Summary"]
      ) {
        return [];
      }
      const domainCounts = {};
      props.curr_fda["Summary"].forEach((item) => {
        let domain = item.Domain;
        if (item.Domain === "Respiration") {
          domain = "Resp.";
        } else if (item.Domain === "Intelligibility") {
          domain = "Intell.";
        }
        if (domainCounts[domain]) {
          domainCounts[domain]++;
        } else {
          domainCounts[domain] = 1;
        }
      });

      return Object.entries(domainCounts).map(([name, count]) => ({
        name,
        count,
      }));
    });

    return {
      domainSpans,
    };
  },
};
</script>

<style>
/* Grid Container with Vertical Label and Grid */
.grid-container {
  display: flex; /* Align the label and grid-area horizontally */
  align-items: flex-start; /* Align at the top */
  gap: 10px; /* Add space between the label and the grid */
}

/* Vertical Label Styling */
.vertical-label {
  display: flex;
  flex-direction: column; /* Stack "Normal Function" and "No Function" vertically */
  justify-content: space-between; /* Push "Normal Function" to the top, "No Function" to the bottom */
  text-align: center; /* Center-align the text */
  font-size: 12px; /* Adjust the font size */
  writing-mode: vertical-lr; /* Write text vertically */
  transform: rotate(180deg); /* Flip text for readability */
}

.vertical-label .label-top {
  font-weight: bold;
}

/* Grid Area for Header and Grid */
.grid-area {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Domain Header Styling */
.domain-header {
  display: grid;
  text-align: center;
  font-weight: bold;
  grid-template-rows: 40px;
}

/* Individual Domain Cells */
.domain-cell {
  background-color: #d9edf7;
  padding: 10px;
  border: 1px solid #ccc;
  border-right: 4px solid white;
}

/* Grid Structure */
.grid {
  display: grid;
  gap: 0;
  width: 100%;
}

/* Styling for each individual grid cell */
.cell {
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  height: 40px;
  width: 100%;
}

/* Conditionally applied dividers */
.cell.divider {
  border-right: 4px solid #ccc;
  border-right-color: lightblue;
}

/* Column Labels Below the Grid */
.header {
  display: grid;
  gap: 2px;
  text-align: center;
  grid-template-rows: 40px;
  margin-top: 10px;
}


/* Label style for column headers */
.label {
  display: flex;
  justify-content: right;
  align-items: center;
  writing-mode: vertical-rl; /* Vertical text */
  transform: rotate(180deg); /* Make the vertical text readable */
  font-size: 12px;
  white-space: nowrap; /* Prevent labels from wrapping */

}



/* Black cells */
.cell.black {
  background-color: black;
  color: white;
}
</style>
