<template>
  <div>
    <br/>
    <div class="row justify-content-center mt-4">
      
      <div class="col-5">
        <div class="row justify-content-center">
          <div class="col-7">
            <select class="form-select" v-model="selected_module_location">
              <option v-for="location in module_folder_locations" 
                v-bind:key="location" 
                :selected="location == selected_module_location" 
                :value="location">
                {{ location }}
              </option>
            </select>
          </div> 
        </div>
      </div>
    </div>
    <br/>

    <hr/>

    <div class="row mt-4 mb-4">
      <div class="col-4 border-3 border-success border-end">
        <ul class="list-group">
          <li v-for="(module_, index) in custom_modules_sorted" v-bind:key="index" :class="['list-group-item','mb-2', selected_module_name == module_.module_name? 'list-group-item-secondary':'']" @click="selectCustomModule(module_)"> 
            {{module_.module_name}}
          </li>
        </ul>
      </div>
      <div class="col-8">
        <div v-for="(module_, index) in custom_modules" :key="index" v-show="selected_module_name == module_.module_name">
          <p class="lead">Practise custom module</p>
        </div>
      </div>
    </div>

    <div class="row" v-if="selected_module_name != null">

      <div class="d-flex flex-row-reverse">
        <div class="">
          <button class="btn btn-outline-secondary me-2 rounded-pill" @click="modify_custom_module()" ><i class="bi bi-pencil"></i></button>
          <button class="btn btn-outline-danger me-2 rounded-pill" @click="delete_custom_module()"><i class="bi bi-trash"></i></button>
          <button class="btn btn-success me-2 rounded-pill" @click="add_to_assignment_list()" >Add to homework list</button>
          <button class="btn btn-outline-primary rounded-pill me-2" @click="start_clinic_module()">Start training</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getDataDirectoryCallback, removeDirectory} from '@/firebase/index'
import { ref, computed, watch } from 'vue'
import { useToast } from "vue-toastification";


export default {
  name: 'my_modules',
  props: {
    module_folder_locations: {
      type: Object,
      required: true
    },
    default_folder_location: {
      type: String,
      required: true
    }
  },
  emits: ['add_to_assignment_list', 'start_clinic_module', 'edit_module', 'updated'],
  setup(props, { emit }) {
    const selected_module_location = ref(props.default_folder_location)

    const custom_modules = ref([])

    const selected_module_name = ref(null)
    const selected_meta_module = ref(null)

    const uid = localStorage.getItem('uid')
    
    const selectCustomModule = (module) => {

      selected_module_name.value = module.module_name
      selected_meta_module.value = module
    }

    watch(selected_module_location, () => {
      getCustomModules()
    });

    const getCustomModules = () => {
      
      custom_modules.value = [];

      let user_modules_dir = 'Custom_Modules/' + uid + '/' + selected_module_location.value + '/'

      getDataDirectoryCallback(user_modules_dir, (err, categories_handle) => {

        let location = categories_handle.key
        categories_handle.forEach( (modules_type_handle) => {

          modules_type_handle.forEach( (module_handle) => {
            
            let module_name = module_handle.child('mod_name').val()

            let module_address = user_modules_dir + '/' + modules_type_handle.key + '/' + module_handle.key + '/'

            if(module_name != null) {
              custom_modules.value.push({ 
                module_handle: module_handle, 
                module_address: module_address, 
                module_name: module_name, 
                location: location,  //location is location
                type: modules_type_handle.key }) //type is exercise_type
            }
          });
          
        });
            
      });

    }

    const modify_custom_module = () => {
      emit('edit_module', selected_meta_module)
    }

    const delete_custom_module = () => {
      
      let user_modules_dir = selected_meta_module.value.module_address

      let exercises = selected_meta_module.value.module_handle.val().exercises
      
      if (exercises) {
        for (let exercise_index = 0; exercise_index < exercises.length; exercise_index++) {
          let exercise_address = exercises[exercise_index].address
          removeDirectory(exercise_address)
        }
      }

      removeDirectory(user_modules_dir)
      getCustomModules();

      emit('updated')

      const toast = useToast()
      toast.success("Custom module deleted", {timeout: 5000})
    }

    const custom_modules_sorted = computed(() => {
      let sorted = custom_modules.value.sort((a, b) => a.module_name.toLowerCase() > b.module_name.toLowerCase()?1:-1)
      return sorted
    });

    const add_to_assignment_list = () => {
      let module_address = selected_meta_module.value.module_address
      
      getDataDirectoryCallback(module_address, function (err, result) {
        emit('add_to_assignment_list', result)
      });
    }

    const start_clinic_module = () => {
      let module_address = selected_meta_module.value.module_address
      emit('start_clinic_module', selected_module_name.value, module_address)
    }

    getCustomModules();

    return {
      selected_module_name,
      selected_meta_module,

      custom_modules,

      custom_modules_sorted,

      selectCustomModule,
      modify_custom_module,
      delete_custom_module,

      add_to_assignment_list,
      start_clinic_module,
      selected_module_location,

    }
  }
}
</script>