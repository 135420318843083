<template>
<div>
  <Layout>
    <template v-slot:content>
      <div class="row">
        <div class="card card-body">
          <div class="row">
            <span class="read-text"> Click on the <b style="color:blue">{{item_name}}</b> <img :src="audioImg" style="width:35px;margin-left:1%;cursor: pointer;" @click="bv_tts.say(instruction_msg)" ></span>
          </div>
          <div class="row">
            <p class="h5" :style="interaction_color">
              {{interaction_msg}}
            </p>
          </div>
        </div>
      </div>
      
      <div class="parent">
        <span hidden>{{dummy}}</span>
        <img class="backimg" :src="backImg" :style="backImg_info.size" @click="interaction('back',0)">
        <img v-for="num in frontImg.length" :key="num" class="frontimg" :src="frontImg[num-1]" :style="frontImg_info[num-1].size" style="cursor: pointer;margin-left: 50%;" @click="interaction('front',num-1)">
      </div>
    </template>

    <template v-slot:sidebar>
      <Sidebar class="h-100"
        :current_exercise_index="current_exercise_index"
        :total_exercises="total_exercises"

        :has_next="true"
				@next="nextQuestion()"

        :has_skip="total_exercises>1"
        @skip="nextExercise()"

        :has_show_results="has_results"
        @show_results="goToResults()"
        >
        <template v-slot:bottom>
          <!-- next was here -->
          <!-- skip was here -->
          <!-- show result was here -->

          <div style="margin-top: 70%;">
            <p class="mb-0">Need help?</p>
            <button
              class="btn btn-outline-primary btn-lg w-100 rounded" 
              @click="showAnswer()">
              <p class="mb-0" style="font-size: 16px;"> Show answer <i class="bi bi-file-earmark-check"></i> </p>
            </button>
          </div>
          
        </template>
      </Sidebar>
    </template>
  </Layout>
</div>
</template>
<script>
  import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
  import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
  import {checkPermissionsMediaBasic} from '@/media_input/microphone_recording'
  
  import {getUserDataDir,getData,updateDataDirectory} from '@/firebase/index'
  import {logActivity} from '@/media_input/utils'
  
 
  import audioImg from '@/assets/images/audio_logo.png'
  import {save_pre_computed_results} from '@/utils/results/data_save_utils'
  import {BV_Points} from '@/utils/bv_points'

  import { useTTS } from '@/stores/tts'
  import {startConfetti} from '@/common_js/confetti'

  
  export default {
    name: 'Scene_click',

    /*

    {
   "Name":"Scene click random",
   "exercise_type":"scene_click",
   "setting":"random",
   "plan_name":"Find items in real life situations",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Find items in real life situations//Exercises/General/Aphasia/Scene Click/exercises/random",
   "ex_path":"Find items in real life situations//Exercises/General/Aphasia/Scene Click/exercises/random",
   "today":"2023-07-11 17:21",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"scene_click",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "module_name":"Find items in real life situations",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":1
    }


*/
    props: 
    {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data(){
      return {
        base_level_address:'/Levels/Scenario Description',
        level_address:'',
        userCategory:'',
        region:'',
        // tableImg:tableImg,
        // vaseImg:vaseImg,
        // flowerImg:flowerImg,
        // testImg:testImg,
        skip_button: "Skip the whole exercise",
        audioImg:audioImg,
        backImg:null,
        backImg_info:{},
        frontImg:[],
        frontImg_info:[],
        dummy:'',
        instruction_msg:'',
        operation_instruction:'',
        record_instruction:"Press the 'Record' button below to give your answer.",
        check_instruction:"Press the 'Check answer' button to check your answer.",
        next_instruction: "Press Next to see more questions.",
        new_item_instruction:'Click on an item in the image below to see more questions',
        interaction_msg:'',
        interaction_color:'',
        show_btn:false,
        total_exercises:1,
        play_pause_text:'Record answer',
        listener_ref:null,
        answer_text: '',
        resultsTable: [],
        results:{'correct':0,'incorrect':0,'total':0},
        uid:'',
        audio_player: null,
        half_word_url: null,
        current_qs:[],
        qs_count:0,
        max_qs:3,
        check_count:0,
        correct_counter:0,
        incorrect_counter:0,
        show_answer_counter:0,
        has_results:false,
        total_counter:0,
        previously_answered:false,
        right_answer:'',
        previous_img_name: null,
        previous_img_id: 0,
        target_scenario:null,
        history_scene_id:[],
        total_scene_num:0,
        currentSentence: '',
        target_words:[],
        answer_text_color: '',
        is_recording:false,
        scenario_id:0,
        current_item_id:0,
        item_name:'',
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),

      }
    },
    created() {
  
      const _this = this

      checkPermissionsMediaBasic()

      _this.current_exercise_index = _this.exForm.current_exercise_index + 1
      _this.total_exercises = _this.exForm.total_all_type_exercises

      this.userCategory = localStorage.getItem('userCategory');
      this.region = localStorage.getItem('region');
      
      logActivity( 'accessed', 'scene_click')
      
      let uid = localStorage.getItem('uid');
      _this.uid = uid;

      this.chooseScenario();

    },
    beforeUnmount()
    {
      this.bv_tts.cancel()


    },
    mounted()
    {
      const _this = this
      
      _this.bv_tts.say(_this.instruction_msg)
    },
    computed: {
  
    },
    methods: {
      goToResults()
      {
        const _this = this

        this.save_results()

        let goToResultsData = {curr_ex_done:true}
        this.$emit('goToResults', goToResultsData);
      },
      save_results()
      {

        const _this = this

        if(this.total_counter>0)
        {
          let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter, Total:this.total_counter, Show_Answer:_this.show_answer_counter, Target_Word: this.item_name}

          let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}


          let results = {all_results:all_results, plot_results:plot_results}

          var scene_click_data = {resultsTablePre: {exercise_type: 'Scene Click', exercise: 'Scene Click', results: results, audio_address:''}}


          save_pre_computed_results(_this.exForm,_this.$firebase,scene_click_data)

          _this.bv_points.add(10)

          _this.$emit('logExerciseResults', scene_click_data);
        }



      },

      chooseScenario()
      {
        const _this = this

        let userType = localStorage.getItem('userCategory');


        let uid = localStorage.getItem('uid');

        this.level_address = '/Users/' + uid + this.base_level_address


        if(userType!=undefined || userType==='slp')
        {
            let client_number = localStorage.getItem('client_uid');

            if(client_number!='undefined' && client_number!=null && client_number!='')
            {

                this.level_address = '/Users/'+ client_number + this.base_level_address

            }
        }


  
        let address = this.level_address
  
        let do_once = true
  
        console.debug('chooseScenario')
  
        getData(address, function (err, result) {
          console.debug('here')
  
          if(do_once)
          {
            do_once = false
            console.debug('chooseScenario2')
  
            let curr_scenario = result.child('Scenario ID').val()
  
            console.debug('curr_scenario = ' + curr_scenario)
  
            if(curr_scenario==undefined || curr_scenario==null)
            {
              curr_scenario = 0
            }
            else
            {
              curr_scenario = parseInt(curr_scenario)
            }
            console.debug('curr_scenario = ' + curr_scenario)
            _this.scenario_id = curr_scenario
            // curr_scenario = curr_scenario+1
            // if(curr_scenario>(_this.total_scene_num-1))
            // {
            //   curr_scenario = 0
            // }
  
            // curr_scenario = curr_scenario.toString()
  
            // updateDataDirectory(address,{'Scenario ID':curr_scenario})
  
            _this.loadImage()
  
            
  
          }
        })
  
      },
      nextExercise()
      {
          const _this = this;
          this.bv_tts.cancel()

          _this.$emit('nextExercise');
      },
      loadImage()
      {
          let _this = this;
          _this.backImg = null;
          _this.backImg_info = {};
          _this.frontImg = [];
          _this.frontImg_info = [];
          let image_folder_ref = _this.$firebase.database().ref("Exercises").child('General').child('Aphasia').child('Image_click');
          // let image_folder_ref = _this.$firebase.database().ref("Exercises").child('General').child('Aphasia').child('Image_click_newImg_stage'); //for debugging new images in the scene description
          var do_once = true;
          getData(image_folder_ref , function (err, result) {
  
              if(do_once)
              {
                  do_once = false;
                  let full_scenario = []
                  result.forEach(function (childSnapshot) {
                      let scenario = childSnapshot.key;
                      full_scenario.push(scenario);
                  }); 
                  _this.total_scene_num = full_scenario.length;
  
                  let address = _this.level_address
  
  
                  let curr_scenario = _this.scenario_id
                  curr_scenario = curr_scenario+1
                  if(curr_scenario>(_this.total_scene_num-1))
                  {
                    curr_scenario = 0
                  }
  
                  curr_scenario = curr_scenario.toString()
  
                  updateDataDirectory(address,{'Scenario ID':curr_scenario})
  
  
                  if(_this.history_scene_id.length!=0)
                  {
                    for(let i=0;i<_this.history_scene_id.length;i++)
                    {
                      full_scenario.splice(_this.history_scene_id[i],1);
                    }
                  }
                  // let scenario_id = Math.floor(Math.random() * full_scenario.length);
                  let scenario_id = _this.scenario_id
                  // scenario_id = 3; //force scene for debugging
                  _this.history_scene_id.push(scenario_id);
                  _this.target_scenario = full_scenario[scenario_id];
                  // console.debug('scenario= '+_this.target_scenario);
                  _this.backImg_info = result.child(_this.target_scenario).child('backImg').child('0').val();
                  // console.debug('image name = '+_this.backImg_info.name);
                  _this.backImg = new URL(`../../../../../assets/images/image_click/${_this.target_scenario}/${_this.backImg_info.name}.png`, import.meta.url)
                  _this.backImg_info.size = "width:"+_this.backImg_info.size;
                  _this.backImg_info.checked = true;
                  console.debug('imag size= '+_this.backImg_info.size);
  
                  result.child(_this.target_scenario).child('frontImg').forEach(function (childSnapshot) {
                      let frontInfo_temp = childSnapshot.val();
                      console.debug('front image name = '+frontInfo_temp.name)
                      console.debug('q_a[0]= '+frontInfo_temp.q_a[0].answer);
                      // _this.all_items.push(frontInfo_temp.name)
                      frontInfo_temp.size = "width:"+frontInfo_temp.size+";"+frontInfo_temp.loc;
                      frontInfo_temp.checked = false;
                      _this.frontImg_info.push(frontInfo_temp);
                      let frontImg_temp = new URL(`../../../../../assets/images/image_click/${_this.target_scenario}/${frontInfo_temp.name}_white_alpha.png`, import.meta.url)
                      _this.frontImg.push(frontImg_temp)
                  });
                  console.debug('front info size= '+_this.frontImg_info.length);
                  // _this.loadLocation();
                  
                  if('description' in _this.frontImg_info[_this.current_item_id])
                  {
                    _this.item_name = _this.frontImg_info[_this.current_item_id].description
                  }
                  else
                  {
                    _this.item_name = _this.rightAnswer(_this.frontImg_info[_this.current_item_id].q_a[0].answer)
                  }
                  _this.instruction_msg = 'Click on the '+_this.item_name
                  _this.bv_tts.say(_this.instruction_msg)
              }
          });
          
      },
      interaction(name,id)
      {
          const _this = this
          this.dummy = this.dummy + '1'
          this.interaction_msg = '';
          this.qs_count = 0;
        
          if(name == 'front')
          {
              if(!this.frontImg_info[id].checked)
              {
                if(this.previous_img_id != null)
                {
                  // if(this.previous_img_name == 'back')
                  // {
                  //   this.backImg = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${this.backImg_info.name}.png`, import.meta.url)
                  // }
                  // else if(this.previous_img_name == 'front')
                  // {
                  //   this.frontImg[this.previous_img_id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${this.frontImg_info[this.previous_img_id].name}_alpha.png`, import.meta.url)
                  // }
                  if(this.frontImg_info[this.previous_img_id].checked)
                  {
                    this.frontImg[this.previous_img_id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${this.frontImg_info[this.previous_img_id].name}_alpha.png`, import.meta.url)
                  }
                }
                this.check_count = this.check_count+1;

                console.debug('ftont image name = '+this.frontImg_info[id].name)
                console.debug('current item id = '+this.current_item_id)
                console.debug('my selection=' + id)
                this.has_results = false

                console.debug('this.previously_answered = ' + this.previously_answered)

                if(id == this.current_item_id)
                {

                  
                  if(!this.previously_answered)
                  {
                    this.correct_counter = this.correct_counter+1
                    this.total_counter = this.total_counter+1
                    this.save_results()

                  }

                  

                  this.previously_answered = false

                  this.incorrect_counter = 0
                  this.correct_counter = 0
                  this.show_answer_counter = 0


                  this.frontImg_info[id].checked = true;
                  this.frontImg[id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${ this.frontImg_info[id].name}_halo_alpha.png`, import.meta.url) 
                  this.interaction_msg = 'Correct!'
                  this.bv_tts.system_say(this.interaction_msg)
                  this.interaction_color = 'fontSize:24px;color:green';

                  startConfetti(this)

                  window.countDownTimer = setTimeout(() => {
                    _this.nextQuestion()

                  }, 2200)

                }
                else
                {
                  if(!this.previously_answered)
                  {
                    this.incorrect_counter = this.incorrect_counter+1
                    this.total_counter = this.total_counter+1
                    this.save_results()

                  }

                  this.incorrect_counter = 0
                  this.correct_counter = 0
                  this.show_answer_counter = 0


                  this.previously_answered = true

                  this.interaction_color = 'fontSize:24px;color:red';

                  this.interaction_msg = 'Wrong selection'
                  this.bv_tts.system_say(this.interaction_msg)


                }
              }
              
              
          
              
          }
          this.previous_img_name = name;
          this.previous_img_id = id;
          
      },
      get_half_word_url(word)
      {
  
          let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'
  
  
          const _this = this;
          const storage = _this.$firebase.storage();
  
          storage.ref(audio_file_location).getDownloadURL()
          .then((url) => {
  
              _this.half_word_url = url
  
              console.debug('Found half word url!!')
  
          })
          .catch((error) => {
  
              console.debug('error ' + error )
          });
      
  
      },
      listenHint() 
      {
  
        const _this = this
  
        let url = _this.half_word_url
  
        if(_this.audio_player==null)
        {
            _this.audio_player = new Audio(url);
  
            _this.audio_player.play();
  
        }
        else
        {
  
            if(!_this.audio_player.paused)
            {
            _this.audio_player.pause();
  
            
            }
            // else
        
            {
            _this.audio_player = new Audio(url);
  
            _this.audio_player.play();
  
            }
        }
  
      },
      rightAnswer(text)
      {
        let target_words = text.split(';')
        console.debug('right answes = '+target_words)
        let first_answer = target_words[0]
        return first_answer
      },
      nextQuestion()
      {
          const _this = this
          this.previously_answered = false
          _this.interaction_msg = ''
          if(_this.current_item_id<_this.frontImg_info.length-1)
          {
            _this.current_item_id = _this.current_item_id + 1;
            if('description' in _this.frontImg_info[_this.current_item_id])
            {
              _this.item_name = _this.frontImg_info[_this.current_item_id].description
            }
            else
            {
              _this.item_name = _this.rightAnswer(_this.frontImg_info[_this.current_item_id].q_a[0].answer)
            }              
            _this.instruction_msg = 'Click on the '+_this.item_name
            _this.bv_tts.say(_this.instruction_msg)
          }
          else
          {
            _this.current_item_id = 0;
            window.countDownTimer = setTimeout(() => {
              _this.nextExercise();

            }, 1250)
          }
          
          // console.debug('qs_count= '+this.qs_count)
          // if(_this.play_status)
          // {
              
          //     // _this.pause();
          //     // _this.endRecordingAudio(false)
          //     _this.play_pause_text = 'Record answer'
          //     _this.play_status = false
          //     _this.started = false
          //     clearInterval(window.refreshIntervalId)
          // }
          
          // if(this.qs_count<this.max_qs -1)//take out the question[0] which is the first question asked when clicked
          // {
          //     this.qs_count = this.qs_count+1;
          //     let rand_question_id = Math.floor(Math.random() * this.current_qs.length);
          //     this.instruction = this.current_qs[rand_question_id].question;
          //     this.bv_tts.say(this.instruction)
  
          //     this.right_answer = this.current_qs[rand_question_id].answer;
          //     console.debug('this.right_answer = ' + this.right_answer)
          //     this.target_words = this.right_answer.split(';')
          //     this.operation_instruction = this.record_instruction;
          //     this.show_btn = true;
          //     this.half_word_url = null
  
          //     let first_answer = this.right_answer.split(';')[0]
  
  
          //     this.get_half_word_url(first_answer)
  
  
          //     this.current_qs.splice(rand_question_id,1)
          // }
          // else
          // {
              
          //     console.debug('check count = '+this.check_count)
          //     console.debug('this.frontImg.length = '+this.frontImg.length)
          //     console.debug('this.history_scene_id.length = '+this.history_scene_id.length)
          //     console.debug('this.total_scene_num = '+this.total_scene_num)
  
          //     console.debug('here 0')
  
          //     console.debug('this.history_scene_id.length = ' + this.history_scene_id.length)
          //     console.debug('this.total_scene_num = ' + this.total_scene_num)
  
  
          //     if(this.check_count ==  this.frontImg.length)
          //     {
          //       console.debug('in here')
  
          //       _this.$emit('nextExercise');
  
  
          //     }
          //     else
          //     {
          //       this.instruction = this.new_item_instruction;
          //       this.bv_tts.say(this.instruction)
          //       this.operation_instruction = '';
          //       this.show_btn = false;
          //       this.half_word_url = null
          //       this.qs_count = 0;
          //     }
          // }
          
      },

      showAnswer()
      {
        const _this = this
        this.show_answer_counter = this.show_answer_counter+1
        console.debug('show answer id= '+this.current_item_id + ' name= '+this.frontImg_info[this.current_item_id].name)
        
        if(this.previous_img_id != null)
        {
          
          if(this.frontImg_info[this.previous_img_id].checked)
          {
            this.frontImg[this.previous_img_id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${this.frontImg_info[this.previous_img_id].name}_alpha.png`, import.meta.url)
          }
        }


        this.frontImg_info[this.current_item_id].checked = true;
        this.frontImg[this.current_item_id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${ this.frontImg_info[this.current_item_id].name}_halo_alpha.png`, import.meta.url) 
          // let first_answer = this.right_answer.split(';')[0]
          // this.interaction_color = 'fontSize:24px;color:#0099ff';
        this.dummy = this.dummy+1
        this.interaction_msg = "The answer is highlighted below. Press Next to do more";
        this.bv_tts.say(this.interaction_msg)

        this.previous_img_id = this.current_item_id
        

        // window.countDownTimer = setTimeout(() => {
        //   _this.nextQuestion()

        // }, 1250)
      },
      loadLocation()
      {
          let all_loc = this.backImg_info.all_loc;
          for(let m =0; m<this.frontImg.length;m++)
          {
              let rand_loc_id = Math.floor(Math.random() * all_loc.length);
              this.frontImg_info[m].size = this.frontImg_info[m].size + ";"+all_loc[rand_loc_id];
              console.debug('front image loc= '+this.frontImg_info[m].size)
              all_loc.splice(rand_loc_id,1);
          }
      },
      randLocation()
      {
          let range_x_min = this.backImg_info.range_x[0];
          let range_x_max = this.backImg_info.range_x[1];
          let range_y_min = this.backImg_info.range_y[0];
          let range_y_max = this.backImg_info.range_y[1];
          let div_x = Math.floor(this.frontImg.length/2);
          let div_y = this.frontImg.length-div_x;
  
          let all_loc = [];
          for(let j = 0;j<div_x;j++)
          {
              for(let k=0;k<div_y;k++)
              {
                  let x = range_x_min + Math.floor((range_x_max-range_x_min)/div_x)*(j+1);
                  let y = range_y_min + Math.floor((range_y_max-range_y_min)/div_y)*(k+1);
                  let loc = "top:"+y+"%;left:"+x+"%";
                  all_loc.push(loc);
              }   
          }
  
          for(let m =0; m<this.frontImg.length;m++)
          {
              let rand_loc_id = Math.floor(Math.random() * all_loc.length);
              this.frontImg_info[m].size = this.frontImg_info[m].size + ";"+all_loc[rand_loc_id];
              console.debug('front image loc= '+this.frontImg_info[m].size)
              all_loc.splice(rand_loc_id,1);
          }
      },
    }
  }
</script>
  
<style scoped>

  .custom-text{
      font-size: 20px;
      font-family: Arial;
  }
  
  .parent {
    position: relative;
    top: 0;
    left: 0;
  }
  .backimg {
    position: relative;
    top: 0;
    left: 0;
    /* border: 1px red solid; */
  }
  .frontimg {
    position: absolute;
    /* top: 60px;
    left: 500px; */
    /* border: 1px green solid; */
  }

</style>