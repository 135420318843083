<template>
  <div>
    <Layout>
      <template v-slot:content>
        <span style="fontSize:26px" class="custom-text">{{instruct}} <img :src="audioImg" style="width:25px;;cursor: pointer;" @click="readInstructions()"> </span>

        <div v-if="!isToShow">
          <div class="card">
            <div class="card-body"> 
              <!-- Not needed <p v-if="title!=undefined" class="read-text" style="margin-top:10px">{{title}}</p>   -->
              <p class="read-text" style="margin-top:10px">{{descript}}</p>
              <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="readContentAloud(descript)">

            </div>
          </div>
        </div>
        
        <div v-if="isToShow" class="mt-2">
          <div class="card">
            <div class="card-body"> 
              <div class="row justify-content-center">
                <p class="read-text" style="margin-top:10px" @click="readContentAloud(qs_answers)">{{descript_qs}}</p>
                <img :src="audioImg" style="width:55px; cursor:pointer;" @click="readContentAloud(qs_answers)">

              </div>
              <div class="row justify-content-center">
                <div class="col-8">
                  <div class="input-group justify-content-start">
                    <div class="form-check">
                      <label class="form-check-label fs-4 text-start" for="0" v-bind:style="radio_choices!==choiceA ? {backgroundColor:'#FFFFFF'} : choiceA === answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}">{{choiceA}}</label>
                      <input class="form-check-input" type="radio" v-model="radio_choices" id="0" :value="choiceA" @change="chooseAnswer(radio_choices)" >
                    </div>
                  </div>
                  <div class="input-group justify-content-start">
                    <div class="form-check">
                      <label class="form-check-label fs-4 text-start" for="1" v-bind:style="radio_choices!==choiceB ? {backgroundColor:'#FFFFFF'} : choiceB === answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}">{{choiceB}}</label>
                      <input class="form-check-input" type="radio" v-model="radio_choices" id="1" :value="choiceB" @change="chooseAnswer(radio_choices)" >
                    </div>
                  </div>
                  <div class="input-group justify-content-start text-start">
                    <div class="form-check">
                      <label class="form-check-label fs-4" for="2" v-bind:style="radio_choices!==choiceC ? {backgroundColor:'#FFFFFF'} : choiceC === answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}">{{choiceC}}</label>
                      <input class="form-check-input" type="radio" v-model="radio_choices" id="2" :value="choiceC" @change="chooseAnswer(radio_choices)" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"

          :has_next="btn_text!=='Show question'"
          @next="nextQuestion()"

          :has_show_results="has_results"
          @show_results="goToResults()"
          >
          <template v-slot:top>
            <hr/>
            <p class="lead" font-size="medium">Question {{question_index+1}} of {{total_questions}}</p>

            <button class="btn btn-primary btn-lg w-100 mt-2" @click="showOptions()"> {{btn_text}} 
              <i v-bind:class="[btn_text==='Show question' ? 'bi bi-eye' : 'bi bi-arrow-clockwise']" ></i>
            </button>
            <!-- next was here -->

          </template>
        </Sidebar>
      </template>
    </Layout>
    
  </div>
</template>
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'

import homeplanImg from '@/assets/images/homeplan.png'
import settingImg from '@/assets/images/setting.png'
import patientImg from '@/assets/images/patient.png'
import taskImg from '@/assets/images/task.png'
import addImg from '@/assets/images/add.png'
import warnImg from '@/assets/images/warn.png'
import audioImg from '@/assets/images/audio_logo.png'
import backImg from '@/assets/images/back.png'
import targetIcon from '@/assets/images/target.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import rightArrow from '@/assets/images/bxs-right-arrow-white.png'
import libImg from '@/assets/images/prePlans.png'
import {startConfetti} from '@/common_js/confetti'

import { getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'
import { BV_Points } from '@/utils/bv_points'

import FuzzySet from 'fuzzyset'

export default {
  name: "Story_memory",
  props: {
    exForm: {
      type: Object,
    }
  },
  components: {Layout, Sidebar},
  data() {
    return {
      title:undefined,
      total_exercises:1,
      total_questions:1,
      current_exercise_index:1,
      reading_comprehension_age:7,
      question_index:0,
      listen_count:0,
      has_results: false,
      homeplanImg: homeplanImg,
      settingImg: settingImg,
      patientImg: patientImg,
      taskImg: taskImg,
      rightArrow: rightArrow,
      addImg: addImg,
      warnImg: warnImg,
      audioImg: audioImg,
      backImg: backImg,
      targetIcon: targetIcon,
      doneIcon: doneIcon,
      descript:'test',
      descript_qs:'',
      subject:['jack','kate','Mary','additional'],
      occupation:['Student','gardener','chef','additional'],
      interests:[],
      all_questions:[],
      first_answer:true,
      topics:[],
      num_subject:3,
      show_results: false,
      curr_ex: 0,
      results_table: {back:[],pre:[]},
      question:'test',
      answer:'',
      radio_choices:'',
      choiceA:'testA',
      choiceB:'testA',
      choiceC:'testA',
      choiceD:'testA',
      read_instructions:"Read the paragraph below. Press Show question to answer the question.",
      choice_num: 4,
      btn_text: 'Show question',
      isToShow: false,
      correct_counter:0,
      incorrect_counter:0,
      instruct:'',
      descript_qs_answer:'',
      qs_answers: [],
      libImg:libImg,
      start_object: {
        assessment_type: "USER_TRAINING", 
        module_name: 'Story Description',
        current_exercise_index: 0
      },
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points()
    }
  },
  created() 
  {
    const _this = this

    _this.userCategory = localStorage.getItem("userCategory");
    
    this.instruct = this.read_instructions;
    console.debug('here')
    console.debug(_this.exForm)

    this.all_questions = _this.exForm.all_questions

    this.total_questions = this.all_questions.length

    this.setupQuestion()

    // this.descript = _this.exForm.paragraph
    // this.descript_qs = _this.exForm.question

    // this.choiceA = this.exForm.options[0];
    // this.choiceB = this.exForm.options[1];
    // this.choiceC = this.exForm.options[2];

    let selection = Math.floor(Math.random() * 6);
    // if(selection<2)
    // {
    //   this.setup(1);
    // }
    // else
    // {
    //   this.setup(2);
    // }

    logActivity( 'accessed', 'reading_comprehension')

    _this.has_results = _this.exForm.has_results


    //_this.start_object = _this.$route.params.start_object

    if(_this.start_object!=undefined && _this.start_object!=null)
    {
      if(_this.start_object.results_table!=undefined && _this.start_object.results_table!=null)
      {
        _this.results_table = _this.start_object.results_table
      }
    }

  },
  beforeUnmount()
  {
    this.bv_tts.cancel()
    this.bv_tts.cancel()  

  },
  methods: 
  {
    readInstructions()
    {
      this.bv_tts.cancel()  

      this.bv_tts.say(this.instruct)

    },
    readContentAloud(content)
    {
        const _this = this


        if(content.length>200)
        {
          let array_text = content.split('.')

          this.bv_tts.cancel()  

          this.bv_tts.say_sentences(array_text, 75)

        }
        else
        {
          this.bv_tts.cancel()  

          _this.bv_tts.say(content)

        }

        

    },
    setupQuestion()
    {
      const _this = this
      this.radio_choices = ''

      let question_index = this.question_index
      this.first_answer = true




      this.descript = this.all_questions[question_index].paragraph
      this.descript_qs = this.all_questions[question_index].question
      
      this.answer = this.all_questions[question_index].answer.trim()

      this.answer = this.answer.replace('A)', '').trim()
      this.answer = this.answer.replace('B)', '').trim()
      this.answer = this.answer.replace('C)', '').trim()
      
      

      this.reading_comprehension_age = this.all_questions[question_index].reading_comprehension_age

      this.title = this.exForm.Title

      if(this.reading_comprehension_age==undefined || this.reading_comprehension_age==null)
      {
        this.reading_comprehension_age = 16
      }

      console.debug('this.reading_comprehension_age = ' + this.reading_comprehension_age)


      this.descript_qs = this.descript_qs.replace('Q1.', '').trim()
      this.descript_qs = this.descript_qs.replace('Q2.', '').trim()
      this.descript_qs = this.descript_qs.replace('Q3.', '').trim()

      this.descript_qs = this.descript_qs.replace('Q1)', '').trim()
      this.descript_qs = this.descript_qs.replace('Q2)', '').trim()
      this.descript_qs = this.descript_qs.replace('Q3)', '').trim()



      let options = this.all_questions[question_index].options


      _this.choiceA = options[0].trim()
      _this.choiceB = options[1].trim()
      _this.choiceC = options[2].trim()


      this.choiceA = this.choiceA.replace('A)', '').trim()
      this.choiceB = this.choiceB.replace('B)', '').trim()
      this.choiceC = this.choiceC.replace('C)', '').trim()
      
      console.debug('_this.choiceA=' + _this.choiceA)
      console.debug('_this.choiceB=' + _this.choiceB)
      console.debug('_this.choiceC=' + _this.choiceC)

      console.log(JSON.stringify(_this.choiceA)); // ""Hello\nWorld""
      console.log(JSON.stringify(_this.choiceB)); // ""Hello\nWorld""
      console.log(JSON.stringify(_this.choiceC)); // ""Hello\nWorld""


      if(this.answer==='answer: A.' || this.answer==='A.' || this.answer ==='A')
      {
        _this.answer = _this.choiceA
      }
      if(this.answer==='answer: B.' || this.answer==='B.' || this.answer ==='B')
      {
        _this.answer = _this.choiceB
      }
      if(this.answer==='answer: C.' || this.answer==='C.' || this.answer ==='C')
      {
        _this.answer = _this.choiceC
      }



      this.qs_answers = [this.descript_qs, this.choiceA, this.choiceB, this.choiceC]

      
      
      let no_matching = true

      for(let n in options)
      {

        
        if(options[n].trim()===_this.answer)
        {
          no_matching = false
        }
      }

      if(no_matching)
      {

        let a = FuzzySet([_this.answer]);

        let best_index = 0
        let best_value = 0

        for(let n in options)
        {
          let value = a.get(options[n].trim(),null)


          if(value!=undefined && value!=null)
          {
            value = value[0][0]

            if(value>best_value)
            {
              best_index = n
              best_value = value
            }

          }

        }

        _this.answer = options[best_index].trim()


        this.answer = this.answer.replace('A)', '').trim()
        this.answer = this.answer.replace('B)', '').trim()
        this.answer = this.answer.replace('C)', '').trim()

      }


    },
    goToResults()
    {
      const _this = this

      _this.saveResults()

      let curr_ex_done = false

      if(this.correct_counter!=0 || this.incorrect_counter!=0)
      {
        curr_ex_done = true
      }

      var goToResultsData = {curr_ex_done:curr_ex_done}

      _this.$emit('goToResults', goToResultsData);

    },
    saveResults()
    {
      const _this = this

      if(this.correct_counter!=0 || this.incorrect_counter!=0)
      {
        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}
        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:_this.correct_counter+_this.incorrect_counter,ListenCount:_this.listen_count, ReadingComprehensionAge:this.reading_comprehension_age}
        let results = {all_results:all_results, plot_results:plot_results}

        var reading_comprehension_data = {resultsTablePre: {exercise_type: 'Reading Comprehension', exercise: 'Reading Comprehension', results: results, audio_address:''}}


        _this.$emit('logExerciseResults', reading_comprehension_data);



        save_pre_computed_results(this.exForm,_this.$firebase,reading_comprehension_data)

        _this.bv_points.add(10)
      }
    },
    chooseAnswer(radio_choices)
    {

      const _this = this
      
      console.debug('radio_choices = ' + radio_choices)
      console.debug('answer = ' + this.answer)

      if(_this.answer==radio_choices)
      {

        if(_this.first_answer)
        {
          _this.correct_counter = _this.correct_counter + 1
          this.first_answer = false

        }

        console.debug('this.question_index = ' + this.question_index)
        console.debug('this.all_questions.length = ' + this.all_questions.length)

        this.bv_tts.cancel()  

        if(this.question_index+1<this.all_questions.length)
        {

          _this.bv_tts.system_say(radio_choices + ' is correct! Click Next to do another exercise')


        }
        else{
          _this.bv_tts.system_say(radio_choices + ' is correct! Click Next.')

        }

        startConfetti(this)


        

      }
      else
      {
        
        if(_this.first_answer)
        {
          _this.incorrect_counter = _this.incorrect_counter + 1
          this.first_answer = false


        }
        window.speechSynthesis.cancel()

        _this.bv_tts.system_say(radio_choices + ' is not the right one. Try again!')

      }

    },
    setup(scenario)
    {
      var _this = this;
      var do_once = true;

        _this.curr_ex = _this.curr_ex+1

        _this.show_results = _this.curr_ex>3
        
        let targetDir = 'Exercises/General/Aphasia/Story_understanding/'


      getData(targetDir, function (err, result) {

        if(do_once)
        {
          do_once = false

          let total_name = result.child('who').numChildren();
          let rand_name_id = Math.floor(Math.random() * total_name)+1;
          let rand_name = result.child('who').child(rand_name_id).val();
          _this.subject = [];
          while(_this.subject.length<_this.choice_num)
          {
            if(!_this.subject.includes(rand_name))
            {
              _this.subject.push(rand_name);
            }
            else
            {
              rand_name_id = Math.floor(Math.random() * total_name)+1;
              rand_name = result.child('who').child(rand_name_id).val();
            }
          }

          if(scenario == 1)
          {
            let total_occupation = result.child('occupation').numChildren();
            let rand_occupation_id = Math.floor(Math.random() * total_occupation)+1;
            let rand_occupation = result.child('occupation').child(rand_occupation_id).child('Type').val();
            let rand_topic_id = Math.floor(Math.random() * result.child('occupation').child(rand_occupation_id).child('Topic').numChildren())+1;
            _this.question = result.child('occupation').child(rand_occupation_id).child('Topic').child(rand_topic_id).val();
            _this.occupation = [];
            while(_this.occupation.length<_this.choice_num)
            {
              if(!_this.occupation.includes(rand_occupation))
              {
                  _this.occupation.push(rand_occupation);
              }
              else
              {
                  rand_occupation_id = Math.floor(Math.random() * total_occupation)+1;
                  rand_occupation = result.child('occupation').child(rand_occupation_id).child('Type').val();
              }
            }
          }
          else if(scenario == 2)
          {
            let total_interest = result.child('interests').numChildren();
            let rand_interest_id = Math.floor(Math.random() * total_interest)+1;
            let rand_interest = result.child('interests').child(rand_interest_id).child('Type').val();
            _this.interests = [];
            // _this.interests.push(rand_interest);
            let rand_topic_id = Math.floor(Math.random() * result.child('interests').child(rand_interest_id).child('Topic').numChildren())+1;
            _this.topics = [];
            // _this.topics.push(result.child('interests').child(rand_interest_id).child('Topic').child(rand_topic_id).val());
            while(_this.topics.length<_this.choice_num)
            {
              if(!_this.interests.includes(rand_interest))
              {
                _this.interests.push(rand_interest);
                rand_topic_id = Math.floor(Math.random() * result.child('interests').child(rand_interest_id).child('Topic').numChildren())+1;
                _this.topics.push(result.child('interests').child(rand_interest_id).child('Topic').child(rand_topic_id).val());
              }
              else
              {
                rand_interest_id = Math.floor(Math.random() * total_interest)+1;
                rand_interest = result.child('interests').child(rand_interest_id).child('Type').val();
              }
              
            }
          }
          

          _this.descriptionTemp(scenario);

        }

      });    
    },
    showOptions()
    {
        if(this.btn_text == 'Show question')
        {

            this.isToShow = true;
            this.btn_text = 'Repeat text';
            this.instruct = "Answer the question below. Press Repeat text to read the paragraph again.";

            this.bv_tts.cancel()  

            setTimeout(() => {
              this.bv_tts.cancel()  

              this.bv_tts.system_say_sentences(this.qs_answers)        
            }, 500)

        }
        else
        {
          this.bv_tts.cancel()  

            this.isToShow = false;
            this.btn_text = 'Show question';
            this.instruct = this.read_instructions;
        }
        
    },
    nextQuestion()
    {
        this.question_index = this.question_index+1

        window.speechSynthesis.cancel()


        if(this.question_index>=this.all_questions.length)
        {
            this.nextExercise()
        }
        else
        {
            this.setupQuestion()
            this.bv_tts.cancel()  

            setTimeout(() => {
              this.bv_tts.cancel()  

              this.bv_tts.system_say_sentences(this.qs_answers)        
            }, 500)

        }

    },
    nextExercise()
    {
      const _this = this

      _this.saveResults()

      _this.$emit('nextExercise');
      // const _this = this
      // this.isToShow = false;
      // this.btn_text = 'Show question';
      // this.radio_choices = '';
      // let selection = Math.floor(Math.random() * 6);
      // if(selection<2)
      // {
      //   this.setup(1);
      // }
      // else
      // {
      //   this.setup(2);
      // }

      // this.saveResults()
      // _this.current_correct_counter = 0
      // _this.current_incorrect_counter = 0

    },
  },
}
</script>
<style scoped>


li:hover {
  background-color: #e0e0e2;
  color: white;
}



</style>


<!-- Results Description:

  {
  "Story Memory": {
    "audio_address": "<empty>",
    "resultsTablePre": {
      "audio_address": "",
      "exercise": "Story Memory",
      "exercise_type": "Memory Games",
      "results": {
        "all_results": {
          "Correct": <int>,
          "Incorrect": <int>,
          "ListenCount": <int>,
          "Total": <int>>
        },
        "plot_results": {
          "Correct": <int>,
          "Incorrect": <int>
        }
      }
    },
    "results_type": "pre_computed"
  },
  "assessment_type": "USER_TRAINING/SLP_TRAINING",
  "plan_name": "Story Memory"
  
}


 -->