<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="row">
          <p class="fs-3">{{instructions_msg}} <img :src="audioImg" style="width:35px;cursor: pointer;" @click="bv_tts.say(instructions_msg)"></p>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6">
            <button v-if="all_prompts.length>0" class="btn btn-success btn-lg w-100" @click="loadPrompts()">Talking prompts <i class="bi bi-lightbulb" style="color: white;"></i></button>
            <button v-else class="btn btn-success btn-lg w-100" disabled>No more prompts <i class="bi bi-lightbulb" style="color: white;"></i></button>
            </div>
        </div>
        <div class="row mt-2">
              
            <div class="row">
            <img id="img_naming" v-bind:src="imgSrc"  width="250" height="450" style="object-fit: contain"> 
            </div>
          
        </div>
        <div class="row" v-if="attribute_1.length>2 && attribute_2.length>2">           

          <p style="font-size: small;">Attribute: photo by <a href="{{ attribute_1[1] }}"> {{ attribute_1[2] }}</a> on <a href="{{ attribute_2[1] }}">{{ attribute_2[2] }}</a></p>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          
          :has_show_results="has_results"
          @show_results="goToResults()"   
          >
            <template v-slot:top>
              <button v-if="action==='record'" class=" btn-primary btn btn-lg w-100 mt-2" @click="playPause()">Start</button>
              <button v-if="action==='recording'" class=" btn-info btn btn-lg w-100 mt-2" @click="playPause()">End</button>
              <!-- show results was here -->

            </template>
        </Sidebar>
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import {checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia} from '@/media_input/microphone_recording'

import homeplanImg from '@/assets/images/homeplan.png'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import audioImg from '@/assets/images/audio_logo.png'
import ideaImg from '@/assets/images/research.png'
import loadingSVG from '@/assets/images/loader.svg'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {getData} from '@/firebase/index'

import {logActivity,removePunctuationFromString} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'

import {BV_Points} from '@/utils/bv_points'
import {updateDataDirectory } from '@/firebase/index'

import {generate_results_path} from '@/utils/results/data_save_utils'

export default {
    name: 'name_a_picture_easy',
    props: {
      exForm: {
        type: Object,
      },
    },
    components: {Layout, Sidebar},
    data() {
      return {
        picture_name:'',
        resultsTableBack:[],
        sound_hints:false,
        recognition_setting: 0,
        action:'record',
        play_status: false,
        push_structure:null,
        avg_loudness: 0,
        max_loudness:0,
        last_loudness:0,
        counter_loudness:0,
        client_demo: true,
        audioImg:audioImg,
        ideaImg:ideaImg,
        loadingSVG: loadingSVG,
        diffMarks:{
          0:{
            style:{color: '#000000'},
            label:'Auto'
          },
          1:{
            style:{color: '#000000'},
            label:'Manual'
          },
        },
        answer_text: '',
        answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
        imgSrc: loadingSVG,
        hints:['It is used to cook','It is made of metal'],
        showHints:false,
        answer_options:['Saucepan','Fridge'],
        showOptions:false,
        showedOptions:false,
        showedAnswer:false,
        has_results:false,
        tested_words_phonetics:{},
        target_words_phonetics:{},
        full_sentence:'',
        chosen_image: '',
        target_word: '',
        audio_player: null,
        target_img_number: '',
        instructions_msg: 'Describe the picture',
        homeplanImg: homeplanImg,
        crossImg: crossImg,
        doneIcon: doneIcon,
        inputAcc:'',
        play_pause_text: 'Start',
        total_exercises:1,
        current_exercise_index: 0,
        speech_rec: null,
        started: false,
        target_words: [],
        currentSentence: '',
        best_answer:'',
        results:{'correct':0,'incorrect':0,'total':0},
        uid: localStorage.getItem('uid'),
        userType: localStorage.getItem('userCategory'),
        region: localStorage.getItem('region'),
        resultsTable: [],
        retest_count: null,
        is_recording: false,
        congatulationsOnce: false,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
        test:'www.google.com',
        attribute_1:[],
        attribute_2:[],
        all_prompts:[],
        past_pic_keys:[]
      }
    },
    created() {

      const _this = this

      logActivity( 'accessed', 'picture_naming')

      checkPermissionsMedia(); 

      let temp_past_pic_keys = localStorage.getItem('past_pic_key')

      if(temp_past_pic_keys==undefined || temp_past_pic_keys==null)
      {
          temp_past_pic_keys = []
      }
      else
      {
          temp_past_pic_keys = JSON.parse(temp_past_pic_keys)
      }
      this.past_pic_keys = temp_past_pic_keys


      this.setup();

    },
    mounted()
    {
      const _this = this

      if(_this.recognition_setting==0)
      {
        let inital_text = 'Describe the picture.'

        _this.bv_tts.say(inital_text) 
      }
    },
    beforeUnmount()
    {  
      const _this = this  
      this.bv_tts.cancel()

      clearInterval(window.refreshIntervalId)

      if(_this.is_recording) {
        _this.endRecordingAudio(true);
      } else {
        _this.endRecordingAudio(false);
      }

      deleteSoundMeter()


    },    
    methods: 
    {
      
      nextExercise()
      {

        console.debug('nextExercise')
        const _this = this;
        // _this.saveResults()

        let avg_loudness_array = []

        if(_this.avg_loudness>0)
        {
          avg_loudness_array.push(_this.avg_loudness)
        }



        if(_this.resultsTableBack.length>0)
        {
          let basicData = {resultsTableBack: [_this.resultsTableBack[this.resultsTableBack.length-1]], avg_loudness_array: avg_loudness_array}

          _this.$emit('logExerciseResults', basicData);
        } else {
          let basicData = {resultsTableBack: null, avg_loudness_array: avg_loudness_array}
          _this.$emit('logExerciseResults', basicData);
        }
          
        _this.$emit('nextExercise');
      },
      goToResults()
      {
        const _this = this

        // _this.saveResults()
        let goToResultsData = {curr_ex_done:false}
        _this.$emit('goToResults', goToResultsData);
      },
      weighted_randomisation(resources_data)
      {

        const _this = this

        let randomiser_array = []

        let keys = Object.keys(resources_data)

        console.debug('keys = ' + keys)


        for(let n in keys)
        {
          let curr_topic_folder = resources_data[keys[n]]


          for(let k in curr_topic_folder)
          {
            console.debug(k)
            let in_key = keys[n] + '_' + k
            randomiser_array.push({'topic':keys[n],'name':k,'in_key':in_key})
          }
        }

        console.debug(randomiser_array)

        let loop_max = 0

        let new_img_key = ''
        let rand_key_id = 0

        while ((_this.past_pic_keys.includes(new_img_key) || loop_max==0) && (loop_max<100)) {
            rand_key_id = Math.floor(Math.random() * randomiser_array.length)   

            new_img_key = randomiser_array[rand_key_id]['in_key']

            loop_max = loop_max + 1
        }

        _this.local_store(new_img_key)

        let output = randomiser_array[rand_key_id]
        console.debug(output)
        return output



            // let rand_key_id = Math.floor(Math.random() * keys.length)                

            // let all_image_resources = resources_data[keys[rand_key_id]]
            // let keys_image = Object.keys(all_image_resources)
            // let rand_image_key_id = Math.floor(Math.random() * keys_image.length)

            // let img_address = ''
            // let new_img_key = keys[rand_key_id] + '_'+keys_image[rand_image_key_id]
            // let loop_max = 0
            // while ((_this.past_pic_keys.includes(new_img_key))&&(loop_max<100)) {
            //     rand_key_id = Math.floor(Math.random() * keys.length)   
            //     all_image_resources = resources_data[keys[rand_key_id]]
            //     keys_image = Object.keys(all_image_resources)
            //     rand_image_key_id = Math.floor(Math.random() * keys_image.length)
            //     new_img_key = keys[rand_key_id] + '_' + keys_image[rand_image_key_id]
            //     loop_max = loop_max + 1
            // }
      },   
      setup()
      {
        let _this = this;

        _this.answer_text = '';

        _this.instructions_msg = 'Describe the picture'

        _this.has_results = _this.exForm.has_results


        let client_demo = _this.exForm.client_demo


        if(client_demo!=undefined && client_demo!=null)
        {
            _this.client_demo = client_demo
        }
        

        let resources_address = '/Exercises/Resources/Picture_description/'
        let do_once = true

      getData(resources_address, function (err, result) {

          if(do_once)
          {

            do_once = false
            let resources_data = result.val()

            let randomised_output = _this.weighted_randomisation(resources_data)


            let topic = randomised_output['topic']

            let all_image_resources = resources_data[topic]

            console.debug(all_image_resources)
            let image_name = randomised_output['name']
            console.debug(image_name)
      

            _this.picture_name = all_image_resources[image_name].name

            _this.picture_name = _this.picture_name.charAt(0).toUpperCase() + _this.picture_name.slice(1);


            _this.all_prompts = all_image_resources[image_name].questions
            let img_address = all_image_resources[image_name].file_name

            let attribute_text = ''


            if('attribute' in all_image_resources[image_name])
            {
              attribute_text = all_image_resources[image_name].attribute

              _this.attribute_1 = attribute_text.match(/<a\s+href=['"](.*?)['"]\s*>(.*?)<\/a>/i);

              let second_half = attribute_text.substring(_this.attribute_1[0].length,attribute_text.length)


              _this.attribute_2 = second_half.match(/<a\s+href=['"](.*?)['"]\s*>(.*?)<\/a>/i);

            }

          


            _this.current_exercise_index = _this.exForm.current_exercise_index + 1
            _this.total_exercises = _this.exForm.total_all_type_exercises
            _this.retest_count = null
            _this.target_word = _this.target_word.toLowerCase()


            var storageRef = _this.$firebase.storage().ref();
            
            storageRef.child(img_address).getDownloadURL().then(function(url) 
            {
              _this.imgSrc = url;
            }).catch(function(error) {

            });

          }
        })
      },
      local_store(new_key)
      {
          let _this = this
          if(_this.past_pic_keys.length >10)
          {
              _this.past_pic_keys.splice(0,1)
          }
          _this.past_pic_keys.push(new_key)
          localStorage.setItem('past_pic_key',JSON.stringify(_this.past_pic_keys))
      },
      loadPrompts()
      {
          if(this.all_prompts.length>0)
          {
              let rand_instruction_id = Math.floor(Math.random() * this.all_prompts.length)

              this.instructions_msg = this.all_prompts[rand_instruction_id]

              this.bv_tts.say(this.instructions_msg)
              this.all_prompts.splice(rand_instruction_id,1)
              this.$forceUpdate()
          }
          else
          {
              console.debug('error - prompt finished')
          }
          
      },
      playPause()
      {
          
        const _this = this;

        this.bv_tts.cancel()

        console.debug('this.play_status = ' + this.play_status)


        if(_this.play_status)
        {


          _this.last_loudness = _this.avg_loudness

          let avg_loudness_array = []

          if(_this.avg_loudness>0)
          {
            avg_loudness_array.push(_this.avg_loudness)
          }


          _this.avg_loudness = 0;
          _this.total_loudness = 0;
          _this.counter_loudness = 0;

          this.play_status = false


          if(_this.is_recording)
          {
            _this.is_recording = false


            _this.endRecordingAudio(true)


  
            clearInterval(window.refreshIntervalId)
            window.speechSynthesis.cancel()

            this.action='recorded'

            this.nextExercise()
          }
        }
        else
        {
            _this.play_status = true;
            _this.play_pause_text = 'Stop Recording'

            this.action='recording'




            _this.resumeRecording()

                
            _this.runTimer()
            

        }

      },
      roundNumber(number,number_of_decimals)
      {   
        let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)
        return rounded_number;
      },
      runTimer() {
        let interval_time = 250;

        const _this = this;

        clearInterval(window.refreshIntervalId)
        
        window.refreshIntervalId = setInterval(function () {
        
        if (soundMeter != null) {

          // if(element!=null)
          {

            // var sound_l = soundMeter.instant.toFixed(2) ;
            var sound_l = soundMeter.db.toFixed(2);
            _this.sound_l2 = soundMeter.db2.toFixed(2);
            var max_freq_index = soundMeter.max_freq_index

            if(max_freq_index==undefined || max_freq_index==null)
            {
              max_freq_index = 0
            }



            max_freq_index = max_freq_index.toFixed(2);


            // let sound_calibration = 20;
            let sound_threshold = 52;
            

            console.debug('sound_l = ' + sound_l)
            if(sound_l>sound_threshold)
            {
            
              _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
              
              _this.avg_loudness_show = _this.roundNumber(_this.avg_loudness,0)

              console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)

              _this.max_loudness = Math.max(_this.max_loudness,sound_l)


              _this.counter_loudness += 1;

              
            
            }
          
          }
        }

        }, interval_time);
      },
      resumeRecording()
      {
          var _this = this;
      
          // resumeRecordingMedia();
          if(!_this.is_recording)
          {
              recordAudioMedia()
              _this.is_recording = true;
          }
  
      },
      endRecordingAudio(save_audio)
      {

      
          const _this = this;

          console.debug('_this.last_loudness = ' + _this.last_loudness)

          console.debug('_this.last_loudness = ' + _this.avg_loudness)

          if(save_audio)
          {



            let results_addresses = generate_results_path(this.exForm)

            let random_identifier = results_addresses['random_identifier']

            let exercises_results_path = results_addresses['exercises_results_path']

            _this.resultsTableBack.push(exercises_results_path)

            let modules_results_path = results_addresses['modules_results_path']

            let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;

  
            if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
              audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
            }

            var audio_address = 'audios/' + audio_folder + '.mp3';

            audio_address = audio_address.replaceAll('//','/')

            
            let today = _this.exForm.today
            
  
            
          
            _this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')


            updateDataDirectory(modules_results_path, {plan_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})


            let name = 'Picture Description'
            let push_structure = {'date':today, 'name': name, 'topic':_this.picture_name, 'path':exercises_results_path , 'audio_address':audio_address, 'uid':_this.uid, 'loudness':_this.last_loudness,'localisation':_this.region}

            let ground_truth = 'picture_description'

            console.log('ground_truth = ' + ground_truth)



            let has_analysis = true

            if('speechtype' in this.exForm)
            {

              if(Array.isArray(this.exForm.speechtype) && this.exForm.speechtype.includes('unscripted_speech_recognition'))
              {
                push_structure['unscripted_speech_recognition'] = {'type':'picture_description'}
              }

              if(Array.isArray(this.exForm.speechtype) && this.exForm.speechtype.includes('transcription'))
              {
                push_structure['transcription'] = {'type':'picture_description'}
              }
            }




            console.debug('push_structure')


            console.debug(push_structure)


            if(has_analysis)
            {
              _this.resultsTableBack.push(exercises_results_path)
              _this.modules_results_path = modules_results_path
              // console.debug('path = ' + modules_results_path)
              // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
              stopRecordingMedia(audio_address, push_structure, _this);

          
            }
          
              
          }
          else
          {
              stopRecordingMedia(null,null,null);
          }            


          this.is_recording = false;

      }, 
      pause()
      {
          const _this = this;
          _this.play_status = false;
          _this.play_pause_text = _this.start_btn_text

          clearInterval(window.refreshIntervalId)

      },

    }
}
</script>

<style scoped>
.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: #195e52;
    height: 20mm;
    width: 35mm;
}
</style>

