<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div class="card">
          <span class="read-text">{{ instructions }}
            <img :src="audioImg" style="width:35px;;cursor: pointer;" @click="read_instructions()">
          </span>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col-md-4" style="margin-top: 3%;" @click="wordTTS()" >

            <div v-if="task==1" class="card card-body custom-card_blue">
              <span class="read-text">{{selected_word.substring(0,answer.length)}} <b style="color:#f56c6c"> {{selected_word.substring(answer.length,answer.length+1)}}  </b> {{ selected_word.substring(answer.length+1,selected_word.length) }}<img :src="audioImg" @click="bv_tts.say(selected_word.substring(answer.length,answer.length+1))" style="width:35px;;cursor: pointer;"></span>
            </div>
            <div v-else class="card card-body custom-card_blue">
              <span class="read-text">{{selected_word}} <img :src="audioImg" @click="bv_tts.say(selected_word)" style="width:35px;;cursor: pointer;"></span>
            </div>

            <!-- <div class="card card-body custom-card_blue">
              <span class="read-text">{{selected_word}} <img :src="audioImg" style="width:35px;;cursor: pointer;"></span>
            </div> -->

          </div>
        </div>

        <div v-if="task!=0" style="margin-top: 6mm;">
          <div class="row mt-2 justify-content-center">
            <span style="font-size: x-large;"> {{your_answer_txt}} </span>

            <input v-model="answer"  @input="auto_answer_checker()"  style="font-size: x-large; width:40%"/>
            <!-- <button v-if="button_check" class="btn btn-primary btn-lg ms-1 w-25" @click="answer_check()">
              Submit answer <i class="bi bi-send" style="margin-left: 5mm;"></i>
            </button> -->
          </div>
        </div>

        <div style="margin-top:1%;margin-bottom: 1%">
          <span class="empty_item" :style="msg_colour" style="margin-top:2%">{{result_message}}</span>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_show_results="show_results"
          @show_results="goToResults()"   
          >
          <template v-slot:top>
            <hr/>
            <p class="lead" font-size="medium">Task {{Math.min(task,n_tasks)+1}} of {{n_tasks+1}}</p>
            <button class="btn btn-info btn-lg w-100 mt-2"  @click="nextTask()"> Next <i class="bi bi-skip-forward" style="margin-left: 3mm;"></i> </button>
            
          </template>
        </Sidebar>
      </template>
    </Layout>
    <div class="modal fade" id="level_up_pop" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Level up</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="m-3">
              <div class="row">
                <h4 >{{ level_up_message }}</h4>
              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'

import {getData, getUserDataDir, updateDataDirectory} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity} from '@/media_input/utils'
import audioImg from '@/assets/images/audio_logo.png'
import { useTTS } from '@/stores/tts'
import {BV_Points} from '@/utils/bv_points'
import { Modal } from 'bootstrap'
import {find_n_random_words_containing_phonemes_2} from '@/media_input/words'
import {startConfetti} from '@/common_js/confetti'

export default {
  name: 'Copy_word',
  props: 
  {
    exForm: {
      type: Object,
    },
    /*
      exForm input example
      {
        "Name":"Antonym easy",
        "difficulty":"easy",
        "exercise_type":"antonym_exercise",
        "plan_name":"Antonym",
        "assessment_type":"SLP_TRAINING",
        "curr_exercise_path":"Antonym/Exercises/General/Antonyms/exercises/easy/",
        "ex_path":"Antonym/Exercises/General/Antonyms/exercises/easy/",
        "today":"2023-07-11 16:20",
        "audioIns1":null,
        "imageAddress":null,
        "videoAddress":null,
        "audioAddress":null,
        "audioIns2":null,
        "videoIns":null,
        "exercise_type":"antonym_exercise",
        "url":null,
        "url2image":null,
        "audioIns":null,
        "module_name":"Antonym",
        "has_results":false,
        "current_exercise_index":0,
        "number_exercises":7
      }

    */
  },
  components: {Layout, Sidebar},
  data(){
    return {
      base_level_address:'/Levels/Copy_word',
      level_address:'',
      correct_counter:0,
      incorrect_counter:0,
      n_tasks:2,
      task:0,
      answer:'',
      instructions:'',
      result_message: '',
      selected_word: '',
      level_up_counter: 0,
      total:0,
      step:'word',
      current_exercise_index: this.exForm.current_exercise_index + 1,
      failed_already: false,
      number_exercises: this.exForm.total_all_type_exercises,
      difficulty: 'easy',
      level_properties:[
          {'level':1,'step':'letter','n_syl':2},
          {'level':2,'step':'letter','n_syl':3},
          {'level':3,'step':'word','n_syl':2},
          {'level':4,'step':'word','n_syl':3},
          {'level':5,'step':'word','n_syl':4}   
        ],
      past_exercises: [],
      your_answer_txt: 'Your answer',
      level_up_message:'',
      msg_colour:'',
      show_results: false,
      audioImg:audioImg,
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points()
    }
  },
  created() {
    const _this = this

    _this.show_results = _this.exForm.has_results


    logActivity( 'accessed', 'copy_word_exercise')

    _this.getUserLevel()
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()
  },
  methods: {

    auto_answer_checker()
    {
      const _this = this


      let selected_word_lower = _this.selected_word.toLowerCase()
      let answer_lower = _this.answer.toLowerCase()

      if(this.task==1 && answer_lower.length>0)
      {

        if(selected_word_lower.length>=answer_lower.length && selected_word_lower[answer_lower.length-1] === answer_lower[answer_lower.length-1])
        {
          this.correct_single_letter()

        }
        else
        {
          if(!this.failed_already)
          {
            this.failed_already = true
            this.total = this.total+1
            this.incorrect_counter = this.incorrect_counter+1
          }
          this.incorrect_single_letter()
        }

      }

      

      if(_this.answer.toLowerCase()==_this.selected_word.toLowerCase() && _this.task==2)
      {
        if(!_this.failed_already)
        {
          _this.correct_counter = _this.correct_counter+1
          this.total = this.total+1
          startConfetti(this)

        }

        console.debug('_this.correct_counter = ' + _this.correct_counter)
        _this.result_message = "Congrats! You got the right word"
        _this.msg_colour = "color:green;fontSize:20px;";
        _this.level_up_counter = _this.level_up_counter+1
 

        // if(_this.correct_streak>=4)
        // {
        //   if(_this.difficulty=='easy')
        //   {
        //     _this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
        //     let level_up_pop = new Modal(document.getElementById('level_up_pop'));
        //     level_up_pop.show()

        //     _this.difficulty='medium'
        //     _this.correct_streak = 0
        //     _this.past_exercises = []
        //   }
        //   else if(_this.difficulty=='medium')
        //   {
        //     _this.past_exercises = []

        //     _this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
        //     let level_up_pop = new Modal(document.getElementById('level_up_pop'));
        //     level_up_pop.show()

        //     _this.correct_streak = 0

        //     _this.difficulty='hard'
        //   }
        // }

        

        updateDataDirectory(_this.level_address,{current_level:_this.current_level,level_up_counter:_this.level_up_counter})

        setTimeout(() => {
            this.nextExercise()
            }, 900);


      }
    },
    wordTTS()
    {
      if(this.task==1)
      {
        this.bv_tts.say(this.selected_word.substring(this.answer.length,this.answer.length+1))
      }
      else
      {
        this.bv_tts.say(this.selected_word)
      }

    },
    correct_single_letter()
    {

      if(this.selected_word!==this.answer)
      {

        let correct_single_letter_message = 'Correct. ' + this.selected_word[this.answer.length]
        this.bv_tts.say_sentences(['Correct. ',this.selected_word[this.answer.length]])
      }
      else
      {
        if(!this.failed_already)
        {
          this.correct_counter = this.correct_counter+1
          this.total = this.total+1
        }
        this.bv_tts.say('Good job.')
        this.nextTask()
      }

    },
    incorrect_single_letter()
    {
      let incorrect_single_letter_message = this.answer[this.answer.length-1] + ' is not the right letter. The letter is ' + this.selected_word[this.answer.length-1]

      this.answer = this.answer.substring(0,this.answer.length-1)
      this.bv_tts.say_sentences([incorrect_single_letter_message])
    },
    nextExercise()
    {
      const _this = this;
      _this.saveResults()
      _this.$emit('nextExercise');
    },
    goToResults()
    {
      this.saveResults()
      
      let goToResultsData = {curr_ex_done:false}
      this.$emit('goToResults', goToResultsData);
    },
    saveResults()
    {
      const _this = this

      if(this.correct_counter!=0 || this.incorrect_counter!=0)
      {
        let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:this.correct_counter+this.incorrect_counter}

        let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter}
      
        let results = {all_results:all_results, plot_results:plot_results}

        var copy_word_data = {resultsTablePre: {exercise_type: 'Copy Words', exercise: 'Copy Words', results: results, audio_address:''}}

        save_pre_computed_results(_this.exForm,_this.$firebase,copy_word_data)

        _this.bv_points.add(10)

        _this.$emit('logExerciseResults', copy_word_data);
      }
    },
    getUserLevel()
      { 
        const _this = this

        let userType = localStorage.getItem('userCategory');


        let uid = localStorage.getItem('uid');

        this.level_address = '/Users/' + uid + this.base_level_address


        if(userType!=undefined || userType==='slp')
        {
            let client_number = localStorage.getItem('client_uid');

            if(client_number!='undefined' && client_number!=null && client_number!='')
            {

                this.level_address = '/Users/'+ client_number + this.base_level_address

            }
        }


        let do_once = true


        getData(_this.level_address, function (err, result) {

          if(do_once)
          {
            do_once = false
            
            let current_level = result.child('current_level').val()
            let lvl_up_counter = result.child('level_up_counter').val()

            console.debug('current_level = ' + current_level)


            if(current_level==undefined || lvl_up_counter==undefined)
            {
              current_level = 1
              lvl_up_counter = 0
              updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter})
            }

            _this.level_up_counter = lvl_up_counter

            if(current_level-1<0 || current_level-1>=_this.level_properties.length)
            {
              current_level = 1
              
            }

            console.debug('current_level = ' + current_level)
            
            console.debug('lvl_up_counter = ' + lvl_up_counter)

            _this.current_level = current_level


            _this.curr_level_properties = _this.level_properties[_this.current_level-1]
            _this.step = _this.curr_level_properties['step']

          


            _this.getWord()
          }
        })


      },
      getWord()
      {
        const _this = this
        let n_words = 1
        let search_phoneme = 'any'
        let phoneme_position = 'any'
        let n_syl = _this.curr_level_properties['n_syl']
        console.debug('n_syl = ' + n_syl)
        let syllable_conditions = {condition:'equal',n_syl:n_syl}


        let phonetic_conditions = {difficulty_rating:'hard',manners:[],placings:[],voicings:[]}

        find_n_random_words_containing_phonemes_2(n_words,search_phoneme,phoneme_position,syllable_conditions,phonetic_conditions).then(function(word_list){



            if(word_list.length<1)
            {
                alert('No words were found containing these letters');
            }
            else
            {
                _this.selected_word = word_list[0].toLowerCase()

                _this.setup_task()
            }
        })



      },
      nextTask()
      {
        this.task = this.task + 1

        if(this.task>this.n_tasks)
        {
          this.nextExercise()
        }
        else
        {
          this.setup_task()
        }

      },
      setup_task()
      {
        this.failed_already = false

        if(this.task==0)
        {
          this.setup_task_0()
        }
        else if(this.task==1)
        {
          this.setup_task_1()
        }
        else{
          this.answer = ''

          this.setup_task_2()
        }

      },
      read_instructions()
      {
        if(this.task==0)
        {
          this.setup_task_0()

        }
        else if(this.task==1)
        {
          this.setup_task_1()
        }
        else
        {
          this.setup_task_2() 
        }
      },
      setup_task_0()
      {
        const _this = this
        this.instructions = 'Read aloud the word after me'
        this.task_instructions = [this.instructions,this.selected_word,'Click next to go to the next task.']


        this.bv_tts.say_sentences(this.task_instructions, 125)

      
      },
      setup_task_1()
      {
        const _this = this

        this.task_instructions = ["Let's write the word","Type the letter. "+ this.selected_word[this.answer.length]]
        this.instructions = 'Write the word letter by letter'
        this.bv_tts.say_sentences(this.task_instructions)

      
      },
      setup_task_2()
      {
        const _this = this

        this.instructions = "Now type the word by yourself"

        this.task_instructions = [this.instructions]

        this.bv_tts.say_sentences(this.task_instructions)

      }
  },
}
</script>

<style scoped>

.inline-block-child {
  display: inline-block;
}

.custom-card_blue{
    background-color: #b7d8f5;
    border-color: #b7d8f5;
    font-size: 20px;
}
.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.empty_item:empty:before {
  content: "\200b";
}


</style>

<!-- Result Description

{
  "Antonym Exercise": {
    "Exercises": { //Need to remove this layer
      "General": { //Need to remove this layer
        "Antonyms": { //Need to remove this layer
          "exercises": { //Need to remove this layer
            "easy": { //Need to remove this layer
              "<key>": {
                "audio_address": "<empty>",
                "resultsTablePre": {
                  "audio_address": "<empty>",
                  "exercise": "Antonym Exercise",
                  "exercise_type": "Antonym Exercise",
                  "results": {
                    "all_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>",
                      "Number_Hints": "<int>",
                      "Total": "<int>"
                    },
                    "plot_results": {
                      "Correct": "<int>",
                      "Incorrect": "<int>",
                      "Total": "<int>"
                    }
                  }
                },
                "results_type": "pre_computed"
              }
            }
          }
        }
      }
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Antonym Exercise"
  }
}
-->