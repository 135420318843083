import constants from '@/utils/constants'


export function splitIntoSyllables(word) {
  // Basic rule-based approach for splitting English words into syllables
  if (!word || typeof word !== 'string') return [];

  // Lowercase the word for consistent processing
  word = word.toLowerCase();

  // Vowel groups to identify syllables
  const vowels = 'aeiouy';
  const regex = new RegExp(`[${vowels}]+[^${vowels}]*`, 'g');

  // Find syllable-like chunks using regex
  const chunks = word.match(regex);

  if (!chunks) return [word]; // Return the word itself if no syllables are found

  // Refine syllable splits
  return refineSyllables(chunks);
}

function refineSyllables(chunks) {
  // Example refinement rules, can be improved with more phonetic rules
  const refined = [];
  for (let i = 0; i < chunks.length; i++) {
      let chunk = chunks[i];

      // Handle silent "e" at the end of words
      if (i === chunks.length - 1 && chunk.endsWith('e')) {
          chunk = chunk.slice(0, -1);
      }

      // Combine small chunks
      if (i > 0 && chunk.length === 1 && /^[aeiouy]$/.test(chunk)) {
          refined[refined.length - 1] += chunk; // Merge with previous chunk
      } else {
          refined.push(chunk);
      }
  }
  return refined.filter(Boolean); // Remove empty parts
}


export function removePlural(word)
{
  let out_word = word

  if(word.length>2)
  {
    if(word.slice(-1)=='s')
    {
      out_word = word.substring(0, word.length - 1);

    }
  }

  return out_word

}


export function roundNumber(number, number_of_decimals) {   
  let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)
  return rounded_number
}

function findIDRange(pos,target)
{
  let min_target = 0;
  let max_target = pos[pos.length-1];
  if(target<pos[0])
  {
    min_target = '';
    max_target = pos[0];
  }
  else if(target>pos[pos.length-1])
  {
    min_target = pos[pos.length-1];
    max_target = ''
  }
  else
  {
    for(let i=0;i<pos.length;i++)
    {
      if((pos[i]<target)&&(pos[i]>min_target))
      {
        min_target = pos[i];
      }

      if((pos[i]>target)&&(pos[i]<max_target))
      {
        max_target = pos[i]
      }
    }
  }

  return[min_target,max_target]
}

export function phonemeToWord(word, phonemes)
{
  // console.debug('phoenme0= '+phonemes[1])
  let pho_pos = [];
  let word_pos = [];
  let pho_letter = [];
  let word_pos_in_pho = [];
  let phoneme_word = []


  if(word==undefined)
  {
    return [phoneme_word,word_pos_in_pho]

  }


  phoneme_word = word.split('');
  for(let i = 0;i<phonemes.length;i++)
  {

    if(constants.consonants.includes(phonemes[i]))
    {
      let pho = phonemes[i];
      let conso_arr = constants.conso_letters[pho];
      let temp_cons_pos = word.length+1;
      let temp_letter = ''
      for(let j=0;j<conso_arr.length;j++)
      {
        let temp_pos = ''
        if(word_pos.length>0)
        {
          temp_pos = word.indexOf(conso_arr[j],word_pos[word_pos.length-1]+1);
        }
        else
        {
          temp_pos = word.indexOf(conso_arr[j]);
        }
        if((temp_pos!=-1)&&(temp_pos<temp_cons_pos))
        {
          temp_cons_pos = temp_pos;
          temp_letter = conso_arr[j];
        }
      }
      if((temp_letter == 'cc')&&(i<phonemes.length-1)) //for cases to distinguish 'success' from 'succulent'
      {
        if(phonemes[i+1]=='s')
        {
          temp_letter = 'c'
        }
      }
      
      for(let n=0;n<temp_letter.length;n++)
      {
        pho_pos.push(i);
      }
      if(temp_cons_pos!=word.length+1)
      {
        for(let m=0;m<temp_letter.length;m++)
        {
          word_pos.push(temp_cons_pos+m)
          phoneme_word[temp_cons_pos+m] = pho;  
        }
        
        pho_letter.push(temp_letter)
      }
      else
      {
        console.debug('phoneme '+ pho+' doesnt have corresponding letters')
      }
      // if((pho == 'k')&&(phonemes[i+1]=='s'))
      // {
      //   i=i+1;
      // }

    }
  }
  for(let k=0;k<phoneme_word.length;k++)
  {
    if(word_pos.includes(k))
    {
      let id = pho_pos[word_pos.indexOf(k)];
      phoneme_word[k] = phonemes[id];
      word_pos_in_pho.push(id);
      // if(word == test)
      // {
      //   console.debug('k= '+k+' pho_id= '+id)
      // }
    }
    else
    {
      let [min_word_pos,max_word_pos] = findIDRange(word_pos,k);
      if((min_word_pos == ''))
      {
        min_word_pos = word_pos[0];
      }
      if(max_word_pos == '')
      {
        max_word_pos = word_pos[word_pos.length-1];
      }
      let min_pho_pos = pho_pos[word_pos.indexOf(min_word_pos)];
      let max_pho_pos = pho_pos[word_pos.indexOf(max_word_pos)];
      
      let pho_id = min_pho_pos+k-min_word_pos;
      // if(word == test)
      // {
      //   console.debug('word pos min='+min_word_pos+' max= '+max_word_pos);
      //   console.debug('pho pos min='+min_pho_pos+' max= '+max_pho_pos);
      //   console.debug('k= '+k+' pho_id= '+pho_id)
      // }
      while(pho_id>=max_pho_pos)
      {
        pho_id = pho_id - 1
      }
      if(k>max_word_pos)
      {
        pho_id = max_pho_pos+k-max_word_pos;
        if(pho_id>phonemes.length-1)
        {
          pho_id = phonemes.length-1
        }
      }
      if(k<min_word_pos)
      {
        pho_id = min_pho_pos - (min_word_pos - k)
        if(pho_id<0)
        {
          pho_id = 0;
        }
      }
      // if(word == test)
      // {
      //   console.debug('new pho_id= '+pho_id)
      // }
      phoneme_word[k] = phonemes[pho_id];
      word_pos_in_pho.push(pho_id)
    }
  }
  return [phoneme_word,word_pos_in_pho]
}

export function getIssueFlag()
{
    
    let temp_iss_flag = localStorage.getItem('issue_flag');

    if(temp_iss_flag!=undefined && temp_iss_flag!=null)
    {
        
        let issue_flag_arr = temp_iss_flag.split(',')
        let issue_flag = [];
        for(let i=0;i<issue_flag_arr.length;i++)
        {
            let flag = (issue_flag_arr[i] == 'true')
            issue_flag.push(flag);
        }
        return issue_flag

    }
    else
    {
        let issue_flag = [true,true,true,true]

        return issue_flag
    }

}

export function toWords(num){
  var ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
              'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
              'seventeen', 'eighteen', 'nineteen'];
  var tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty',
              'ninety'];

  var numString = num.toString();

  if (num < 0) throw new Error('Negative numbers are not supported.');

  if (num === 0) return 'zero';

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + ' ' + ones[numString[1]];
  }

  //100 and more
  if (numString.length == 3) {
    if (numString[1] === '0' && numString[2] === '0')
      return ones[numString[0]] + ' hundred';
    else
      return ones[numString[0]] + ' hundred and ' + toWords(+(numString[1] + numString[2]));
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + ' thousand';
    if (end < 100) return ones[numString[0]] + ' thousand and ' + toWords(end);
    return ones[numString[0]] + ' thousand ' + toWords(end);
  }
}
